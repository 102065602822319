export default {
  howProgressBarWorks:
    'Mijn energiebehoefte geeft de energiebehoefte voor vandaag berekend op basis van jouw voedingsplan-metrics en je training vandaag.',
  macros: {
    calories: 'Kilocalorie (kcal)',
    carbs: 'Koolhydraten',
    protein: 'Eiwitten',
    fat: 'Vetten',
  },
  nutritionView: {
    coach: 'De weergave is ingesteld door jouw coach voor focus op de juiste macronutriënten.',
    core: 'De weergave is ingesteld op core performance  voor focus op de macronutriënten:',
    advanced: 'De weergave is ingesteld op advanced performance voor focus op:',
  },

  calculationExplained: {
    title: 'Hoe wordt dit berekend?',
    text: "Op basis van persoonlijke kenmerken, zoals lengte, leeftijd en gewicht, berekent The Athlete's FoodCoach je dagelijkse energie- en macronutriëntenbehoefte. Wanneer je een training inplant, heeft dit invloed op je energie- en macronutriëntenbehoeften, waardoor deze dagelijks kunnen variëren.",
  },

  chartExplanation: {
    title: 'Hoe werkt de grafiek?',
    coach:
      'Zodra je maaltijden voor een dag inplant, vullen de balken voor calorieën en de verschillende macronutriënten zich. Wanneer je voldoende van een bepaald macronutrient hebt ingepland voor die dag, verschijnt er een groen vinkje bij de balk, zoals hieronder weergegeven.',
    core: 'Zodra je maaltijden voor een dag inplant, vullen de balken van de verschillende macronutriënten zich. Wanneer je voldoende koolhydraten of eiwitten hebt ingepland voor die dag, verschijnt er een groen vinkje bij de balk, zoals hieronder weergegeven.',
    advanced:
      'Zodra je maaltijden voor een dag inplant, vullen de balken voor kcal en de verschillende macronutriënten zich. Wanneer je voldoende kcal, koolhydraten, eiwitten of vetten hebt ingepland voor die dag, verschijnt er een groen vinkje bij de balk, zoals hieronder weergegeven.',
  },
};
