import Decimal from 'decimal.js';

export const toDecimal = value => {
  if (typeof value === 'number') {
    return new Decimal(value);
  }
  return new Decimal(value.replace(',', '.'));
};

export const isDecimal = value => /^-?(?:\d+)(?:(\.|,)\d+)?$/.test(value);
