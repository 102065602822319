import FcSportsPicker from '@/modules/shared/components/sports-picker/SportsPicker';
import { registerRoutes } from './routes/onboarding.routes';
import guards from './guards';
import messages from './messages/nl';
import messagesEn from './messages/en';

export const onboardingSportsModule = ({ router }, i18nModule) => ({
  components: { FcSportsPicker },
  install() {
    this.guards = guards;

    i18nModule.setTranslations({ onboardingSports: { ...messages } }, 'nl');
    i18nModule.setTranslations({ onboardingSports: { ...messagesEn } }, 'en');

    registerRoutes(router);
  },
});
