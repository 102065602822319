import { getUserProfile } from '@/modules/shared/services/profile/profile.service';

const onboardingGuard = async (to, from, next) => {
  try {
    const { acceptsTermsAndConditions, sportType } = await getUserProfile();

    if (!acceptsTermsAndConditions) {
      next({ name: 'onboarding-additional-info' });
    } else if (!sportType) {
      next({ name: 'onboarding-sport-type' });
    } else {
      next();
    }
  } catch {
    next({ name: 'auth-login' });
  }
};

export default onboardingGuard;
