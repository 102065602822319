import { PROCESS } from '@/modules/shared/constants/env.const';
import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import apollo from '@/modules/shared/graphql/apollo.config';
import APP_OPENS_QUERY from '@/modules/shared/graphql/queries/appOpens.query.graphql';
import MODAL_QUERY from '@/modules/shared/graphql/queries/modal.query.graphql';
import { getItem, setItem } from '@/modules/shared/services/storage.service';
import { isSameDay, nowISOString } from '@/modules/shared/utils/date.util';

const packageVersion = PROCESS.PACKAGE_VERSION;

export default async (_, { modalName }, { cache }) => {
  const { modal } = cache.readQuery({ query: MODAL_QUERY, variables: { modalName } });
  const {
    data: { appOpens },
  } = await apollo.defaultClient.query({ query: APP_OPENS_QUERY });

  modal.timesShown += 1;
  modal.lastShownDate = nowISOString();

  if (modal.showForEachVersion) {
    modal.shouldBeShown = false;
    modal.lastShownVersion = packageVersion;
  } else if (modal.min && !modal.max) {
    modal.shouldBeShown = appOpens.daysWithAppOpens >= modal.min;
  } else if (modal.max && !modal.min) {
    modal.shouldBeShown = modal.timesShown < modal.max && !isSameDay(modal.lastShownDate);
  } else {
    modal.shouldBeShown =
      appOpens.daysWithAppOpens >= modal.min &&
      modal.timesShown < modal.max &&
      !isSameDay(modal.lastShownDate);
  }

  cache.writeQuery({
    query: MODAL_QUERY,
    variables: { modalName },
    data: { modal },
  });

  const modals = (await getItem(PERSISTENCE_KEYS.MODALS)) || {};
  modals[modalName] = modal;
  await setItem(PERSISTENCE_KEYS.MODALS, modals);

  return modal;
};
