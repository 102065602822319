import buttons from './buttons.en';
import counts from './counts.en';
import headings from './headings.en';
import info from './info.en';
import labels from './labels.en';
import links from './links.en';
import modals from './modals.en';
import networkErrors from './networkErrors.en';
import notifications from './notifications.en';
import placeholders from './placeholders.en';
import progressBarExplanation from './progressBarExplanation.en';
import titles from './titles.en';

export default {
  buttons,
  counts,
  headings,
  info,
  labels,
  links,
  modals,
  networkErrors,
  notifications,
  placeholders,
  titles,
  progressBarExplanation,
};
