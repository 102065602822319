export default {
  buttons: {
    createAccount: 'Create an account',
    login: 'Login',
    resetPassword: 'Reset password',
    backToLogin: 'Back to login',
  },
  headings: {
    login: '<strong>Login</strong> to fuel your dreams',
    register: {
      title: '<strong>Time to fuel your dreams?</strong>',
      subtitle: 'Let’s create a FoodCoach account!',
    },
    forgotPassword: 'Reset your password',
    passwordReset: 'Registered email addresses will receive a password reset email shortly',
  },
  links: {
    createAccount: 'Create an account',
    forgotPassword: 'I’ve forgotten my password',
    backToLogin: 'Do you already have an account? <strong>Login</strong>',
  },
  placeholders: {
    givenName: 'First name',
    middleName: 'Middle name',
    familyName: 'Family name',
    email: 'name@email.com',
    password: 'Choose strong password',
  },
  misc: {
    hello: 'Hello 👋',
    newAtJumbo: 'New to Blanco?',
    letsCreateYourAccount: "Let's create your account",
    forgotPasswordInfo:
      '<strong>Important:</strong> Only registered email addresses will receive a password reset email. If your email is not in our records, no reset email will be sent.',
  },
  titles: {
    signUp: 'Sign up',
    account: 'Account',
    login: 'Login',
    resetPassword: 'Reset password',
    createAccount: 'Create a <strong>Blank Account</strong> to:',
    welcomeHappyMany: "Welcome to <strong>The Athlete's FoodCoach</strong>",
    welcomeSports:
      'Are you a <strong>fanatic athlete</strong> and do you want to improve your sports performance?',
  },
  notifications: {
    accountCreated: 'Congratulations - you now have a FoodCoach account',
  },
  body: {
    passwordConfirmation: [
      "<strong>Security Note:</strong>\n For security reasons, we can't confirm if the provided email is registered with us.",
      '<strong>No email received?</strong>\n Try again with a different email address or contact us at <a href="mailto:feedback@theathletesfoodcoach.com">feedback@theathletesfoodcoach.com</a>.',
      "Note: Jumbo FoodCoach accounts won't work in this new app.",
    ],
  },
};
