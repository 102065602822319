<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="tooltip">
    <div ref="trigger" :class="{ trigger: show }" @click="handleClick">
      <slot name="trigger"></slot>
    </div>
    <div ref="tooltip">
      <div v-if="show" class="backdrop" @click="hideTooltip"></div>
      <div v-if="show" class="highlight" :style="highlightStyle" @click="hideTooltip"></div>
      <transition v-show="show" name="fade">
        <div v-if="show" class="arrow" :style="arrowStyle"></div>
      </transition>
      <transition v-show="show" name="fade">
        <div
          v-show="show"
          ref="content"
          class="content-wrapper"
          :style="contentStyle"
          :class="{ top: showOnTop, left: showOnLeft, right: !showOnLeft, bottom: !showOnTop }"
        >
          <slot name="content" :hide-tooltip="hideTooltip"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  const TOOLTIP_WIDTH = 280;
  const CONTENT_HORIZONTAL_OFFSET = 70;
  const CONTENT_VERTICAL_OFFSET = 28;

  export default defineComponent({
    name: 'FcTooltip',
    props: {
      showByDefault: {
        type: Boolean,
        default: false,
        required: false,
      },
      highlightSize: {
        type: Number,
        default: 44,
      },
      highlightOffset: {
        type: [Number, Array],
        default: 7,
      },
      position: {
        type: String,
        required: false,
        default: 'none',
      },
      triggerOnClick: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        show: false,
        positionTop: 0,
        positionLeft: 0,
        offsetX: 0,
        offsetY: 0,
        screenHeight: 0,
        screenWidth: 0,
        showOnTop: false,
        showOnLeft: false,
      };
    },

    computed: {
      contentStyle() {
        let left = `${this.positionLeft + this.offsetX}px`;
        if (this.position === 'left') {
          left = `${
            this.positionLeft - (TOOLTIP_WIDTH - this.highlightSize + this.highlightOffsetX)
          }px`;
        } else if (this.position === 'right') {
          left = `${this.positionLeft - this.highlightOffsetX}px`;
        }
        return {
          left,
          top: `${this.positionTop + this.offsetY}px`,
          width: `${TOOLTIP_WIDTH}px`,
        };
      },
      highlightOffsetX() {
        return Array.isArray(this.highlightOffset) ? this.highlightOffset[0] : this.highlightOffset;
      },
      highlightOffsetY() {
        return Array.isArray(this.highlightOffset) ? this.highlightOffset[1] : this.highlightOffset;
      },
      highlightStyle() {
        return {
          left: `${this.positionLeft - this.highlightOffsetX}px`,
          top: `${this.positionTop - this.highlightOffsetY}px`,
          height: `${this.highlightSize}px`,
          width: `${this.highlightSize}px`,
        };
      },
      arrowStyle() {
        const { height } = this.$refs.content.getBoundingClientRect();
        return {
          left: `${this.positionLeft + this.highlightSize / 2 - 2 * this.highlightOffsetX}px`,
          top: `${this.positionTop + this.offsetY + (this.showOnTop ? height : 0) - 4}px`,
        };
      },
    },
    watch: {
      showByDefault(newVal) {
        if (newVal) {
          this.scrollToTrigger();
          setTimeout(() => this.showTooltip(), 400);
        }
      },
    },
    mounted() {
      if (this.showByDefault) {
        this.scrollToTrigger();
        setTimeout(() => this.showTooltip(), 400);
      }
    },
    methods: {
      handleClick() {
        if (this.triggerOnClick) {
          this.showTooltip();
        }
      },
      showTooltip() {
        document.body.appendChild(this.$refs.tooltip);
        this.freezeScroll();
        this.updateTooltipPosition();
        this.show = true;
        this.adjustOffset();
      },
      hideTooltip() {
        document.body.removeChild(this.$refs.tooltip);
        this.unFreezeScroll();
        this.show = false;
        this.$emit('hide');
      },
      updateTooltipPosition() {
        const { height: screenHeight, width: screenWidth } = global.window.screen;
        const { top, left } = this.$refs.trigger.getBoundingClientRect();

        this.showOnTop = top > screenHeight / 4;
        this.showOnLeft = left < screenWidth / 2;

        this.positionLeft = left;
        this.positionTop = top;
      },
      adjustOffset() {
        this.$nextTick(() => {
          const { height, width } = this.$refs.content.getBoundingClientRect();
          if (this.showOnTop) {
            this.offsetY = -(height + CONTENT_VERTICAL_OFFSET);
          } else {
            this.offsetY = 2 * CONTENT_VERTICAL_OFFSET;
          }

          if (this.showOnLeft) {
            this.offsetX = -CONTENT_HORIZONTAL_OFFSET;
          } else {
            this.offsetX = CONTENT_HORIZONTAL_OFFSET - width;
          }
        });
      },
      scrollToTrigger() {
        if (this.$refs.trigger?.scrollIntoView) {
          this.$refs.trigger.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
      freezeScroll() {
        global.document.documentElement.classList.add('no-scroll');
      },
      unFreezeScroll() {
        global.document.documentElement.classList.remove('no-scroll');
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .trigger {
    position: relative;
    z-index: $z-index-page-tooltip + 3;
  }

  .highlight {
    background-color: $color-white;
    border-radius: 50%;
    opacity: 0.5;
    position: fixed;
    z-index: $z-index-page-tooltip + 2;
  }

  .backdrop {
    background-color: $color-black;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-page-tooltip;
  }

  .content-wrapper {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0 rgba($color-black, 0.3);
    padding: $spacing-s;
    position: fixed;
    z-index: $z-index-page-tooltip + 1;
  }

  .arrow {
    background-color: $color-white;
    height: 8px;
    position: fixed;
    transform: rotate(45deg);
    width: 8px;
    z-index: $z-index-page-tooltip + 2;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity;
  }

  .fade-enter-active {
    transition: 0.3s;
  }

  .fade-leave-active {
    transition: 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
