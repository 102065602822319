import guards from './guards';
import messages from './messages/nl/onboarding.nl';
import messagesEn from './messages/en/onboarding.en';
import { registerRoutes } from './routes/onboarding.routes';

// Register module
export const onboardingModule = ({ router }, i18nModule) => ({
  guards,
  install() {
    i18nModule.setTranslations({ onboarding: { ...messages } }, 'nl');
    i18nModule.setTranslations({ onboarding: { ...messagesEn } }, 'en');
    registerRoutes(router);
  },
});
