export default {
  birthDateSaved: 'Je geboortedatum is opgeslagen',
  dailyEnergyNeedSaved: 'Jouw dagelijkse energiebehoefte is opgeslagen',
  eatingOnActivityPlanned: 'Je eten tijdens je training is ingepland',
  ftpSaved: 'Je FTP-waarde is opgeslagen',
  heightSaved: 'Je lengte is opgeslagen',
  mealPlanned: 'Je {mealMoment} is ingepland',
  mealRemoved: 'Je {mealMoment} is verwijderd',
  recipeAdded: '{recipe} is toegevoegd aan je bord',
  sleepingHoursSaved: 'Je gemiddeld aantal slaapuren zijn opgeslagen',
  productAddedToPlan: '{product} is toegevoegd aan je bord',
  productUpdatedForRecipe: '{product} is bijgewerkt',
  productUpdatedForPlan: '{product} is bijgewerkt',
  productRemovedFromPlan: '{product} is verwijderd van je bord',
  productRemoved: '{product} is verwijderd',
  sportSaved: 'De gekozen sport is opgeslagen',
  userRecipeRemoved: 'Je eigen recept is verwijderd',
  energyCalculationSaved: 'Je energieberekening is opgeslagen',
  weightSaved: 'Je gewicht is opgeslagen',
  workingHoursSaved: 'Je werkuren zijn opgeslagen',
  workTypeSaved: 'Je type werk is opgeslagen',
  mealsRecalculated: 'Je open maaltijden zijn herberekend',
  invalidBarcode: 'Ongeldige barcode',
};
