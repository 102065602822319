export default {
  personalData: 'Persoonlijke gegevens',
  length: 'Je lengte',
  sleepingHours: 'Je slaapuren',
  energyCalculation: 'Energieberekening',
  weight: 'Je gewicht',
  workingHours: 'Je werkuren',
  workType: 'Je type werk',
  yourSport: 'Jouw sport',
  gender: 'Je geslacht',
  birthdate: 'Je geboortedatum',
  activityLevel: 'Je activiteitenniveau',
};
