import buttons from './buttons.en';
import faqs from './faqs.en';
import headings from './headings.en';
import info from './info.en';
import labels from './labels.en';
import links from './links.en';

export default {
  buttons,
  faqs,
  headings,
  info,
  links,
  labels,
  medals: {
    title: 'My medals',
    description: 'COMING SOON - Earn medals to track and showcase your achievements.',
    readMore: 'Read more',
    popover: {
      title: 'My medals - coming soon',
      description: [
        'At The Athlete’s FoodCoach, we believe that the real difference in performance is made in your daily nutrition 🍏',
        'My Medals will support your journey by breaking down the complex topic of nutrition into specific performance themes. This approach helps you focus on actionable strategies for short-, mid-, and long-term goals, turning your nutrition journey into an adventure.',
        'Soon you can level up your journey with the thrill of earning and showcasing your well-deserved medals 🚀',
      ],
    },
  },
};
