export default {
  achieveYourGoals: 'Achieve your <strong>goals</strong>',
  connectToRealCoach: 'Connect to a real coach',
  eatingSustainable: 'Eat sustainable',
  faq: 'More <strong>about FoodCoach</strong>',
  feedback: { title: 'Help us improve' },
  helloAnonymous: 'nice to see you again',
  home: 'My goal',
  loseWeight: 'Lose weight',
  myEvent: 'My event',
  findMyFocus: 'Find my focus',
  extras: 'Extras',
  foodCoachPremium: 'FoodCoach Premium',
  myFocus: 'My focus',
};
