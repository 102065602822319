export default {
  workInProgress: 'We werken momenteel hard om jou binnenkort te kunnen helpen met dit doel.',
  youreEarlyExplanations: [
    'Doordat je op de knop hebt gedrukt, weten wij dat je dit belangrijk vindt.',
    'Wil je ons verder helpen? Geef via de knop aan hoe wij je het beste kunnen helpen met dit doel.',
  ],
  connectToCoachList: [
    'Krijg voedingsadvies van wereld niveau',
    'Gecertificeerde FoodCoach',
    'Stel al je voedingsvragen',
  ],
  premiumBenefits: [
    'Persoonlijk aangepast voedingsplan',
    'Sync Strava, Garmin, TrainingPeaks en meer',
    'Exclusieve FoodCoach AI ondersteuning',
    'Ontgrendel medailles, uitdagingen en focus plans',
    'Ontdek 1500+ Pro Recepten',
  ],
  feedback: {
    text: 'FoodCoach is nog in beta phase. Met jouw feedback kunnen wij onze diensten verbeteren.',
  },
};
