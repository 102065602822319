export default {
  aboutThisApp: 'About this app',
  activityLevel: 'Activity level',
  calculateYourFtp: 'Calculate my FTP',
  energyCalculationExplanation: 'What method applies best to me?',
  dateOfBirth: 'Date of birth',
  developer: 'Features',
  editRecipe: 'Change your recipe',
  fitnessOverview: 'Which intensity should I choose?',
  ftpValueExplanation: 'Learn more about FTP',
  gender: 'Gender',
  length: 'Height',
  myAccount: 'My profile',
  myFtpValue: 'My FTP',
  myGoal: 'My goal',
  myInfo: 'My data',
  mySport: 'Sport',
  myEnergyCalculation: 'Sport hours',
  onActivityExplanation: 'Never bonk again?',
  progressBarExplanation: 'How do these bars work?',
  save: 'Save',
  sleepingHours: 'Sleeping hours',
  sportSettings: 'Sport settings',
  toHappyMany: 'Change my goal',
  trainingIntensity: 'Want to know more about your training intensity?',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Work type',
  recipeListTabs: {
    favorites: 'Favourites',
    foodcoach: 'Find a meal',
    myRecipes: 'My recipes',
    planner: 'My plan',
    tuneYourMeal: 'Tune your meal',
    suggestedMeals: 'Suggested meals',
  },
  tuneYourMealExplanation: 'How does tuning work?',
  tuningSatisfied: 'Yes, tune this meal',
};
