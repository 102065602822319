import { isNil } from 'ramda';
import apollo from '@/modules/shared/graphql/apollo.config';
import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';

const createTrainingGuard = async (to, from, next) => {
  const redirect = () => next({ name: 'training-ftp', params: { ...to.params } });

  try {
    const {
      data: {
        getUserProfile: { user },
      },
    } = await apollo.defaultClient.query({ query: GET_USER_PROFILE_QUERY });

    if (to.params.type !== 'cycling') {
      next();
      return;
    }

    if (isNil(user.functionalThresholdPower)) {
      redirect();
    } else {
      next();
    }
  } catch {
    redirect();
  }
};

export default createTrainingGuard;
