<template>
  <li
    :class="{
      'fc-link-group-item--disabled': disabled,
    }"
    class="fc-link-group-item"
  >
    <component
      :is="component"
      class="fc-link-group-item__nav"
      v-bind="attributes"
      :class="{ 'fc-link-group-item__nav--small': small }"
      :disabled="disabled"
      v-on="$listeners.click ? { click: $listeners.click } : null"
    >
      <jum-icon v-if="icon" size="30" :icon="icon" class="fc-link-group-item__icon" />

      <div class="fc-link-group-item__content">
        <slot></slot>
      </div>

      <div v-if="placeholder" class="fc-link-group-item__placeholder">{{ placeholder }}</div>

      <jum-icon
        v-if="chevron"
        size="20"
        :icon="isExternalLink || showExternalLinkIcon ? 'jum-external-link' : 'jum-chevron-right'"
        class="fc-link-group-item__type"
      />
    </component>
  </li>
</template>

<script>
  import Url from 'url-parse';
  import { JumIcon } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcLinkGroupItem',
    components: {
      JumIcon,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      href: {
        type: String,
        default: undefined,
      },
      icon: {
        type: String,
        default: undefined,
      },
      chevron: {
        type: Boolean,
        default: true,
      },
      small: {
        type: Boolean,
        default: false,
      },
      showExternalLinkIcon: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: [String, Number],
        default: '',
      },
      route: {
        type: [String, Object],
        default: undefined,
      },
    },
    computed: {
      isExternalLink: vm => (vm.href ? new Url(vm.href).origin !== new Url('/').origin : false),
      attributes: vm => {
        const attrs = {};

        if (vm.href) {
          if (vm.isExternalLink) {
            attrs.href = vm.href;
            attrs.target = '_blank';
          } else {
            attrs.to = vm.href;
          }
        }

        if (vm.route) {
          attrs.to = vm.route;
        }

        return attrs;
      },
      component: vm => {
        if (vm.route) return 'router-link';
        if (vm.href) return vm.isExternalLink ? 'a' : 'router-link';
        if (vm.$listeners.click) return 'button';
        return 'div';
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  $border-width: 1px;

  .fc-link-group-item {
    border-bottom: $border-width solid $color-gray-lighten-80;
    list-style: none;

    &__nav {
      align-items: center;
      background-color: transparent;
      border: 0;
      color: $color-gray-darken-80;
      display: flex;
      flex-shrink: 0;
      font-family: $font-family-outfit;
      font-size: $font-size-small;
      line-height: $line-height-small;
      min-height: $spacing-xl - $border-width;
      padding: $spacing-s $spacing-m;
      text-align: left;
      text-decoration: none !important;
      width: 100%;

      &--small {
        min-height: $spacing-m - $border-width;
        padding: $spacing-s 0;
      }
    }

    &--disabled {
      pointer-events: none;

      .fc-link-group-item__nav {
        color: $color-gray-lighten-30;
      }
    }

    &__icon {
      margin-right: 10px;
    }

    &__type {
      flex-shrink: 0;
    }

    &__content {
      flex-grow: 1;
    }

    &__image {
      flex-grow: 0;
      margin-right: $spacing-s;
    }

    &__placeholder {
      color: #757575;
      margin: 0 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
