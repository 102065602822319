export default {
  coach: 'Coach',
  moreInformation: 'More information can be found in:',
  savePreference: 'Save preferences',
  coachesWantSeeDetails:
    '<strong>Coaches</strong> of {organization} want to get insights in your <strong>personal data</strong>:',
  choices: {
    true: 'I accept that my personal data will be shared with these coaches to improve my nutrition plan.',
    false: 'I don’t accept that my personal data will be shared with these coaches.',
  },
  modals: {
    unsubscribe: {
      title: 'Disconnect from coach',
      message:
        'Are you sure you want to disconnect from your coach? <br/><br/> Pay attention: Only coaches can request a connection again.',
      confirmText: 'Yes disconnect',
      cancelText: 'No cancel',
    },
  },
  notifications: {
    unsubscribed: "You're disconnected from your coach",
  },
};
