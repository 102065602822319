import messages from './messages/nl/auth.nl';
import messagesEn from './messages/en/auth.en';
import { registerRoutes } from './routes';

/* eslint-disable import/no-mutable-exports */
export let mixins = {};
export let router;
/* eslint-enable import/no-mutable-exports */

export const authModule = (sharedModule, routerModule, i18nModule) => ({
  install() {
    router = routerModule.router;
    mixins = { ...sharedModule.mixins };

    i18nModule.setTranslations({ auth: { ...messages } }, 'nl');
    i18nModule.setTranslations({ auth: { ...messagesEn } }, 'en');
    registerRoutes(router);
  },
});
