// eslint-disable-next-line max-classes-per-file
import { configService } from '@/config.service';
import { Medal } from '@/util/gamification/medals';
import { marked } from 'marked';

const { strapiImageUrl } = configService;

export class ChallengeStep {
  constructor(title, items) {
    this.title = title;
    this.items = items;
  }

  get inProgress() {
    return this.items.some(item => item.completed);
  }

  get isCompleted() {
    return this.items.every(item => item.completed);
  }
}

export class Challenge {
  constructor(
    uuid,
    title,
    description,
    cardImageUrl,
    coverImageUrl,
    steps,
    medal,
    tag,
    sport,
    showExtraSupportSection,
    takeHomeMessages
  ) {
    this.uuid = uuid;
    this.title = title;
    this.description = description;
    this.cardImageUrl = cardImageUrl;
    this.coverImageUrl = coverImageUrl;
    this.steps = steps;
    this.medal = medal;
    this.tag = tag;
    this.sport = sport;
    this.showExtraSupportSection = showExtraSupportSection;
    this.takeHomeMessages = takeHomeMessages;
  }

  get hasMedal() {
    return !!this.medal;
  }

  get totalItems() {
    return this.steps.flatMap(step => step.items).length;
  }

  get completedItems() {
    return this.steps.flatMap(step => step.items).filter(item => item.completed).length;
  }

  get totalSteps() {
    return this.steps.length;
  }

  get hasSteps() {
    return this.totalSteps > 0;
  }

  get inProgress() {
    return this.steps.some(step => step.inProgress);
  }

  get completedSteps() {
    return this.steps.filter(step => step.isCompleted).length;
  }

  get progress() {
    if (!this.completedSteps || !this.totalSteps) {
      return 1;
    }

    const product = (this.completedSteps / this.totalSteps) * 100;

    return product > 1 ? product : 1;
  }

  get progressForItems() {
    if (!this.completedItems || !this.totalItems) {
      return 1;
    }

    const product = (this.completedItems / this.totalItems) * 100;

    return product > 1 ? product : 1;
  }

  get completed() {
    return this.completedSteps === this.totalSteps;
  }
}

export const mapChallengeItemToLink = (item, progress, challengeUuid, playlistUuid = null) => {
  const tag = {
    type: item.tag,
    text: item.tag,
  };

  if (item.tag === 'learning' && item.blog?.data?.attributes) {
    return {
      title: item.blog.data.attributes.title,
      url: item.blog?.data?.attributes?.url,
      img: `${strapiImageUrl}${item.blog.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: true,
      tag,
      uuid: item.blog.data.attributes.uuid,
      completed: progress ? progress.items.includes(item.blog.data.attributes.uuid) : false,
    };
  }

  if (item.tag === 'doing' && item.action?.data?.attributes) {
    const url = playlistUuid
      ? {
          name: 'doing-detail-playlist',
          params: {
            playlistUuid,
            challengeUuid,
            doingUuid: item.action?.data?.attributes?.uuid,
          },
          query: {
            stepUuid: item.uuid,
          },
        }
      : {
          name: 'doing-detail',
          params: {
            challengeUuid,
            doingUuid: item.action?.data?.attributes?.uuid,
          },
          query: {
            stepUuid: item.uuid,
          },
        };

    return {
      title: item.action?.data?.attributes?.title ?? '',
      url,
      img: `${strapiImageUrl}${item.action?.data?.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: false,
      tag,
      uuid: item.uuid ?? null,
      completed: progress ? progress.items.includes(item?.uuid) : false,
    };
  }

  return null;
};

export const createChallengeFromStrapiResponse = (
  item,
  progress,
  medal = null,
  playlistUuid = null,
  medalProgress = []
) => {
  const { attributes } = item;
  let medalClass = null;

  if (medal) {
    medalClass = Medal.createFromStrapiResponse(medal, medalProgress);
  }

  const steps = attributes.steps.map(
    step =>
      new ChallengeStep(
        step.title,
        step.items
          .map(stepItem =>
            mapChallengeItemToLink(stepItem, progress, attributes.uuid, playlistUuid)
          )
          .filter(stepItem => stepItem !== null)
      )
  );

  return new Challenge(
    attributes.uuid,
    attributes.title,
    attributes.description,
    `${strapiImageUrl}${attributes.cardImage.data.attributes.url}`,
    `${strapiImageUrl}${attributes.coverImage.data.attributes.url}`,
    steps,
    medalClass,
    attributes?.tag?.type,
    attributes?.sport?.type,
    attributes.showExtraSupport,
    attributes?.takeHomeMessagesNew ? marked.parse(attributes.takeHomeMessagesNew) : null
  );
};
