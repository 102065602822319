export default {
  aboutThisApp: 'Over <strong>deze app</strong>',
  addOwnIngredient: 'Wil je je eigen ingrediënt toevoegen?',
  addOwnProduct: 'Wil je je eigen product toevoegen?',
  advancedFilters: {
    heading: '<strong>Filteren</strong>',
    specialWishes: '<strong>Speciale wensen</strong>',
    dishType: '<strong>Soort gerecht</strong>',
    popularFilter: '<strong>Populaire filter</strong>',
    kitchen: '<strong>Keuken</strong>',
  },
  blogs: "Laat je inspireren door de <strong>The Athlete's FoodCoach blogs</strong>",
  buffet: '<strong>Buffet opties</strong>',
  details: 'Gegevens',
  eatingOnActivityList: 'Producten voor tijdens je training',
  energyNeed: '<strong>Mijn energiebehoefte</strong>',
  emptyLockedMoment: 'Dit moment is leeg',
  myPlan: 'Mijn plan',
  ftpValueExplanation: 'Meer weten over je FTP waarde?',
  graphExplanation: 'Hoe werken deze balkjes?',
  macroNutrientsRatio: 'Macronutriëntenverhouding',
  noMatchingIngredients: 'Staat je ingrediënt er niet tussen?',
  noMatchingProducts: 'Staat je product er niet tussen?',
  noMatchingRecipes: 'Geen recepten gevonden',
  noSelectedProducts: 'Je hebt nog geen producten toegevoegd',
  nutritionFacts: 'Voedingswaarden',
  yourMeals: '<strong>Mijn maaltijden</strong>',
  noRecipesCreated: 'Je hebt nog geen eigen recepten gemaakt',
  noRecipesCreatedForMealMoment: 'Je hebt nog geen eigen recepten gemaakt',
  createRecipeAction: 'Tover je eigen recept om tot een succesmaaltijd',
  recipeCategories: {
    fast: 'Klaar in <strong>{time} minuten</strong>',
    vegetarian: '<strong>Dag zonder vlees</strong>',
    vegan: '<strong>Vegan</strong>',
    bread: '<strong>Brood</strong> recepten',
    pasta: '<strong>Pasta</strong> recepten',
    oat: '<strong>Havermout</strong> recepten',
    rice: '<strong>Rijst</strong> recepten',
    cottageCheese: '<strong>Kwark</strong> recepten',
    vegetableRich: '<strong>Groente</strong> toppers',
    summer: '<strong>Zomertoppers</strong>',
    autumn: '<strong>Herfsttoppers</strong>',
    winter: '<strong>Wintertoppers</strong>',
    spring: '<strong>Lentetoppers</strong>',
    other: '<strong>Overige</strong> recepten',
    classics: '<strong>Familiegerechten</strong>',
    bakingTogether: '<strong>Samen bakken</strong>',
    schijfVanVijf: '<strong>Schijf van Vijf</strong>',
  },
  scanBarcode: 'Scan <strong>barcode</strong> van productverpakking',
  result: 'Resultaat',
  saveTunedMoment: 'Sla {moment} op',
  yourTrainings: '<strong>Mijn trainingen</strong>',
  tuningSatisfaction: 'Ben je tevreden met je maaltijd?',
  thankYouForAddingThisIngredient: 'Bedankt voor het toevoegen van dit ingrediënt!',
  yourMeal: 'Mijn maaltijd',
  ingredientDetails: 'Productdetails',
  suggestions: 'Suggesties',
  recentlyUsedProducts: 'Recent gebruikt',
  history: 'Geschiedenis',
  cameraDenied: 'Toegang tot camera geweigerd',
  unitOfMeasures: 'Maateenheden',
};
