export const ENVIRONMENTS = {
  ACCEPTANCE: 'acceptance',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test',
};

export const PROCESS = {
  PACKAGE_VERSION: process.env.PACKAGE_VERSION || '0',
};

export const PLATFORMS = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
};
