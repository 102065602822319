import VueI18n from 'vue-i18n';
import { dateTimeFormats } from '@/modules/i18n/date-time-formats/dateTimeFormats';
import VueCookies from 'vue-cookies';

export const i18nModule = () => ({
  install(Vue) {
    Vue.use(VueI18n);
    Vue.use(VueCookies, { expires: 'never' });
    const preferredLanguage = Vue.$cookies.get('preferred-language') || 'en';
    this.i18n = new VueI18n({
      locale: preferredLanguage,
      fallbackLocale: 'en',
      dateTimeFormats,
    });
  },
  setTranslations(msgs, lang) {
    // mergeLocaleMessage() converts arrays to objects!
    this.i18n.mergeLocaleMessage(lang, msgs);
  },
});
