export default {
  aboutThisApp: "Over The Athlete's FoodCoach app",
  activityLevel: 'Je activiteitenniveau',
  addIngredients: 'Ingrediënten toevoegen',
  addTraining: 'Training toevoegen',
  customRecipe: 'Eigen recept',
  syncedActivity: 'Gesynchroniseerde activiteit',
  customRecipeDetails: 'Gegevens van je recept',
  customRecipeIntroduction: 'Hoe het werkt',
  customRecipeOptions: 'Eigen recept opties',
  dailyEnergyNeed: 'Je dagelijkse energiebehoefte',
  dateOfBirth: 'Geboortedatum',
  developers: 'Developers',
  editTraining: 'Training wijzigen',
  energyCalculation: 'Energieberekening',
  exactMeasurement: 'Kan ik deze ingrediënten afronden?',
  FAQs: 'Veelgestelde vragen',
  features: 'Features',
  ftpValue: 'Je conditieniveau',
  ingredients: 'Ingrediënten',
  jumboFoodcoachRecipes: "The Athlete's FoodCoach recepten",
  length: 'Je lengte',
  gender: 'Je geslacht',
  birthdate: 'Je geboortedatum',
  macroNutrientsRatio: `<strong>Macronutriëntverhouding</strong> van je recept`,
  myAccount: 'Mijn account',
  myEnergyCalculation: 'Mijn energieberekening',
  myGoal: 'Mijn doel',
  myInfo: 'Mijn gegevens',
  mySport: 'Mijn sport',
  nutrientsInfo: 'Hoe komen deze voedingswaarden tot stand?',
  profile: 'Profiel',
  recipe: 'Recept',
  sleepingHours: 'Je slaapuren',
  sportSettings: 'Mijn sportinstellingen',
  sportType: 'Sporttype',
  searchResults: 'Zoekresultaten',
  trainingSchedule: 'Trainingsschema',
  userRecipes: 'Eigen recepten',
  weight: 'Je gewicht',
  workingHours: 'Je werkuren',
  workType: 'Je type werk',
  yourSport: 'Jouw sport',
  actualsUpdate: 'Actuals aanpassen',
};
