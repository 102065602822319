import onboardingStepSportsGuard from '@/modules/onboarding-sports/guards/onboardingSportsStep/onboardingSportsStep.guard';
import guards from '@/modules/shared/utils/guards.util';

const WeightGoalPage = () =>
  import('@/modules/onboarding-sports/pages/weight-goal/WeightGoalPage.vue');
const NutritionViewPage = () =>
  import('@/modules/onboarding-sports/pages/nutrition-view/NutritionViewPage.vue');
const PerformanceGoalPage = () =>
  import('@/modules/onboarding-sports/pages/performance-goal/PerformanceGoalPage.vue');
// Pages
const PersonalDataPage = () =>
  import('@/modules/onboarding-sports/pages/personal-data/PersonalDataPage');
const EnergyCalculationPage = () =>
  import('@/modules/onboarding-sports/pages/energy-calculation/EnergyCalculationPage.vue');
const SportPage = () => import('@/modules/onboarding-sports/pages/sport/SportPage');
const GenderPage = () => import('@/modules/onboarding-sports/pages/gender/GenderPage');
const BirthdatePage = () => import('@/modules/onboarding-sports/pages/birthdate/BirthdatePage');
const HeightPage = () => import('@/modules/onboarding-sports/pages/height/HeightPage');
const WeightPage = () => import('@/modules/onboarding-sports/pages/weight/WeightPage');
const WorkTypePage = () => import('@/modules/onboarding-sports/pages/work-type/WorkTypePage');
const WorkingHoursPage = () =>
  import('@/modules/onboarding-sports/pages/working-hours/WorkingHoursPage');
const ActivityLevelPage = () =>
  import('@/modules/onboarding-sports/pages/activity-level/ActivityLevelPage');
const SleepingHoursPage = () =>
  import('@/modules/onboarding-sports/pages/sleeping-hours/SleepingHoursPage');
const CreatingPlanPage = () =>
  import('@/modules/onboarding-sports/pages/creating-plan/CreatingPlanPage');

export const ONBOARDING_ROUTES = [
  {
    path: '/onboarding-sports',
    name: 'onboarding-sports',
    redirect: { name: 'onboarding-personal-data' },
  },
  {
    path: '/onboarding-sports/personal-data/:isCurrentSportsUser?',
    name: 'onboarding-personal-data',
    component: PersonalDataPage,
    beforeEnter: guards([
      onboardingStepSportsGuard(
        [{ name: 'acceptsTermsAndConditions', expectedValue: true }],
        'onboarding-additional-info'
      ),
    ]),
  },
  {
    path: '/onboarding-sports/sport-type',
    name: 'onboarding-sport-type',
    component: SportPage,
    meta: {
      noPayWall: true,
    },
  },
  {
    path: '/onboarding-sports/energy-calculation',
    name: 'onboarding-energy-calculation',
    component: EnergyCalculationPage,
  },
  {
    path: '/onboarding/weight-goal',
    name: 'onboarding-weight-goal',
    component: WeightGoalPage,
    meta: {
      noPayWall: true,
    },
  },
  {
    path: '/onboarding-sports/nutrition-view',
    name: 'onboarding-nutrition-view',
    component: NutritionViewPage,
    meta: {
      noPayWall: true,
    },
  },
  {
    path: '/onboarding/performance-goal',
    name: 'onboarding-performance-goal',
    component: PerformanceGoalPage,
    meta: {
      noPayWall: true,
    },
  },
  {
    path: '/onboarding-sports/gender',
    name: 'onboarding-gender',
    component: GenderPage,
  },
  {
    path: '/onboarding-sports/birthdate',
    name: 'onboarding-birthdate',
    component: BirthdatePage,
  },
  {
    path: '/onboarding-sports/height',
    name: 'onboarding-height',
    component: HeightPage,
  },
  {
    path: '/onboarding-sports/weight',
    name: 'onboarding-weight',
    component: WeightPage,
  },
  {
    path: '/onboarding-sports/activity-level-work-day',
    name: 'onboarding-activity-level-work-day',
    component: WorkTypePage,
  },
  {
    path: '/onboarding-sports/average-work-day-hours',
    name: 'onboarding-average-work-day-hours',
    component: WorkingHoursPage,
  },
  {
    path: '/onboarding-sports/activity-level-day-off',
    name: 'onboarding-activity-level-day-off',
    component: ActivityLevelPage,
  },
  {
    path: '/onboarding-sports/average-sleeping-hours',
    name: 'onboarding-average-sleeping-hours',
    component: SleepingHoursPage,
  },
  {
    path: '/onboarding-sports/creating-plan',
    name: 'onboarding-creating-plan',
    component: CreatingPlanPage,
  },
];

export const registerRoutes = router => {
  ONBOARDING_ROUTES.forEach(route => router.addRoute(route));
};
