import { PRO_STATUS } from './constants/pro.const';
import { PRODUCT_IMAGES_URL } from './constants/products.const';
import guards from './guards';
import messages from './messages/nl/shared.nl';
import messagesEn from './messages/en/shared.en';
import mixins from './mixins';
import utils from './utils';

export const sharedModule = i18nModule => ({
  utils,
  mixins,
  guards,
  constants: { PRODUCT_IMAGES_URL, PRO_STATUS },
  install() {
    i18nModule.setTranslations({ shared: { ...messages } }, 'nl');
    i18nModule.setTranslations({ shared: { ...messagesEn } }, 'en');
  },
});
