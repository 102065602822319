export default [
  {
    text: 'Bij elk type fitnesstraining vragen wij om je intensiteit in te vullen. Hieronder geven we een indicatie die je op weg kan helpen om de juiste intensiteit te selecteren.',
  },
  {
    title: 'Rustig, gemiddeld en intensief.',
    text: 'Naast de duur (tijd) bepaalt de intensiteit van je training hoeveel energie jij gebruikt tijdens deze training. Hiervoor kun je kiezen één van de volgende opties: rustig, gemiddeld en intensief.',
  },
  {
    text: 'Je kiest "rustig" wanneer je training niet heel erg intensief is, denk aan een groepsles yoga. Je hartslag en ademfrequentie blijven laag en je zoekt niet je limiet op.',
  },
  {
    text: 'Een training is "gemiddeld" als je je fysiek uitdaagt, maar aan het einde nog wel wat reserve over hebt. Deze trainingen komen over het algemeen het meeste voor.',
  },
  {
    text: '"Intensief" kies je als je hartslag en ademfrequentie tijdens de inspanning (regelmatig) is verhoogd en je vaak de limiet opzoekt. Denk hierbij aan een spinningles of een HIIT training.',
  },
  {
    title: 'Weerstand',
    text: 'Bij krachttraining werk je met weerstand (gewicht). Deze weerstand bepaalt samen met de hoeveelheid rust die jij neemt hoeveel energie je tijdens de training gebruikt.',
  },
  {
    title: 'Veel of weinig weerstand?',
    text: 'De weerstand wordt gebaseerd op het aantal herhalingen dat jij kunt maken met dat gewicht. Kun je maximaal 12 herhalingen maken? Dan kies je ‘veel weerstand’. Lukt het je om meer dan 12 herhalingen te maken, dan kun je het beste ‘weinig weerstand’ selecteren.',
  },
];
