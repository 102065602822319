<template>
  <div class="item-content">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcLinkGroupItemContent',
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
</style>
