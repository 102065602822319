export default [
  {
    text: 'For each type of fitness training, we ask you to enter your intensity. Below we give an indication that can help you select the right intensity.',
  },
  {
    title: 'Easy, average and intensive.',
    text: 'In addition to the duration (time), the intensity of your training determines how much energy you use during this training. For this you can choose one of the following options: quiet, medium and intensive.',
  },
  {
    text: 'You choose "easy" when your training is not very intensive, such as a group yoga class. Your heart rate and breathing rate remain low and you don\'t push your limit.',
  },
  {
    text: 'You choose "Intensive" if your heart rate and breathing frequency have (regularly) increased during the effort and you often push yourself to the limit. Think of a spinning class or a HIIT training.',
  },
  {
    title: 'Resistance',
    text: 'With strength training you work with resistance (weight). This resistance, together with the amount of rest you take, determines how much energy you use during training.',
  },
  {
    title: 'High or low resistance?',
    text: "The resistance is based on the number of repetitions you can do with that weight. Can you do a maximum of 12 reps? Then you choose high resistance'. If you manage to make more than 12 repetitions, it is best to select 'low resistance'.",
  },
];
