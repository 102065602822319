const NutritionViewPage = () =>
  import('@/modules/profile/pages/nutrition-view/NutritionViewPage.vue');
const WeightGoalPage = () => import('@/modules/profile/pages/weight-goal/WeightGoalPage.vue');
const EditSingleProfileFieldPage = () =>
  import(
    '@/modules/profile/pages/personalDetails/account/edit-single-profile-field-page/EditSingleProfileFieldPage.vue'
  );
const GenderPage = () => import('@/modules/profile/pages/personalDetails/gender/GenderPage.vue');

const ProfileOverviewPage = () => import('@/modules/profile/pages/overview/OverviewPage');
const ProfileAccountPage = () =>
  import('@/modules/profile/pages/personalDetails/account/AccountPage');
const PrivacySettingsPage = () =>
  import('@/modules/profile/pages/personalDetails/privacy-settings/PrivacySettingsPage');
const ProfileBirthdatePage = () =>
  import('@/modules/profile/pages/personalDetails/birthdate/BirthdatePage');
const PerformanceGoal = () => import('@/modules/profile/pages/performance-goal/PerformanceGoal');
const ProfileHeightPage = () => import('@/modules/profile/pages/personalDetails/height/HeightPage');
const ProfileWeightPage = () => import('@/modules/profile/pages/personalDetails/weight/WeightPage');
const ProfileWorkTypePage = () =>
  import('@/modules/profile/pages/personalDetails/work-type/WorkTypePage');
const ProfileWorkingHoursPage = () =>
  import('@/modules/profile/pages/personalDetails/working-hours/WorkingHoursPage');
const ProfileActivityLevelPage = () =>
  import('@/modules/profile/pages/personalDetails/activity-level/ActivityLevelPage');
const ProfilePreferredLanguagePage = () =>
  import('@/modules/profile/pages/personalDetails/language/LanguagePage');
const ProfileSleepingHoursPage = () =>
  import('@/modules/profile/pages/personalDetails/sleeping-hours/SleepingHoursPage');
const ProfileFtpPage = () => import('@/modules/profile/pages/sportSettings/ftpPage/ftpPage');
const ProfileSportPage = () => import('@/modules/profile/pages/sportSettings/sportPage/sportPage');
const ProfileEnergyCalculationPage = () =>
  import('@/modules/profile/pages/sportSettings/energyCalculationPage/EnergyCalculationPage.vue');
const ProfileAboutPage = () => import('@/modules/profile/pages/about/AboutPage');
const ConnectDevicePage = () =>
  import('@/modules/profile/pages/connect-devices/ConnectDevicesPage');
const ApproveJoinPage = () => import('@/modules/profile/pages/connect-devices/ApproveJoinPage');
const ConnectDeviceCompletePage = () =>
  import('@/modules/profile/pages/connect-devices/ConnectDeviceCompletePage');
const MyNutritionPlanMetricsPage = () =>
  import('@/modules/profile/pages/my-nutrition-plan-metrics/MyNutritionPlanMetricsPage');
const AppSettingsPage = () => import('@/modules/profile/pages/app-settings/AppSettingsPage');
const MySubscriptionPage = () =>
  import('@/modules/profile/pages/my-subscription/MySubscriptionPage');
const MyGoalsPage = () => import('@/modules/profile/pages/my-goals/MyGoalsPage');
const ProductDatabasePage = () =>
  import('@/modules/profile/pages/product-database/ProductDatabasePage.vue');

export const TAB_ROUTE = {
  path: 'profile',
  name: 'profile',
  component: ProfileOverviewPage,
  meta: {
    noPayWall: true,
  },
};

export const registerRoutes = router => {
  const routes = [
    {
      path: '/profile/my-nutrition-plan-metrics',
      name: 'profile-my-nutrition-plan-metrics',
      component: MyNutritionPlanMetricsPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/my-nutrition-plan-metrics/gender',
      name: 'profile-gender',
      component: GenderPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/app-settings',
      name: 'profile-app-settings',
      component: AppSettingsPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/app-settings/subscription',
      name: 'profile-subscription',
      component: MySubscriptionPage,
    },
    {
      path: '/profile/app-settings/product-database',
      name: 'profile-product-database',
      component: ProductDatabasePage,
    },
    {
      path: '/profile/app-settings/nutrition-view',
      name: 'profile-nutrition-view',
      component: NutritionViewPage,
    },
    {
      path: '/profile/my-goals',
      name: 'profile-my-goals',
      component: MyGoalsPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/account',
      name: 'profile-personal-account',
      component: ProfileAccountPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/account/:fieldName',
      name: 'profile-personal-account-edit',
      component: EditSingleProfileFieldPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/privacy-settings',
      name: 'profile-personal-privacy-settings',
      component: PrivacySettingsPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/performance-goal',
      name: 'profile-performance-goal',
      component: PerformanceGoal,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/weight-goal',
      name: 'profile-weight-goal',
      component: WeightGoalPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/birthdate',
      name: 'profile-personal-birthdate',
      component: ProfileBirthdatePage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/height',
      name: 'profile-personal-height',
      component: ProfileHeightPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/weight',
      name: 'profile-personal-weight',
      component: ProfileWeightPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/work-type',
      name: 'profile-personal-work-type',
      component: ProfileWorkTypePage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/working-hours',
      name: 'profile-personal-working-hours',
      component: ProfileWorkingHoursPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/activity-level',
      name: 'profile-personal-activity-level',
      component: ProfileActivityLevelPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/preferred-language',
      name: 'profile-preferred-language',
      component: ProfilePreferredLanguagePage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/personal/sleeping-hours',
      name: 'profile-personal-sleeping-hours',
      component: ProfileSleepingHoursPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/sport-settings/ftp',
      name: 'profile-sport-settings-ftp',
      component: ProfileFtpPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/sport-settings/sport',
      name: 'profile-sport-settings-sport',
      component: ProfileSportPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/sport-settings/energy-calculation',
      name: 'profile-sport-settings-energy-calculation',
      component: ProfileEnergyCalculationPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/about',
      name: 'profile-about',
      component: ProfileAboutPage,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/profile/connect-devices',
      name: 'profile-connect-devices',
      component: ConnectDevicePage,
    },
    {
      path: '/profile/join/approve',
      name: 'profile-join-approve',
      component: ApproveJoinPage,
    },
    {
      path: '/profile/connect-devices/complete',
      name: 'profile-connect-devices-complete',
      component: ConnectDeviceCompletePage,
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
