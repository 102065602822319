const generalRules = [
  // FTP rule
  {
    name: 'profile_ftp',
    message: 'core.inputErrors.invalid.ftp',
    rule: {
      validate: value => {
        try {
          const parsedValue = parseInt(value, 10);
          return parsedValue >= 80 && parsedValue <= 500;
        } catch {
          return false;
        }
      },
    },
  },
];

export default generalRules;
