import { events } from '../events';

export default class BaseType {
  constructor(component, onClose) {
    this.component = component;
    this.onClose = onClose;
    this.id = BaseType.generateRandomId();
  }

  static generateRandomId() {
    const date = new Date();
    const time = date.getTime();
    const random = Math.ceil(Math.random() * 1000);

    return `m-${time}-${random}`;
  }

  close() {
    events.$emit('remove', this.id);
    if (this.onClose) this.onClose(this);
  }
}
