import { UnitOfMeasure } from '@blancofoodcoach/content-scaling/model/unit-of-measure';

export const UNITS = {
  CAL: 'shared.counts.kcal',
  GRAMS: `shared.counts.${UnitOfMeasure.Grams}`,
  BOOL: 'shared.counts.bool',
  PERCENT: 'shared.counts.pct',
};

export const COLORS = {
  BLACK: '#000000',
  PRIMARY: '#eeb717',
  SUCCESS: '#0fc647',
  ORANGE: '#ec7022',
  TAF_RED: '#F94D3F',
  TAF_YELLOW: '#FFB736',
  TAF_PURPLE: '#908AC0',
  TAF_GREEN: '#0D8933',
};

export const NUTRITION_TYPES = {
  calories: {
    key: 'calories',
    label: 'shared.nutrients.calories',
    color: COLORS.BLACK,
    unit: UNITS.CAL,
  },
  carbs: {
    key: 'carbs',
    label: 'shared.nutrients.carbs',
    unit: UNITS.GRAMS,
    color: COLORS.TAF_RED,
    caloriesPerGram: 4,
  },
  protein: {
    key: 'protein',
    label: 'shared.nutrients.protein',
    unit: UNITS.GRAMS,
    color: COLORS.TAF_YELLOW,
    caloriesPerGram: 4,
  },
  fat: {
    key: 'fat',
    label: 'shared.nutrients.fat',
    unit: UNITS.GRAMS,
    color: COLORS.TAF_PURPLE,
    caloriesPerGram: 9,
  },
  fiber: {
    key: 'fiber',
    label: 'shared.nutrients.fiber',
    unit: UNITS.GRAMS,
    color: COLORS.TAF_GREEN,
    caloriesPerGram: 2,
  },
};

export const NUTRIENT_KEYS = {
  carbs: {
    relativeKey: 'relativeCarbs',
    relativeRangeKey: 'carbsRange',
  },
  protein: {
    relativeKey: 'relativeProteins',
    relativeRangeKey: 'proteinRange',
  },
  fat: {
    relativeKey: 'relativeFat',
    relativeRangeKey: 'fatRange',
  },
  fiber: {
    relativeKey: 'relativeFiber',
    relativeRangeKey: 'fiberRange',
  },
};
