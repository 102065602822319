import { between, required } from 'vee-validate/dist/rules.umd';
import { TEAMSPORT } from '@/modules/shared/constants/profile.const';
import { age } from './utils.validator';

const onboardingRules = [
  // additional info rules
  {
    name: 'onboarding_gender_required',
    message: 'core.inputErrors.required.gender',
    rule: required,
  },
  {
    name: 'onboarding_birthDate_required',
    message: 'core.inputErrors.required.dateOfBirth',
    rule: required,
  },
  {
    name: 'onboarding_birthDate_invalid',
    message: 'core.inputErrors.invalid.dateOfBirth',
    rule: {
      validate: age,
    },
  },
  {
    name: 'onboarding_acceptsTermsAndConditions_required',
    message: 'core.inputErrors.required.termsAndConditions',
    rule: required,
  },
  {
    name: 'onboarding_acceptsSportsTermsAndConditions_required',
    message: 'core.inputErrors.required.sportsTermsAndConditions',
    rule: required,
  },

  // sportType rules
  {
    name: 'onboarding_sport_required',
    message: 'core.inputErrors.required.sportType',
    rule: required,
  },

  {
    name: 'not_teamsport',
    message: 'core.inputErrors.required.sportType',
    rule: {
      validate: value => value !== TEAMSPORT,
    },
  },

  // height rules
  {
    name: 'onboarding_height_required',
    message: 'core.inputErrors.invalid.height',
    rule: required,
  },
  {
    name: 'onboarding_height_invalid',
    message: 'core.inputErrors.invalid.height',
    rule: between,
  },

  // weight rules
  {
    name: 'onboarding_weight_required',
    message: 'core.inputErrors.invalid.weight',
    rule: required,
  },
  {
    name: 'onboarding_weight_invalid',
    message: 'core.inputErrors.invalid.weight',
    rule: between,
  },

  // workType rules
  {
    name: 'onboarding_workType_required',
    message: 'core.inputErrors.required.activityLevelWorkDay',
    rule: required,
  },

  // activityLevel rules
  {
    name: 'onboarding_activityLevel_required',
    message: 'core.inputErrors.required.activityLevel',
    rule: required,
  },

  // workDayHours rules
  {
    name: 'onboarding_avgWorkDayHours_required',
    message: 'core.inputErrors.invalid.workDayHours',
    rule: required,
  },
  {
    name: 'onboarding_avgWorkDayHours_invalid',
    message: 'core.inputErrors.invalid.workDayHours',
    rule: between,
  },

  // sleepingHours rules
  {
    name: 'onboarding_sleepingHours_required',
    message: 'core.inputErrors.invalid.sleepingHours',
    rule: required,
  },
  {
    name: 'onboarding_sleepingHours_invalid',
    message: 'core.inputErrors.invalid.sleepingHours',
    rule: between,
  },
];

export default onboardingRules;
