export default {
  mealDetailIntroduction: {
    title: 'Customized meals',
    message: {
      default:
        'We customize all your meals. This way you always get the right amount of carbohydrates, proteins and fats per meal.',
      userRecipe:
        'We will scale the ingredients based on the macronutrient ratio of your recipe, so that you get the correct number of kilocalories.',
    },
    confirmText: "That's clear",
  },
};
