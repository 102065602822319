export default {
  aboutThisApp: "Abput The Athlete's FoodCoach app",
  activityLevel: 'Activity level',
  addIngredients: 'Add ingreideints',
  addTraining: 'Add training',
  customRecipe: 'Your recipe',
  syncedActivity: 'Synced activity',
  customRecipeDetails: 'Recipe data',
  customRecipeIntroduction: 'Explanation',
  customRecipeOptions: 'Recipe options',
  dailyEnergyNeed: 'Energy needs',
  dateOfBirth: 'Date of birth',
  developers: 'Developers',
  editTraining: 'Edit training',
  energyCalculation: 'Energy calculation',
  exactMeasurement: 'Can I round up these ingredients?',
  FAQs: 'Frequently asked questions',
  features: 'Features',
  ftpValue: 'FTP',
  ingredients: 'Ingredients',
  jumboFoodcoachRecipes: 'FoodCoach recipes',
  length: 'Height',
  gender: 'Gender',
  birthdate: 'Date of birth',
  macroNutrientsRatio: `<strong>Macronutrients ration</strong> of this recipe`,
  myAccount: 'My account',

  myEnergyCalculation: 'Sport hours',
  myGoal: 'My goal',
  myInfo: 'Personal data',
  mySport: 'Sport',
  nutrientsInfo: 'How are these nutritional values ​​determined?',
  profile: 'Profile',
  recipe: 'Recipe',
  sleepingHours: 'Sleeping hours',
  sportSettings: 'Sport settings',
  sportType: 'Sport',
  searchResults: 'Results',
  trainingSchedule: 'Training scheme',
  userRecipes: 'Your recipes',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Work type',
  yourSport: 'Sport',
  actualsUpdate: 'Change training data',
};
