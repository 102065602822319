import apollo from '@/modules/shared/graphql/apollo.config';
import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';

export const getUserProfile = async () => {
  const {
    data: {
      getUserProfile: { user },
    },
  } = await apollo.defaultClient.query({ query: GET_USER_PROFILE_QUERY });

  return user;
};
