export default {
  activityLevel: 'Activiteitenniveau',
  dateOfBirth: 'Geboortedatum',
  gender: 'Geslacht',
  length: 'Lengte',
  mySport: 'Mijn sport',
  sleepingHours: 'Slaapuren',
  weight: 'Gewicht',
  workingHours: 'Werkuren',
  workType: 'Type werk',
  energyCalculationExplanation: 'Welke methode past het beste bij mij?',
};
