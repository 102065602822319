export default {
  addOwnIngredient: {
    title: 'Eigen ingrediënt toevoegen',
    message:
      'Het toevoegen van eigen ingrediënten werkt helaas nog niet. We werken eraan om dit zo snel mogelijk in de app te krijgen.',
    confirmText: 'Oké',
  },
  changeInFtp: {
    title: 'Wijziging FTP-waarde',
    message: `Door je wijziging is het wattage van al geplande trainingen mogelijk inaccuraat, pas dit evt. aan bij ‘trainingen’`,
    confirmText: 'Oké',
    cancelText: 'Annuleren',
  },
  changeInProfile: {
    title: 'Profiel wijziging',
    message:
      'Bij een wijziging van één van de profielgegevens verandert de dagelijkse energiebehoefte.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  confirmAccountReset: {
    title: "The Athlete's FoodCoach account verwijderen",
    message:
      "Wat jammer dat je je The Athlete's FoodCoach account wil verwijderen. Als je doorgaat blijft nog wel je Jumbo.com account actief.",
    confirmText: 'Verwijderen',
    cancelText: 'Annuleren',
  },
  createTraining: {
    title: 'Training toevoegen',
    message: 'Door het toevoegen van deze training verandert je energiebehoefte voor deze dag, ',
  },
  pairActivity: {
    title: 'Nieuwe activiteit gesynchroniseerd!',
    message: 'Wil je deze koppelen aan je geplande training?',
    confirmText: 'Ja, koppelen',
    cancelText: 'Nee',
  },
  pairActivityMultipleTraining: {
    title: 'Nieuwe activiteit gesynchroniseerd!',
    pairToTraining: 'Wil je dit koppelen aan een van je geplande trainingen?',
    selectTraining: 'Aan welke trainingssessie wil je deze koppelen?',
    confirmText: 'Ja, koppelen',
    cancelText: 'Nee',
  },
  trainingImportance: {
    title: 'Weet je zeker dat dit je primaire training is?',
    message:
      'Je hebt al een training gepland, die is aangemerkt als primaire training. Als je deze training als primaire training maakt, wordt de andere training secundair.',
    confirmText: 'Ja, doorgaan',
    cancelText: 'Nee, annuleren',
  },
  deleteUserRecipe: {
    title: 'Eigen recept verwijderen',
    message:
      'Door dit recept te verwijderen, wordt hij verwijderd van alle toekomstige ingeplande momenten.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  energyIntroduction: {
    title: 'Je energiebehoefte',
    message:
      'Wanneer je een training plant, verandert je energiebehoefte. Dit heeft invloed op de hoeveelheid koolhydraten, eiwitten en vetten.',
    confirmText: 'Oké, duidelijk',
  },
  mealDetailIntroduction: {
    title: 'Maaltijden op maat',
    message: {
      default:
        'We stellen al jouw maaltijden op maat samen. Zo krijg je altijd de juiste hoeveelheid koolhydraten, eiwitten en vetten per maaltijd binnen.',
      userRecipe:
        'Op basis van de macronutriëntenverhouding van je recept gaan wij de ingrediënten schalen, zodat je het juiste aantal kilocalorieën binnen krijgt.',
    },
    confirmText: 'Oké, duidelijk',
  },
  moveTraining: {
    title: 'Training wijzigen',
    message:
      'Door je wijziging verandert je energiebehoefte en worden eventueel geplande maaltijden voor deze dag(en) verwijderd.',
  },
  removeTraining: {
    title: 'Training verwijderen',
    message: 'Door het verwijderen van deze training verandert je energiebehoefte voor deze dag, ',
  },
  trainingMoment: {
    mealsRemoved:
      ' | hierdoor wordt {n} geplande maaltijd verwijderd. | hierdoor worden {n} geplande maaltijden verwijderd.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  updateTraining: {
    title: 'Training wijzigen',
    message: 'Door het wijzigen van deze training verandert je energiebehoefte voor deze dag, ',
  },
  deleteActivity: {
    title: 'Weet je zeker dat je deze activiteit wilt verwijderen?',
    message:
      'Deze gesynchroniseerde activiteit wordt verwijderd uit je plan, dit kan niet ongedaan worden gemaakt.',
    cancelText: 'Nee, annuleren',
    confirmText: 'Ja, activiteit verwijderen',
  },
  activityAlreadyPaired: {
    title: 'Weet je zeker dat je deze activiteit wilt koppelen?',
    message:
      'Deze activiteit is al gekoppeld aan een training. Als je deze activiteit koppelt aan deze training, wordt de koppeling met de andere training verwijderd.',
    cancelText: 'Nee, annuleren',
    confirmText: 'Ja, koppelen',
  },
};
