export default {
  logout: 'Uitloggen',
  deleteFoodcoachAccount: 'FoodCoach account verwijderen',
  editAccountJumboSite: 'Wijzig je gegevens op jumbo.com',
  resetPassword: 'Wachtwoord opnieuw instellen',
  save: 'Opslaan',
  readMore: 'Lees meer',
  approve: 'Accepteren',
  reject: 'Afwijzen',
};
