<script>
  import { computed, defineComponent, onActivated, ref } from 'vue';
  import { useRoute } from 'vue-router/composables';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumCollapsible, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import ExtraSupport from '@/modules/home/components/extra-support/ExtraSupport.vue';
  import { usePlaylist } from '@/composables/usePlaylist';
  import ProgressBar from '@/components/library/progress-bar/ProgressBar.vue';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';
  import qs from 'qs';
  import getPlaylistProgressQuery from '@/modules/shared/graphql/queries/getPlaylistProgressQuery.query.graphql';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import { createChallengeFromStrapiResponse } from '@/util/gamification/challenge';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { useMutation } from '@/modules/shared/utils/apollo.util';
  import ADD_MEDAL_MUTATION from '@/graphql/mutations/addMedal.mutation.graphql';
  import UPDATE_USER_PROFILE_MUTATION from '@/modules/shared/graphql/mutations/updateUserProfile.mutation.graphql';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import MedalPopup from '@/components/medal-popup/MedalPopup.vue';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  export default defineComponent({
    name: 'ChallengeDetailPage',
    components: {
      DomPurify,
      MedalPopup,
      LinkCardList,
      ProgressBar,
      JumCollapsible,
      ExtraSupport,
      JumHeading,
      DefaultSpacer,
      JumIcon,
    },
    setup() {
      const { apollo } = useVueProxy();
      const route = useRoute();
      const { slug, challengeSlug } = route.params;
      const { preview } = route.query;
      const { playlist, addPlaylistProgress } = usePlaylist(slug, !!preview);
      const challenge = ref();
      const { hasFlag } = useProfile('network-only');
      const showMedals = computed(() => hasFlag('medals'));

      const fetch = async () => {
        const query = qs.stringify({
          populate: 'deep,8',
          filters: {
            uuid: {
              $eq: challengeSlug,
            },
          },
        });

        const progressResponse = await apollo.query({
          query: getPlaylistProgressQuery,
          variables: {
            playlistId: slug,
          },
          fetchPolicy: 'no-cache',
        });

        const response = await http.get(`challenges?${query}`);
        challenge.value = createChallengeFromStrapiResponse(
          response.data.data[0],
          progressResponse.data.playlist,
          null,
          slug
        );
      };

      const mutatePlaylistId = async newValue => {
        await apollo.mutate({
          mutation: UPDATE_USER_PROFILE_MUTATION,
          variables: { updateUserProfileInput: { playlistId: newValue } },
        });
      };

      const medalToReward = computed(() => {
        const { uuid: challengeUuid, medal } = challenge.value;
        if (!medal) return null;

        const medalTypes = ['bronze', 'silver', 'gold'];
        const foundType = medalTypes.find(type => medal[type]?.challenge?.uuid === challengeUuid);

        return medal[foundType] || null;
      });

      const isOpenMedalPopup = ref(false);

      const addMedal = async (medalId, score) => {
        useMutation(ADD_MEDAL_MUTATION, {
          medalId,
          score,
        });
      };

      const findMedal = () => {
        const { uuid: challengeUuid, medal } = challenge.value;
        if (!medal) return null;

        const medalTypes = ['bronze', 'silver', 'gold'];
        const foundMedal = medalTypes.find(type => medal[type]?.challenge?.uuid === challengeUuid);

        return foundMedal || null;
      };

      onActivated(async () => {
        await fetch();

        if (challenge.value && challenge.value.medal === null) {
          return;
        }

        const medal = findMedal();

        if (showMedals.value && challenge.value.completed && !medal.isCompleted) {
          await addMedal(challenge.value.medal.uuid, medal.name.toUpperCase());
          await mutatePlaylistId(null);
        }
      });

      const steps = computed(() => {
        let opened = false;
        return challenge.value.steps.map(step => {
          const isCompleted = step.items.every(link => link.completed);
          let isOpen = false;

          if (!isCompleted && !opened) {
            isOpen = true;
            opened = true;
          }
          return {
            ...step,
            isCompleted,
            isOpen,
          };
        });
      });

      const checkIfChallengeIsCompleted = async () => {
        if (steps.value.every(step => step.isCompleted)) {
          await addPlaylistProgress(playlist.value.uuid, challenge.value.uuid);
        }
      };

      const theme = computed(() => challenge.value?.tag ?? null);

      const handleItemSelected = async link => {
        if (link.tag.type === 'learning') {
          await addPlaylistProgress(playlist.value.uuid, link.uuid);
          await fetch();
        }

        await checkIfChallengeIsCompleted();
      };

      const closePopup = () => {
        isOpenMedalPopup.value = false;
      };

      return {
        playlist,
        challenge,
        handleItemSelected,
        steps,
        theme,
        closePopup,
        isOpenMedalPopup,
        medalToReward,
      };
    },
  });
</script>

<template>
  <fc-page v-if="playlist && challenge">
    <template #top>
      <fc-nav-bar
        :back-button="{
          name: 'playlist-detail',
          params: { slug: playlist.slug },
        }"
      >
        {{ $t('title') }}
      </fc-nav-bar>
    </template>

    <fc-page-content :class="{ [theme]: theme !== null }">
      <div v-if="challenge.cardImageUrl" class="image">
        <img :src="challenge.cardImageUrl" :alt="`Image for ${challenge.title}`" />
      </div>

      <default-spacer s />

      <div class="h-group">
        <jum-heading h3 bold class="title">
          {{ challenge.title }}
        </jum-heading>
      </div>

      <p class="body">
        {{ challenge.description }}
      </p>

      <section v-if="challenge.completed && challenge.takeHomeMessages" class="take-home">
        <jum-heading h4 bold>
          {{ $t('takeHomeMessageTitle') }}
        </jum-heading>

        <dom-purify :html="challenge.takeHomeMessages" />
      </section>

      <default-spacer />

      <progress-bar
        :current-step="challenge.completedItems"
        :total-steps="challenge.totalItems"
        :progress="challenge.progressForItems"
      />

      <default-spacer />

      <template v-if="steps">
        <jum-collapsible v-for="(step, index) in steps" :key="`step-${index}`" :open="step.isOpen">
          <template #title>
            <div class="step-title">
              <template v-if="step.isCompleted">
                <div class="dot completed">
                  <jum-icon icon="jum-check" size="12" />
                </div>
              </template>
              <template v-else>
                <div class="dot">
                  {{ index + 1 }}
                </div>
              </template>

              <jum-heading h5 bold>
                {{ step.title }}
              </jum-heading>
            </div>
          </template>

          <link-card-list :links="step.items" @item-selected="handleItemSelected" />

          <default-spacer s />
        </jum-collapsible>

        <default-spacer />
      </template>

      <extra-support v-if="challenge.showExtraSupportSection" />
    </fc-page-content>

    <portal v-if="medalToReward" to="overlay">
      <medal-popup
        :medal="medalToReward"
        :is-open="isOpenMedalPopup"
        @proceed="closePopup"
        @cancel="closePopup"
      />
    </portal>
  </fc-page>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .step-title {
    align-items: center;
    display: flex;
    gap: $spacing-s;

    .jum-heading {
      margin: 0;
    }

    .dot {
      align-items: center;
      background-color: #e3e3e3;
      border-radius: 50%;
      display: flex;
      font-size: 14px;
      font-weight: 300;
      height: 20px;
      justify-content: center;
      width: 20px;

      &.completed {
        background-color: #0d8933;

        svg {
          fill: $color-white;
        }
      }
    }
  }

  .image {
    overflow: hidden;

    img {
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .body {
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 1rem * 1.3 !important;
  }

  .h-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    z-index: 1;

    > * {
      margin: 0;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    .fc-numbered-list-item {
      margin-top: 0;
    }
  }

  .perform {
    :deep(.progress-bar) {
      border-color: $taf-red;

      .progress-bar__fill {
        background-color: #feedec;
      }
    }

    :deep(.tooltip) {
      background-color: $taf-red;

      &::after {
        border-color: $taf-red transparent transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: $taf-red;
        }
      }
    }
  }

  .recover {
    :deep(.progress-bar) {
      border-color: #ffb736;

      .progress-bar__fill {
        background-color: #fff6e7;
      }
    }

    :deep(.tooltip) {
      background-color: #ffb736;

      &::after {
        border-color: #ffb736 transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #ffb736;
        }
      }
    }
  }

  .protect {
    :deep(.progress-bar) {
      border-color: $taf-green;

      .progress-bar__fill {
        background-color: #e7fded;
      }
    }

    :deep(.tooltip) {
      background-color: $taf-green;

      &::after {
        border-color: $taf-green transparent transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: $taf-green;
        }
      }
    }
  }

  .coach {
    :deep(.progress-bar) {
      border-color: #908ac0;

      .progress-bar__fill {
        background-color: #f1f0f7;
      }
    }

    :deep(.tooltip) {
      background-color: #908ac0;

      &::after {
        border-color: #908ac0 transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #908ac0;
        }
      }
    }
  }

  .specials {
    :deep(.progress-bar) {
      border-color: #11aeed;

      .progress-bar__fill {
        background-color: #feffff;
      }
    }

    :deep(.tooltip) {
      background-color: #11aeed;

      &::after {
        border-color: #11aeed transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #11aeed;
        }
      }
    }
  }

  .take-home {
    padding-top: 10px;

    :deep(.jum-heading) {
      margin-bottom: 10px;
    }

    span {
      :deep(ul),
      :deep(ol) {
        margin: 10px;
        padding-left: 20px;

        li:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      :deep(p) {
        line-height: 1.4rem;
        white-space: pre-line;
      }

      :deep(*):last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

<i18n>
  {
    "en": {
      "title": "Challenge"
    },
    "nl": {
      "title": "Uitdaging"
    }
  }
</i18n>
