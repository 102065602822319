export const TRAINING_TYPES = {
  STRENGTH_TRAINING: 'Krachttraining',
  CARDIO_STRENGTH_TRAINING: 'Cardio + krachttraining',
  CARDIO_GROUP_TRAINING: 'Cardio/groepsles',
  CIRCUIT_CROSSFIT_TRAINING: 'CrossFit',
};

export const trainingTypesTranslations = () => ({
  recovery: 'Actief herstel',
  strength: TRAINING_TYPES.STRENGTH_TRAINING,
  endurance: 'Duurtraining',
  enduranceLowCarbs: 'Duurtraining (low carb)',
  tempo: 'Tempotraining',
  carbLoading: 'Carb loading',
  interval: 'Intervaltraining',
  normalRace: 'Normale race',
  normalRaceLateStart: 'Normale race (late start)',
  normalRaceLateDinner: 'Normale race (laat diner)',
  hardRace: 'Zware race',
  hardRaceLateStart: 'Zware race (late start)',
  hardRaceLateDinner: 'Zware race (laat diner)',
  timeTrial: 'Tijdrit',
  matchDayKeeper: 'Wedstrijd (keeper)',
  matchDayPlayer: 'Wedstrijd (veldspeler)',
  normalTraining: 'Normale training',
  intensiveTraining: 'Intensieve training',
  gameDayEvening: 'Game day avond',
  cardioGroupLessonEasy: 'Cardio/groepsles rustig',
  cardioGroupLessonIntensive: 'Cardio/groepsles intensief',
  cardioGroupLessonModerate: 'Cardio/groepsles gemiddeld',
  cardioStrengthEasy: 'Cardio + krachttraining rustig',
  cardioStrengthIntensive: 'Cardio + krachttraining intensief',
  cardioStrengthModerate: 'Cardio + krachttraining gemiddeld',
  circuitCrossfitIntensive: 'CrossFit intensief',
  circuitCrossfitModerate: 'CrossFit gemiddeld',
  gameDayMinusOneAm: 'Game day -1AM',
  gameDayMinusOnePm: 'Game day -1PM',
  gameDayMinusThreeAm: 'Game day -3AM',
  gameDayMinusThreePm: 'Game day -3PM',
  gameDayMinusTwoAm: 'Game day -2AM',
  gameDayMinusTwoPm: 'Game day -2PM',
  keeperTraining: 'Keeperstraining',
  recoveryCycling: 'Losfietsen',
  strengthLittleResistanceLittleRest: 'Krachttraining weinig weerstand 0-120 sec rust',
  strengthLittleResistanceLotsRest: 'Krachttraining weinig weerstand > 120 sec rust',
  strengthLotsResistanceLittleRest: 'Krachttraining veel weerstand 0-120 sec rust',
  strengthLotsResistanceLotsRest: 'Krachttraining veel weerstand > 120 sec rust',
  matchDayKeeperAfternoon: 'Wedstrijd (keeper) 13:00-17:00',
  matchDayKeeperEvening: 'Wedstrijd (keeper) 17:00+',
  matchDayKeeperMinusOne: 'Wedstrijd (keeper) -1',
  matchDayPlayerAfternoon: 'Wedstrijd (veldspeler) 13:00-17:00',
  matchDayPlayerEvening: 'Wedstrijd (veldspeler) 17:00+',
  matchDayPlayerMinusOne: 'Wedstrijd (veldspeler) -1',
  normalTrainingKeeperAfternoon: 'Normale training (keeper) 13:00-17:00',
  normalTrainingKeeperMorning: 'Normale training (keeper)',
  normalTrainingPlayerAfternoon: 'Normale training (veldspeler) 13:00-17:00',
  normalTrainingPlayerMorning: 'Normale training (veldspeler)',
  recover: 'Hersteltraining',
});
