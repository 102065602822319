<template>
  <renderless-items type="tipMenus">
    <template #default="{ activeTipMenu }">
      <transition v-if="activeTipMenu && activeTipMenu.type === 'tipmenu'" name="open">
        <div
          v-if="activeTipMenu"
          ref="niceTipMenus"
          class="nice-tipmenus"
          @mousedown.self="mouseDown(activeTipMenu)"
        >
          <fc-tip-menu
            :buttons="activeTipMenu.buttons"
            :full-width="activeTipMenu.fullWidth"
            :y="activeTipMenu.y"
            @close="activeTipMenu.close()"
          />
        </div>
      </transition>
    </template>
  </renderless-items>
</template>

<script>
  import { defineComponent } from 'vue';
  import FcTipMenu from '@/components/library/tip-menu/tip-menu.vue';

  export default defineComponent({
    name: 'NiceTipMenus',
    components: { FcTipMenu },
    data: () => ({
      activeTipMenu: null,
    }),
    created() {
      document.addEventListener('mouseup', this.mouseUp);
    },
    beforeDestroy() {
      document.removeEventListener('mouseup', this.mouseUp);
    },
    methods: {
      mouseDown(activeTipMenu) {
        this.activeTipMenu = activeTipMenu;
      },
      mouseUp($event) {
        if (this.activeTipMenu && $event.target === this.$refs.niceTipMenus) {
          this.activeTipMenu.close();
        }

        this.activeTipMenu = null;
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-tipmenus {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-grow: revert;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-page-popover;

    &__tipmenu {
      bottom: 0;
      flex-grow: 1;
      will-change: bottom, transform;
      //z-index: 301;
    }

    &::after {
      //background-color: rgba($color-black, 0.5);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 0.3s ease;
      //z-index: 20;
    }
  }

  .open-enter,
  .open-leave-to {
    opacity: 0;
    transition: all ease 400ms;
  }

  .open-enter-active,
  .open-leave-active {
    transition: all ease 400ms;
  }

  .open-enter-to,
  .open-leave {
    opacity: 1;
  }
</style>
