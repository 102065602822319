export default {
  actualsNotificationTitle: 'Did you just do a training?',
  actualsNotificationText:
    'Are the planned values ​different from the actual training? Adjust your training data now.',
  actualsInfoText:
    'In this screen you can adjust the data of your training. For now you can only adjust the data time and wattage. Your changed energy requirement will be visible in you plan.',
  activityLevel: {
    question:
      'When you are not exercising or working, to what extent are you still physically active?',
    explanation:
      'For example, do you spend a lot of time at home, watch television and do your shopping in between or do you walk the dog? Then you are average active. You are very quite when you spend hours behind the bar in the sports canteen. And do you spend hours doing odd jobs or gardening in your free time? Then you are very active.',
  },
  addFirstTraining: 'Ready to add your first training?',
  blogs:
    'Do you want to have enough energy around sports, get your resistance in order and recover well? We inspire and coach you.',
  customRecipeIntroductionUSPs: [
    'Add the ingredients of your favourite recipe',
    'Play around with the ratio of the ingredients to make your recipe more suitable for your nutritional plan',
    'Schedule your recipe. We save it for you so that you can reschedule the recipe on other days.',
  ],
  dailyEnergyNeed: {
    explanation:
      'Great, we determined your profile on a rest day! We know what your average energy needs are and can give you a direction on how many carbohydrates, proteins and fats you need.',
    edit: 'You can check this information and always change it in your profile.',
  },
  doesNotMatchMealPlan:
    'Note: the macronutrient ratio of your recipe does not fit within your nutrition plan. {editRecipe} before inserting it.',
  exactMeasurement: [
    {
      text: 'When Wout van Aert cooks at home with FoodCoach and has to put 0.7 zucchini in his dish, he often chooses to use the whole zucchini. The Team Visma | Lease a Bike rider is stricter when it comes to the amount of chicken or pasta in his meal. "I look at each ingredient to see if it\'s smart to take more or not," he says.',
    },
    {
      text: `In principle, you can round up vegetables, adds Karin Lambrechtse as performance chef and sports dietitian for Team Visma | Lease a Bike. “There are hardly any macronutrients in vegetables. As a result, they do little with your calorie intake. On the other hand, vegetables give a boost to your vitamins and minerals and contain fibers that are important for your intestines. But remember that a higher intake of vegetables should not be at the expense of your recommended macronutrient intake.`,
    },
    {
      text: `Lambrechtse advises to be careful with products, such as chicken and pasta, that contain more macronutrients, and therefore energy. Although she emphasizes that a little slack can't hurt. 'If you end up slightly higher or lower with your energy intake, it's not immediately a big deal, as long as it's not 100 kilocalories too much or too little every day. Realize that The Athlete's FoodCoach presents you with the ideal nutrition plan. So stay as close to the advice as possible.’`,
    },
    {
      text: "That is also what Van Aert does. 'FoodCoach makes healthy and varied eating more fun and easy because you gain new inspiration and gain insight into what your body needs per eating moment.'",
    },
  ],
  ftpExplanationIntro:
    'The most reliable way to measure your FTP is to do an exercise test with an exercise physiologist or sports doctor. An alternative method is to measure yourself. Smart exercise bikes with power measurement often offer standard 20-minute FTP tests or work on standard tests of software programs such as Zwift.',
  ftpExplanation: [
    {
      text: 'You can also perform the 20-minute test outdoors, provided your bike has a power meter. Try to do this test under favorable conditions: on a flat course (or one long steady climb of max 6%) and preferably without bends and without too much traffic. After a good warm-up, you cycle as hard as possible for 20 minutes at a constant pace. From the average power you pedal in those 20 minutes, subtract 5% to estimate your FTP.',
    },
    {
      text: "With the above tests you can measure your FTP quite accurately. Please note that the estimated FTP value of The Athlete's FoodCoach may be an exaggerated or incorrect estimate. As a result, the energy calculation may be incorrect.",
    },

    {
      text: 'You can also look up your FTP value via activity trackers or connected apps. The same applies to the estimated FTP  of FoodCoach: this is less accurate than an exercise test with an exercise physiologist or sports physician or when you perform the test yourself.',
    },
  ],
  ftpExplanationEstimation:
    'Based on your data, we have made an initial, conservative estimate of your FTP.',
  ftpExplanationQuestion: 'Do you know your FTP? Then you can adjust it yourself above.',
  ftpValue: {
    explanation:
      "Just like with the riders of Team Visma | Lease a Bike, we determine how intensive an effort is for you based on your FTP. Don't know your FTP? Then we will help you. We have made an initial, conservative estimate of your FTP.",
    edit: 'When your FTP changes, you can easily adjust it in your profile.',
  },
  trainingManagedBy: {
    join: 'This training is managed by JOIN. If you want to change training duration, type or intensity, please open the JOIN app.',
    trainingpeaks:
      'This training is managed by TrainingPeaks. If you want to change training duration, type or intensity, please open the TrainingPeaks app and synchronze the training.',
  },
  length:
    "To be able to coach you properly, we first calculate what your body needs in energy if you don't exercise. For this we need some information from you. Start with your height.",
  myGoal:
    'Eat healthier? Adjust your goal here. Healthier eating habits for more energy: plan a weekly menu, make food changes and be more conscious about food.',
  noMatchingIngredients:
    'You can add an ingredient yourself, with the corresponding nutritional values.',
  nutrientsInfo: [
    {
      title: 'Accountability energy requirement calculation',
      text: `Athletes have a significantly higher resting energy requirement than non-athletes. This is mainly due to the higher muscle mass compared to non-athletes. For this reason, the Ten Haaf-Weijs formula is used [1]. This ten Haaf formula determines your energy requirement in complete rest (your BMR). You need more energy for all the things you do in daily life, these can be the smallest things. A Metabolic Equivalent of Task (MET value) compensates for this[2]. The The Athlete's FoodCoach app calculates your physical activity level (PAL value) using four MET values that you enter yourself (the number of sleeping hours, the type of work, the number of working hours and your activity level outside of sports and work). By multiplying this BMR and PAL value, we calculate your energy needs on a rest day. Do you exercise? Then we correct your energy consumption for this [2,3].`,
    },
    {
      title: 'Accountability macronutrient calculation',
      text: `The distribution of energy, carbohydrates, proteins and fats depends on the meal times and the type of training, the time of training, the duration and intensity of the training, but also on personal factors such as height, age and weight. The Athlete's FoodCoach helps with the calculation of your energy needs and with the macronutrient distribution per meal according to the methods that are based on the sports nutrition guidelines and have also been applied to top athletes, including the riders of Team Visma | Lease a Bike.`,
    },
    {
      title: 'References',
      text: `[1] Ten Haaf, T. & Weijs, P. J. M. Resting Energy Expenditure Prediction in Recreational Athletes of 18-35 Years: Confirmation of Cunningham Equation and an Improved Weight-Based Alternative. (2014) doi:10.1371/journal.pone.0108460. {0}`,
      links: [
        {
          text: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4183531/',
          url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4183531/',
        },
      ],
    },
    {
      text: `[2] Ainsworth, B. E. et al. 2011 Compendium of Physical Activities: A Second Update of Codes and MET Values. Med. Sci. Sport. Exerc 43, 1575–1581 (2011). {0}`,
      links: [
        {
          text: 'https://pubmed.ncbi.nlm.nih.gov/21681120/',
          url: 'https://pubmed.ncbi.nlm.nih.gov/21681120/',
        },
      ],
    },
    {
      text: `[3]. Margaria, R., Cerretelli, P., Aghemo, P. & Sassi, G. Energy cost of running. J. Appl. Physiol. 18, 367–370 (1963). {0}`,
      links: [
        {
          text: 'https://journals.physiology.org/doi/abs/10.1152/jappl.1963.18.2.367',
          url: 'https://journals.physiology.org/doi/abs/10.1152/jappl.1963.18.2.367',
        },
      ],
    },
  ],
  onActivityExplanation: [
    {
      text: 'Sore muscles, dizziness and a blur in his eyes. It\'s the symptoms when Wout van Aert has a hunger pang. "You want to, but you feel completely empty. You ask things of your body that it no longer has the energy for," says the Team Visma | Lease a Bike rider.',
    },
    {
      text: "When you're hungry, you've completely depleted your carbohydrate stores: your body's favorite fuel for high-intensity exercise. Your body is forced to switch to your fat burning. A much less fast fuel, so you can no longer keep cycling at the same pace. \"Your body enters a kind of state of emergency,\" says Martijn Redegeld, sports dietitian at Team Visma | Lease a Bike.",
    },
    {
      text: "FoodCoach helps you prevent hunger pangs by ensuring that you fill your carbohydrate stores (in your liver and muscles) to the maximum with your meals before a workout. The Athlete's FoodCoach also advises what carbohydrates you should consume during your training, so that you do not run out of carbohydrates on your bike.",
    },
    {
      text: "Are you going to train intensively for a maximum of two hours? Then The Athlete's FoodCoach advises you to eat at least 20 grams of carbohydrates per hour. For efforts longer than two hours, the advice is at least 40 grams per hour. Redegeld: 'By constantly replenishing your carbohydrates, you prevent the bottom of your fuel tank from being reached and you experience a bonk.'",
    },
    {
      text: "Van Aert: 'FoodCoach gives me insight into how many carbohydrates I should eat before and during my training. I hold on to that. Nutrition plays an increasingly important role in performing well. For me, training is still number one, but nutrition soon follows. That's where FoodCoach makes the difference.'",
    },
  ],
  planMealsEnabled:
    'Based on your training, we can now determine what, when and how much you should eat. You can plan your meals on this.',
  schedule: {
    explanation: 'Your training has a major impact on what, when and how much you should eat.',
    selectTraining: 'Are you ready to start? Select the date of your first training and add it.',
  },
  searchIngredientsToAddToRecipe:
    'Start searching for ingredients and add them to your own recipe.',
  sleepingHours: 'How many hours do you sleep per night?',
  sport: {
    hello: `Hello! Glad we got to coach you.`,
    helloName: `Hello!`,
    onlyCycling: 'Unfortunately, only cycling is currently supported. Other sports soon followed.',
    sportSelectorInfo:
      'FoodCoach was developed together with the cycling professionals of Team Visma | Lease a Bike. Cycling and running are the first sports supported by FoodCoach. Other sports soon followed.',
  },
  trainingIntensity: 'You can sharpen the above average wattage yourself.',
  weight: 'Your weight also plays an important role.',
  workingHours: 'How many hours do you work per day?',
  workType: {
    intro:
      'In addition, one job requires more energy from your body than the other job. That is why we would like to know what kind of work you do.',
    explanation:
      'Do you spend a lot of time at your desk? Choose physically passive (sitting down). A teacher does mildly active work. A postman has quite active work and as a road worker you have very active work.',
  },
  changeParticipantsWarning:
    'Note: by changing the number of people, the quantities for ingredients change, your portion as an athlete is {portionPercentage} of this meal.',
  nutrientDirection: 'Go in proportion {direction} in {nutrient}',
};
