/**
 * Debounce the calls to a function
 *
 * If you want to run a function on window resize, you'd better debounce it otherwise it
 * would run 100x every second, clogging up your UX. Debounce waits until no more calls
 * were made for X amount of milliseconds before firing your function *once*
 *
 * @param {Function} func The function to debounce
 * @param {Number} wait The amount of milliseconds to wait for other calls to come in
 * @param {Boolean} [immediate] immediate To fire the function on the leading edge of the calls
 */
export default function (func, wait, immediate) {
  let timeout;

  return function (...args) {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
