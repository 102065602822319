export default {
  personalData: 'Personal data',
  length: 'Height',
  sleepingHours: 'Sleeping hours',
  energyCalculation: 'Energy calculation',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Type of work',
  yourSport: 'Sport type',
  gender: 'Gender',
  birthdate: 'Date of birth',
  activityLevel: 'Activity level',
};
