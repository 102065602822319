export default {
  activityLevelOnboarding: 'Hoe actief ben je buiten je sport en werk om?',
  activityLevels: {
    light: 'Gemiddeld actief',
    moderate: 'Erg actief',
    heavy: 'Extreem actief',
  },
  choseYourSport: 'Kies je teamsport',
  dailyEnergyNeed: 'Je dagelijkse energiebehoefte',
  dateOfBirth: 'Geboortedatum',
  hours: 'Uren',
  workingHours: 'Werkuren',
  length: 'Lengte',
  notAvailable: 'Nog niet beschikbaar',
  nutrients: {
    calories: 'Energie',
    carbs: 'Koolhydraten',
    fat: 'Vetten',
    fiber: 'Vezels',
    protein: 'Eiwitten',
  },
  sports: {
    cycling: 'Wielrennen',
    fitness: 'Fitness',
    running: 'Hardlopen',
    teamsport: 'Teamsport',
    hockey: 'Hockey',
    soccer: 'Voetbal',
  },
  whichTeamSport: 'Welke teamsport doe je?',
  workTypes: {
    unemployed: 'Geen werk',
    sedentary: 'Zittend werk',
    light: 'Licht actief werk',
    moderate: 'Gemiddeld actief werk',
    heavy: 'Zwaar actief werk',
  },
  yourWeight: 'Je gewicht',
};
