<script>
  import { defineComponent } from 'vue';
  import PlaylistCard from '@/modules/home/components/playlist-card/PlaylistCard.vue';

  export default defineComponent({
    name: 'PlaylistCardList',
    components: {
      PlaylistCard,
    },
    props: {
      playlists: {
        type: Array,
        default: () => [],
      },
      vertical: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<template>
  <section class="playlist-card-list" :class="{ vertical }">
    <router-link
      v-for="(playlist, index) in playlists"
      :key="index"
      v-slot="{ navigate }"
      :to="{ name: 'playlist-detail', params: { slug: playlist.slug } }"
      custom
    >
      <playlist-card :playlist="playlist" @click.native="navigate" />
    </router-link>

    <div class="spacer">.</div>
  </section>
</template>

<style scoped lang="scss">
  .playlist-card-list {
    display: flex;
    flex-shrink: 0;
    gap: 16px;

    &.vertical {
      flex-direction: column;

      :deep(.playlist-card) {
        width: 100%;
      }
    }
  }

  .spacer {
    content: '.';
    visibility: hidden;
    width: 16px;
  }
</style>
