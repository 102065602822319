export default {
  howProgressBarWorks:
    'FoodCoach calculates your daily energy needs based on your personal characteristics, such as height, age and weight and your planned training sessions. Your energy needs will be higher on a day where you planned a training. So be aware that your energy needs and macros may differ on rest and training days.',
  macronutrients: 'These macronutrients are shown in the app:',
  macros: {
    calories: 'Kilocalorie (kcal)',
    carbs: 'Carbs',
    protein: 'Protein',
    fat: 'Fats',
  },
  nutritionView: {
    coach: 'The view is set by your coach to focus on all or specific macronutrients.',
    core: 'The view is set to core performance to focus on the macronutrients:',
    advanced: 'The view is set to advanced performance to focus on:',
  },

  calculationExplained: {
    title: 'How is this calculated?',
    text: "Based on personal characteristics, such as height, age and weight, The Athlete's FoodCoach calculates your daily energy and macronutrient needs. When you schedule a training, it affects your energy and macronutrient needs, causing them to vary daily.",
  },

  chartExplanation: {
    title: 'How do the bars work?',
    coach:
      'As soon as you plan meals for the day, the bars of the different macronutrients fill up. When you have scheduled enough of a specific macronutrient that day, a green check mark will appear next to the bar, as shown below.',
    core: 'As soon as you plan meals for the day, the bars of the different macronutrients fill up. When you have scheduled enough carbs or protein for that day, a green check mark will appear next to the bar, as shown below.',
    advanced:
      'As soon as you plan meals for the day, the bars of the different macronutrients fill up. When you have scheduled enough calories, carbs, proteins or fats for that day, a green check mark will appear next to the bar, as shown below.',
  },
};
