<script>
  import { computed, defineComponent, onBeforeMount, ref } from 'vue';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumHeading, JumButton, JumIcon } from '@blancofoodcoach/kompas';
  import { useRouter } from 'vue-router/composables';
  import { usePlaylist } from '@/composables/usePlaylist';
  import FocusPlaylistCard from '@/components/focus-playlist-card/FocusPlaylistCard.vue';
  import FocusChallengeCard from '@/components/focus-challenge-card/FocusChallengeCard.vue';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { useMutation } from '@/modules/shared/utils/apollo.util';
  import ADD_MEDAL_MUTATION from '@/graphql/mutations/addMedal.mutation.graphql';
  import MedalPopup from '@/components/medal-popup/MedalPopup.vue';
  import UPDATE_USER_PROFILE_MUTATION from '@/modules/shared/graphql/mutations/updateUserProfile.mutation.graphql';
  import { useChallenge } from '@/composables/useChallenge';
  import CardSkeletonLoader from '@/components/card-skeleton-loader/CardSkeletonLoader.vue';

  export default defineComponent({
    name: 'FocusSection',
    components: {
      CardSkeletonLoader,
      MedalPopup,
      FocusChallengeCard,
      FocusPlaylistCard,
      DefaultSpacer,
      JumHeading,
      JumButton,
      JumIcon,
    },
    props: {
      playlistId: {
        type: String,
        default: null,
      },
      showMedals: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const isLoading = ref(true);
      const { apollo } = useVueProxy();
      const router = useRouter();
      const hasFocus = computed(() => !!props.playlistId);
      const navigateToChooseFocus = () => {
        router.push({ name: 'focus', params: { type: 'challenges' } });
      };
      const focusType = computed(() => {
        if (props?.playlistId?.includes('challenge')) {
          return 'challenge';
        }

        return 'plan';
      });

      const { playlist, addPlaylistProgress, refetchPlaylistProgress } = usePlaylist(
        props.playlistId
      );

      const uuid = props?.playlistId?.includes('challenge') ? props.playlistId.split(',')[1] : null;
      const { challenge, fetch: fetchChallenge } = useChallenge(uuid, true);

      const addMedal = async (medalId, score) => {
        useMutation(ADD_MEDAL_MUTATION, {
          medalId,
          score,
        });
      };

      const mutatePlaylistId = async newValue => {
        await apollo.mutate({
          mutation: UPDATE_USER_PROFILE_MUTATION,
          variables: { updateUserProfileInput: { playlistId: newValue } },
        });
      };

      const medalToReward = computed(() => {
        if (!challenge.value) {
          return null;
        }

        const { uuid: challengeUuid, medal } = challenge.value;
        if (!medal) return null;

        const medalTypes = ['bronze', 'silver', 'gold'];
        const foundType = medalTypes.find(type => medal[type]?.challenge?.uuid === challengeUuid);

        return medal[foundType] || null;
      });

      const isOpenMedalPopup = ref(false);

      onBeforeMount(async () => {
        if (focusType.value === 'challenge') {
          await fetchChallenge();

          if (
            props.showMedals &&
            challenge.value.completed &&
            medalToReward.value !== null &&
            !medalToReward.value.isCompleted
          ) {
            await addMedal(challenge.value.medal.uuid, medalToReward.value.name.toUpperCase());
            await mutatePlaylistId(null);
            isOpenMedalPopup.value = true;
          }
        }

        isLoading.value = false;
      });

      const handleItemSelected = async link => {
        if (link.tag.type === 'challenge') {
          return;
        }

        await addPlaylistProgress(playlist.value.uuid, link.uuid);
        await refetchPlaylistProgress(playlist.value.uuid);
      };

      const closePopup = () => {
        isOpenMedalPopup.value = false;
      };

      const goToChallenge = () => {
        if (focusType.value === 'challenge') {
          router.push({
            name: 'challenge-detail-no-playlist',
            params: { uuid: challenge.value.uuid },
          });
        }

        if (focusType.value === 'plan') {
          router.push({
            name: 'challenge-detail',
            params: {
              slug: playlist.value.uuid,
              challengeSlug: challenge.value.uuid,
            },
          });
        }
      };

      return {
        hasFocus,
        focusType,
        navigateToChooseFocus,
        playlist,
        handleItemSelected,
        challenge,
        fetchChallenge,
        medalToReward,
        isOpenMedalPopup,
        closePopup,
        goToChallenge,
        isLoading,
      };
    },
  });
</script>

<template>
  <section class="focus-section">
    <jum-heading h3 bold>
      {{ $t('title') }}
    </jum-heading>

    <default-spacer s />

    <template v-if="hasFocus">
      <template v-if="!isLoading">
        <section v-if="playlist" class="focused-playlist">
          <focus-playlist-card :playlist="playlist" show-items />
        </section>

        <section v-if="challenge" class="focused-playlist">
          <focus-challenge-card :challenge="challenge" show-items @update="fetchChallenge" />
        </section>
      </template>

      <template v-else>
        <card-skeleton-loader :height="412" />
      </template>
    </template>

    <template v-else>
      <div class="add-focus-container">
        <img :src="require('@/assets/images/focus-background.webp')" alt="Background image" />

        <jum-button secondary @click="navigateToChooseFocus">
          <jum-icon icon="jum-plus" /> {{ $t('addFocus') }}
        </jum-button>
      </div>
    </template>

    <portal v-if="medalToReward" to="overlay">
      <medal-popup
        :medal="medalToReward"
        :is-open="isOpenMedalPopup"
        @proceed="goToChallenge"
        @cancel="closePopup"
      />
    </portal>
  </section>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .add-focus-container {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      float: left;
      object-fit: cover;
      width: 100%;
    }

    .jum-button {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .jum-icon {
      margin-right: $spacing-s;
    }
  }
</style>

<i18n>
{
  "en": {
    "title": "My Focus",
    "addFocus": "Add focus"
  },
  "nl": {
    "title": "Mijn focus",
    "addFocus": "Focus toevoegen"
  }
}
</i18n>
