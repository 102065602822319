import { subYears } from 'date-fns';

const hasValue = v => !(v === null || v === undefined || String(v).length === 0);

const ISO8601 = {
  full: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/,
  local: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
  datetime: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
  unix: /^\d+$/,
};

const parseDate = (value, unix = false) => {
  if (value instanceof Date) return value;

  if (unix) {
    const date = new Date();
    date.setTime(value);

    return date;
  }

  return new Date(value);
};

export const validDate = value => {
  try {
    const regex = ISO8601.date;
    if (!regex.test(value)) {
      return false;
    }

    const splitValue = value.split('-');
    // Parse timezone independent date
    // Subtract 1 from the monthly value as it starts count at `0`
    const parsedDate = new Date(splitValue[0], splitValue[1] - 1, splitValue[2]);

    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();

    const constructedDate = `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;

    return (
      parsedDate instanceof Date &&
      !Number.isNaN(parsedDate) &&
      // this handles cases like 2000-11-31, which is valid, but gets translated to 2000-12-01
      // and is highly likely not what the user intended
      value === constructedDate
    );
  } catch (err) {
    return false;
  }
};

export const rangeDate =
  (minVal, maxVal, formatVal = 'date') =>
  value => {
    if (!hasValue(value)) {
      return null;
    }

    const regex = ISO8601[formatVal];
    const args = JSON.stringify({ minVal, maxVal, formatVal, value });
    const error = `rangeDate(${args})`;

    if (!regex.test(value)) {
      return error;
    }

    const date = parseDate(value, formatVal === 'unix');

    const lowThreshold = parseDate(minVal, formatVal === 'unix');
    const highThreshold = parseDate(maxVal, formatVal === 'unix');

    return date > highThreshold || date < lowThreshold;
  };

export const age = value => {
  const sixTeen = subYears(new Date(), 16);
  const ninetyNine = subYears(new Date(), 99);

  return !rangeDate(ninetyNine, sixTeen)(value);
};

export const includesSpecialSymbols = value => value.search(/\W/) !== -1;

export const minimumLength = (value, { minLengthValue = 8 }) => value.length >= minLengthValue;

export const includesOneUppercaseChar = value => value.search(/[A-Z]/) !== -1;

export const includesOneLowercaseChar = value => value.search(/[a-z]/) !== -1;

export const includesOneNumber = value => value.search(/\d/) !== -1;
