// Pages
const AdditionalInfoPage = () =>
  import('@/modules/onboarding/pages/additional-info/AdditionalInfoPage');

export const registerRoutes = router => {
  const routes = [
    {
      path: '/onboarding/additional-info',
      name: 'onboarding-additional-info',
      component: AdditionalInfoPage,
      meta: {
        noPayWall: true,
      },
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
