import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';

export const userProfileMixin = {
  apollo: {
    userProfile: {
      query: GET_USER_PROFILE_QUERY,
      update: ({ getUserProfile: { user } }) => user,
    },
  },
  computed: {
    customerId: ({ userProfile }) => userProfile.customerId,
  },
};
