const TermsConditionsPage = () => import('../pages/termsConditions/TermsConditionsPage');

export const registerRoutes = router => {
  const routes = [
    {
      path: '/pro-terms-conditions/:status',
      name: 'pro-terms-conditions',
      component: TermsConditionsPage,
      meta: {
        noPayWall: true,
      },
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
