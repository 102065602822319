export const MODALS = {
  CHANGELOG: 'CHANGELOG',
  ENERGY_INTRODUCTION: 'ENERGY_INTRODUCTION',
  MEAL_INTRODUCTION: 'MEAL_INTRODUCTION',
  PYOR_INTRODUCTION: 'PYOR_INTRODUCTION',
  PRO_TERMS_CONDITIONS: 'PRO_TERMS_CONDITIONS',
};

export const MODAL_CONFIG = {
  [MODALS.PYOR_INTRODUCTION]: {
    max: 1,
  },
  [MODALS.MEAL_INTRODUCTION]: {
    max: 3,
  },
  [MODALS.ENERGY_INTRODUCTION]: {
    max: 3,
  },
  [MODALS.PRO_TERMS_CONDITIONS]: {
    max: 1,
  },
  [MODALS.CHANGELOG]: {
    showForEachVersion: true,
  },
};
