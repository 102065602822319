export default {
  activityHoursExplanation: [
    {
      text: "The Athlete's FoodCoach geeft je een professioneel en persoonlijk voedingsplan. Dat doet de app op basis van je persoonlijke factoren, zoals lengte, leeftijd en gewicht én je ingeplande trainingen. Om jouw voedingsplan af te stemmen op jouw behoefte is het dus belangrijk om te weten hoeveel uur per week je sport.",
    },
    {
      text: 'Door de relatief hoge vetvrije massa van atleten wordt hun energiebehoefte vrij snel onderschat. Sporters die 10 uur of meer per week sporten vallen binnen deze groep. Zij hebben daarom een andere berekening nodig van het BMR.',
    },
  ],
};
