export default {
  trainingImportanceExplanation: [
    {
      text: 'Classify your training sessions when adding multiple sessions based on their importance:',
    },
    {
      type: 'list',
      items: [
        '<strong>Primary Training:</strong> This is your main training session, the core of your workout routine. It is the most crucial part of your training plan, focusing on your key fitness goals.',
        '<strong>Secondary Training:</strong> These are additional sessions that complement your primary training. They support your main goals and add variety to your routine.',
      ],
    },
    {
      text: 'When only one training session is planned, it will always be classified as the primary training.',
    },
    {
      title: 'Why is it important?',
      text: 'Adapting your nutrition to your training sessions supports training efficiency and recovery. By setting the "training importance" for each session, you can prioritize your workouts. Consequently, the FoodCoach app will automatically optimize your nutrition plan around your primary training to better support your fitness objectives.',
    },
  ],
};
