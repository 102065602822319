import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import APP_OPENS_QUERY from '@/modules/shared/graphql/queries/appOpens.query.graphql';
import { setItem } from '@/modules/shared/services/storage.service';
import { isSameDay, nowISOString } from '@/modules/shared/utils/date.util';

export default async (_, __, { cache }) => {
  const { appOpens } = cache.readQuery({ query: APP_OPENS_QUERY });

  appOpens.daysWithAppOpens = isSameDay(appOpens.lastAppOpenDate)
    ? appOpens.daysWithAppOpens
    : appOpens.daysWithAppOpens + 1;
  appOpens.todayAppOpens = isSameDay(appOpens.lastAppOpenDate) ? appOpens.todayAppOpens + 1 : 1;
  appOpens.lastAppOpenDate = nowISOString();
  appOpens.totalAppOpens += 1;

  cache.writeQuery({
    query: APP_OPENS_QUERY,
    data: { appOpens },
  });

  await setItem(PERSISTENCE_KEYS.APP_OPENS, appOpens);

  return appOpens;
};
