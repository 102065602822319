import createTrainingGuard from '@/modules/planner/training/guards/createTraining/createTraining.guard';
import AddProductToUserRecipe from '@/modules/planner/pages/custom/add-product-to-user-recipe/AddProductToUserRecipe.vue';
import AddProductToUserRecipeDetail from '@/modules/planner/pages/custom/add-ingredients/AddProductToUserRecipeDetail.vue';
import onboardingSportsGuard from '@/modules/onboarding-sports/guards/onboardingSports/onboardingSports.guard';

const AddIngredients = () =>
  import('@/modules/planner/pages/custom/add-ingredients/AddIngredients');
const CustomRecipeDetails = () =>
  import('@/modules/planner/pages/custom/details/CustomRecipeDetails');
const AddIngredientDetails = () =>
  import('@/modules/planner/pages/custom/add-ingredients/AddIngredientDetails');
const EditIngredientDetails = () => import('@/modules/planner/pages/custom/edit/EditIngredient');

const CustomRecipeEdit = () => import('@/modules/planner/pages/custom/edit/CustomRecipeEdit');
const MealDetailPage = () => import('@/modules/planner/pages/detail/MealDetailPage');
const MealFocusPage = () => import('@/modules/planner/pages/focus/MealFocusPage');
const MealOverViewPage = () => import('@/modules/planner/pages/meal-overview/MealOverViewPage');
const TrainingForm = () =>
  import('@/modules/planner/training/pages/training/training-form/TrainingForm');
const ActivityForm = () =>
  import('@/modules/planner/training/pages/training/activity-form/ActivityForm');
const TuneYourMealPage = () => import('@/modules/planner/pages/tune-your-meal/TuneYourMeal.vue');
const SaveMealPage = () => import('@/modules/planner/pages/tune-your-meal/SaveMeal.vue');
const CreateIngredient = () =>
  import('@/modules/planner/pages/custom/create-ingredient/CreateIngredient.vue');
const CreateIngredientStep2 = () =>
  import('@/modules/planner/pages/custom/create-ingredient/CreateIngredientStep2.vue');
const ScannerPage = () => import('@/modules/planner/pages/barcode/ScannerPage.vue');
const CameraDeniedPage = () => import('@/modules/planner/pages/camera-denied/CameraDeniedPage.vue');
const ScannerNoResultPage = () =>
  import('@/modules/planner/pages/scanner-result/ScannerNoResultPage.vue');
const ScannerMultipleResultsPage = () =>
  import('@/modules/planner/pages/scanner-result/ScannerMultipleResultsPage.vue');

export const TAB_ROUTE = {
  path: 'planner/:date',
  name: 'planner',
  component: MealOverViewPage,
};

export const registerRoutes = (router, guards) => {
  const routes = [
    {
      path: '/planner/:date/:moment',
      name: 'planner-tune-your-meal',
      component: TuneYourMealPage,
      beforeEnter: onboardingSportsGuard,
    },
    {
      path: '/planner/:date/:moment/add/:type',
      name: 'planner-meals-user-add-ingredients',
      component: AddIngredients,
      beforeEnter: guards,
      meta: {
        savePosition: true,
      },
    },
    {
      path: '/planner/:date/:moment/add/product/scanner',
      name: 'planner-meals-user-add-product-scanner',
      component: ScannerPage,
      beforeEnter: guards,
    },
    {
      path: '/planner/:date/:moment/add/product/scanner/denied',
      name: 'permission-camera-denied',
      component: CameraDeniedPage,
    },
    {
      path: '/planner/:date/:moment/add/product/scanner/:gtin',
      name: 'planner-meals-user-add-ingredients-scanner-no-result',
      component: ScannerNoResultPage,
      beforeEnter: guards,
    },
    {
      path: '/planner/:date/:moment/add/product/scanner/:gtin/results',
      name: 'planner-meals-user-add-ingredients-scanner-multiple-results',
      component: ScannerMultipleResultsPage,
      beforeEnter: guards,
    },
    {
      path: '/planner/:date/:moment/add/product/:ingredientId',
      name: 'planner-meals-user-add-ingredients-details',
      component: AddIngredientDetails,
      beforeEnter: guards,
    },
    {
      path: '/planner/product/:ingredientId/edit',
      name: 'planner-edit-ingredient',
      component: EditIngredientDetails,
      beforeEnter: guards,
    },
    {
      path: '/planner/tune/:date/:moment/save',
      name: 'planner-save-meal',
      component: SaveMealPage,
    },
    {
      path: '/planner/:date/:moment/recipes/:id',
      name: 'planner-meals-detail',
      component: MealDetailPage,
      beforeEnter: guards,
    },
    {
      path: '/planner/:date/:moment/recipes/:id/focus',
      name: 'planner-meals-focus',
      component: MealFocusPage,
      beforeEnter: guards,
    },

    // User
    {
      path: '/planner/user/:date/:moment/:id?/details',
      name: 'planner-meals-user-details',
      component: CustomRecipeDetails,
      beforeEnter: guards,
    },
    {
      path: '/planner/user/:date/:moment/:id/edit',
      name: 'planner-meals-user-edit',
      component: CustomRecipeEdit,
      beforeEnter: guards,
    },
    {
      path: '/planner/user/:date/:moment/:id/edit/add-product',
      name: 'planner-meals-user-recipe-add-product',
      component: AddProductToUserRecipe,
      beforeEnter: guards,
    },
    {
      path: '/planner/user/:date/:moment/:id/edit/add-product/:ingredientId',
      name: 'planner-user-recipe-add-product-details',
      component: AddProductToUserRecipeDetail,
      beforeEnter: guards,
    },
    {
      path: '/planner/:date/:moment/products/add-own',
      name: 'planner-meals-create-ingredient',
      component: CreateIngredient,
      beforeEnter: guards,
    },
    {
      path: '/planner/user/:date/:moment/create-ingredients/:name/:milliliterBased',
      name: 'planner-meals-create-ingredient-step-2',
      component: CreateIngredientStep2,
      beforeEnter: guards,
    },

    // Training
    {
      path: '/planner/training/:type/create/:initialDate',
      name: 'planner-add-training',
      component: TrainingForm,
      beforeEnter: createTrainingGuard,
    },
    {
      path: '/planner/training/:type/:date/:id/edit',
      name: 'planner-edit-training',
      component: TrainingForm,
      beforeEnter: createTrainingGuard,
    },
    {
      path: '/planner/activity/:type/:date/:id/edit',
      name: 'planner-edit-activity',
      component: ActivityForm,
      beforeEnter: createTrainingGuard,
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
