const TabsPage = () => import('../pages/tabs/TabsPage');

export const registerRoutes = (router, children = []) => {
  const routes = [
    {
      name: 'tabs',
      path: '/',
      component: TabsPage,
      redirect: { name: 'home' },
      children,
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
