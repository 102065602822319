export default {
  myInfo: 'Mijn gegevens',
  mySportSettings: 'Mijn sportinstellingen',
  coach: 'Coach',
  applications: 'Apps & apparaten',
  aboutThisApp: 'Over deze app',
  performanceGoal: 'Prestatiedoel',
  weightGoal: 'Gewichtsdoel',
  feedback: 'Geef feedback',
  myAccount: 'Mijn account',
  privacySettings: 'Privacy instellingen',
  dateOfBirth: 'Geboortedatum',
  language: 'Taal',
  length: 'Lengte',
  weight: 'Gewicht',
  gender: 'Geslacht',
  typeOfWork: 'Type werk',
  workingHours: 'Werkuren',
  baselineActivityLevel: 'Baseline activiteitsniveau',
  sleepingHours: 'Slaapuren',
  sports: 'Sportprestaties verbeteren',
  viewAllFAQs: 'Bekijk alle veelgestelde vragen',
  energyCalculationExplanation: 'Welke methode past het beste bij mij?',
  myMainSport: 'Mijn hoofdsport',
  myFtp: 'Mijn FTP',
  energyInRestCalculation: 'Energie in rust berekening',
  mySubscription: 'Mijn abonnement',
  manageSubscriptionInAppStore: 'Beheer mijn abonnement in de {store}',
  productDatabase: 'Productdatabase',
  nutritionView: 'Voedingsweergave',
  whatGenderShouldIChoose:
    'Ik identificeer me niet met mannelijk of vrouwelijk, <u>wat moet ik kiezen?</u>',
  workTypePopover: 'Welk type werk past bij mij?',
  whichWeightGoal: 'Welk gewichtsdoel moet ik kiezen?',
  whichNutritionView: 'Welke weergave past het beste bij mij?',
  smartWeightLoss: 'Wat is een slim richtpunt voor mij?',
  smartWeightGain: 'Wat is een slim richtpunt voor mij?',
};
