<template>
  <nice-popover :popover="popover">
    <template #body>
      <div class="inner-container">
        <jum-heading h3 bold>
          {{ $t('home.medals.popover.title') }}
        </jum-heading>

        <default-spacer s />

        <p
          v-for="(text, index) in Object.values($t('home.medals.popover.description'))"
          :key="index"
        >
          {{ text }}
        </p>

        <div class="image">
          <img :src="require('@/assets/images/medals.webp')" alt="Image" />
        </div>
      </div>
    </template>
  </nice-popover>
</template>

<script>
  import NicePopover from '@/modules/shared/components/nice-popovers/NicePopover';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'MedalsPopover',
    components: {
      DefaultSpacer,
      NicePopover,
      JumHeading,
    },
    props: {
      popover: {
        type: Object,
        required: true,
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .animation {
    margin: auto;
    padding: 0 $spacing-l $spacing-l;
  }

  .inner-container {
    display: block;
    height: calc(100vh - 175px);
    overflow: auto;
    padding-bottom: $spacing-m;
  }

  .image {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    overflow: hidden;

    img {
      height: auto;
      width: 100%;
    }
  }
</style>
