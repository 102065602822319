<template>
  <div v-jum-spinner="{ show: true }" class="fc-content-spinner"></div>
</template>

<script>
  import { JumSpinner } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcContentSpinner',
    directives: { JumSpinner },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-content-spinner {
    color: $color-primary;
    display: flex;
    justify-content: center;
    padding: $spacing-l 0;
  }
</style>
