export default {
  profile: 'Profile',
  myPreferences: 'Preferences',
  nextEvent: 'Your next',
  myGoal: 'My goal',
  myInfo: 'Personal data',
  privacySettings: 'Privacy settings',
  aboutThisApp: 'About this app',
  language: 'Language',
  performanceGoal: 'What is <strong>your performance goal?</strong>',
  myNutritionPlanMetrics: 'My nutrition plan metrics',
  appSettings: 'App settings',
  myGoals: 'My goals',
  mySubscription: 'Mijn abonnement',
  nutritionView: 'Nutrition view',
  productDatabase: 'Product database',
  bodyMetrics: 'Body metrics',
  baselineActivityLevel: 'Baseline activity level',
  sport: 'Sport',
  myPersonalNutritionPlan: 'My personal nutrition plan',
};
