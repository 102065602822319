export default {
  aboutThisApp: "Over <strong>The Athlete's FoodCoach</strong> app",
  carbsPerHourExplanation: 'Hoeveel koolhydraten heb ik nodig?',
  customRecipeIntroduction: `Voeg nu je <strong>eigen recept</strong> toe in drie stappen`,
  doesNotMatchMealPlan: 'Voldoet niet aan voedingsplan',
  doesNotMatchMealPlanFavorite:
    'Let op: de macronutriëntenverhouding van dit recept past niet binnen je voedingsplan.',
  trainingIntensity: 'Meer weten over je trainingsintensiteit?',
  fitnessExplanation: 'Welke intensiteit moet ik kiezen?',
  ftpValueExplanation: 'Meer weten over je FTP?',
  onActivityPopoverExplanation: 'Nooit meer een hongerklop?',
  recipeSections: {
    numberOfParticipants: 'Aantal personen',
    ingredients: 'Ingrediënten',
    methodOfPreparation: 'Bereidingswijze',
    nutritionalValue: 'Voedingswaarden (per portie)',
    components: 'Componenten',
    pieces: 'Aantal stuks',
  },
  changeParticipantsWarning: 'Je wijzigt het aantal personen',
  trainingImportanceExplanation: 'Wat is de trainingsbelangrijkheid?',
};
