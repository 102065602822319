<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    recover: {
      type: Boolean,
    },
    perform: {
      type: Boolean,
    },
    protect: {
      type: Boolean,
    },
    health: {
      type: Boolean,
    },
    coach: {
      type: Boolean,
    },
    video: {
      type: Boolean,
    },
    challenge: {
      type: Boolean,
    },
    learning: {
      type: Boolean,
    },
    doing: {
      type: Boolean,
    },
    specials: {
      type: Boolean,
    },
    custom: {
      type: String,
      default: '',
    },
    mini: {
      type: Boolean,
      default: false,
    },
  });

  const label = computed(() => {
    if (props.recover) return 'Recover';
    if (props.perform) return 'Perform';
    if (props.protect) return 'Protect';
    if (props.health) return 'Health';
    if (props.coach) return 'Coach';
    if (props.specials) return 'Specials';
    if (props.video) return 'Video';
    if (props.learning) return 'Learning';
    if (props.doing) return 'Doing';
    if (props.challenge) return 'Challenge';
    return props.custom;
  });
</script>

<template>
  <div
    class="fc-tag label"
    :class="{
      recover,
      perform,
      protect,
      health,
      coach,
      video,
      specials,
      challenge,
      learning,
      doing,
      mini,
    }"
  >
    {{ label }}
  </div>
</template>

<style scoped lang="postcss">
  .label {
    border: 2px solid #000;
    border-radius: 4px;
    display: inline-block;
    font-weight: 600;
    line-height: 22px;
    padding: 4px 8px;
    text-transform: capitalize;

    &.recover {
      background-color: #ffb736;
      border-color: #ffb736;
    }

    &.perform {
      background-color: #f94d3f;
      border-color: #f94d3f;
      color: #fff;
    }

    &.health,
    &.protect {
      background-color: #0d8933;
      border-color: #0d8933;
      color: #fff;
    }

    &.coach {
      background-color: #908ac0;
      border-color: #908ac0;
      color: #fff;
    }

    &.video {
      background-color: #11aeed;
      border-color: #11aeed;
      color: #fff;
    }

    &.challenge {
      background-color: #908ac0;
      border-color: #908ac0;
      color: #fff;
    }

    &.learning {
      background-color: #11aeed;
      border-color: #11aeed;
      color: #fff;
    }

    &.doing {
      background-color: #908ac0;
      border-color: #908ac0;
      color: #fff;
    }

    &.mini {
      font-size: 0.625rem;
      line-height: 17px;
      padding: 0 6px;
    }

    &.specials {
      background-color: #11aeed;
      border-color: #11aeed;
      color: #fff;
    }
  }
</style>
