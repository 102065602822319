export default {
  addOwnIngredient: {
    title: 'Add ingredient',
    message:
      'Unfortunately, adding your own ingredients does not yet work. We are working on getting this in the app as soon as possible.',
    confirmText: 'Okay',
  },
  changeInFtp: {
    title: 'Change FTP',
    message: `Due to your change, the wattage of already planned training sessions may be inaccurate, adjust this if necessary`,
    confirmText: "That's fine",
    cancelText: 'Cancel',
  },
  changeInProfile: {
    title: 'Change profile',
    message: 'When one of the profile data changes, the daily energy requirement changes.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  confirmAccountReset: {
    title: 'Delete my account',
    message: 'What a pity that you want to delete your FoodCoach account.',
    confirmText: 'Delete',
    cancelText: 'Cancel',
  },
  consentToPersonalDataSharing: {
    title: 'My personal nutrition plan',
    message:
      'To make your personal nutrition plan, we need to calculate your energy needs. To do so we need some of your body metrics and baseline activity level.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  createTraining: {
    title: 'Yes, add my training',
    message: 'By adding this training your energy requirement for the day changes. ',
  },
  deleteUserRecipe: {
    title: 'Delete this recipe',
    message: 'Deleting this recipe will remove it from all future scheduled moments.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  deleteUserProduct: {
    title: 'Are you sure you want to delete this product?',
    message:
      'It will disappear from the list of products, but it will remain in all meals and schedules where it has been added.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  userProductAlreadyDeleted: {
    title: 'You have deleted this product',
    message:
      'So you can no longer adjust it. The product will remain in all meals and schedules where it has been added.',
    confirmText: 'Confirm',
  },
  energyIntroduction: {
    title: 'Energy needs',
    message:
      'When you schedule a workout, your energy needs change. This affects the amount of carbohydrates, proteins and fats.',
    confirmText: "That's clear",
  },
  mealDetailIntroduction: {
    title: 'Customized meals',
    message: {
      default:
        'We customize all your meals. This way you always get the right amount of carbohydrates, proteins and fats per meal.',
      userRecipe:
        'We will scale the ingredients based on the macronutrient ratio of your recipe, so that you get the correct number of kilocalories.',
    },
    confirmText: "That's clear",
  },
  moveTraining: {
    title: 'Change training',
    message:
      'Door je wijziging verandert je energiebehoefte en worden eventueel geplande maaltijden voor deze dag(en) verwijderd.',
  },
  removeTraining: {
    title: 'Training verwijderen',
    message:
      'Your change will change your energy needs and any planned meals for these day(s) will be removed.',
  },
  activityAlreadyPaired: {
    title: 'Are you sure you want to pair this activity?',
    message:
      'A different activity is already paired to this training. Pairing this activity will unpair the currently paired activity.',
    cancelText: 'No, cancel',
    confirmText: 'Yes, pair activity',
  },
  trainingMoment: {
    mealsRemoved: ' | {n} planned meal will be removed. | {n} planned meals will be removed.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  pairingNotPossible: {
    title: 'Pairing is not possible',
    message:
      "You've already paired an activity to your training session(s). To make changes, unpair one of the activities first.",
    confirmText: 'Okay, close',
  },
  updateTraining: {
    title: 'Change training',
    message:
      'By changing this training, your energy needs for the day will be recalculated. It is possible that the meals you have already planned no longer fit within your plan.',
  },
  ingredientNutrients: {
    title: '{ingredientName} (per {grams})',
  },
  missingRecipes: {
    message:
      'We were unable to surprise you at one or more meal times because there are no suitable recipes.',
    confirmText: 'Okay',
  },
  productDatabaseOnboarding: {
    title: 'Select your product database',
    message:
      "If you want to make food logging more specific by adding your favorite products, select the database of the country you're currently in. ",
  },
  weightGoalConflict: {
    title: 'Change your weight goal',
    message:
      'You just planned a {type} day. We advise you to put your weight goal to maintain weight on carb loading and race days to get the most out of your nutrition strategy.',
    confirm: 'Oke, let’s change it',
    cancel: 'No thanks',
    types: {
      race: 'race',
      carbLoading: 'carb loading',
    },
  },
};
