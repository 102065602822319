import { onError } from 'apollo-link-error';

export const serviceUnavailableLink = () =>
  onError(({ networkError }) => {
    if (
      (networkError && networkError?.statusCode === 503) ||
      (networkError && networkError?.statusCode === 504)
    ) {
      if (!window.location.href.includes('down')) {
        window.location.assign('/down');
      }
    }
  });
