import messages from './messages/nl';
import messagesEn from './messages/en';
import mixins from './mixins';
import { registerRoutes, TAB_ROUTE } from './routes/planner.routes';

// Register module
export const plannerModule = ({ router }, coreModule, sharedModule, i18nModule) => ({
  mixins: {
    ...mixins,
    ...sharedModule.mixins,
    ...coreModule.mixins,
  },
  utils: {
    ...coreModule.utils,
  },
  install() {
    this.tabRoute = { ...TAB_ROUTE };

    i18nModule.setTranslations({ planner: { ...messages } }, 'nl');
    i18nModule.setTranslations({ planner: { ...messagesEn } }, 'en');

    registerRoutes(router);
  },
});
