<script>
  import { computed, defineComponent, onActivated } from 'vue';
  import { JumHeading, JumCollapsible, JumButton } from '@blancofoodcoach/kompas';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import FcCheckList from '@/modules/shared/components/check-list/CheckList.vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';
  import ExtraSupport from '@/modules/home/components/extra-support/ExtraSupport.vue';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { useUserProfile } from '@/modules/profile/composables/useUserProfile';
  import UPDATE_USER_PROFILE_MUTATION from '@/modules/shared/graphql/mutations/updateUserProfile.mutation.graphql';
  import { usePlaylist } from '@/composables/usePlaylist';
  import { useI18n } from '@/modules/core/composables/useI18n';

  export default defineComponent({
    name: 'PlaylistDetail',
    components: {
      JumButton,
      ExtraSupport,
      LinkCardList,
      FcCheckList,
      DefaultSpacer,
      JumHeading,
      JumCollapsible,
    },
    setup() {
      const { apollo, modaly } = useVueProxy();
      const { t } = useI18n();
      const { open } = useInAppBrowser();
      const router = useRouter();
      const route = useRoute();
      const { user, refetch } = useUserProfile();
      const { slug } = route.params;
      const { preview } = route.query;
      const {
        playlist,
        addPlaylistProgress,
        playlistProgress,
        resetPlaylistProgress,
        refetchPlaylistProgress,
      } = usePlaylist(slug, !!preview);
      const hasLinks = computed(() => playlist.value?.links && playlist.value.links.length > 0);
      const hasPhases = computed(() => playlist.value?.phases && playlist.value.phases.length > 0);
      const showExtraSupport = computed(() => playlist.value?.extraSupportCta ?? false);
      const isFocus = computed(() => user.value?.playlistId === playlist.value?.uuid);
      const hasProgress = computed(() => playlistProgress.value?.items.length > 0);

      const mutatePlaylistId = async newValue => {
        await apollo.mutate({
          mutation: UPDATE_USER_PROFILE_MUTATION,
          variables: { updateUserProfileInput: { playlistId: newValue } },
        });
      };

      const focus = async () => {
        if (hasProgress.value) {
          modaly({
            title: t('modal.title'),
            message: t('modal.message'),
            confirmText: t('modal.continueProgressButton'),
            cancelText: t('modal.startOverButton'),
            onConfirm: async modal => {
              // Progress
              await mutatePlaylistId(playlist.value.uuid);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
            onCancel: async modal => {
              // Start over
              await resetPlaylistProgress(playlist.value.uuid);
              await mutatePlaylistId(playlist.value.uuid);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
          });
        } else {
          await mutatePlaylistId(playlist.value.uuid);
          await router.push({
            name: 'home',
          });
        }
      };

      const unfocus = async () => {
        await mutatePlaylistId(null);
        await router.push({
          name: 'home',
        });
      };

      const handleItemSelected = async link => {
        if (link.tag.type === 'challenge') {
          return;
        }

        await addPlaylistProgress(playlist.value.uuid, link.uuid);
        await refetchPlaylistProgress(playlist.value.uuid);
      };

      onActivated(async () => {
        refetch();
        await refetchPlaylistProgress();
      });

      const checkIfAllowed = () => {
        if (!isFocus.value) {
          modaly({
            title: t('modal2.title'),
            confirmText: t('modal2.confirmButton'),
            cancelText: t('modal2.cancelButton'),
            onConfirm: async modal => {
              await mutatePlaylistId(playlist.value.uuid);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
            onCancel: async modal => {
              modal.close();
            },
          });
        }
      };

      return {
        open,
        playlist,
        hasPhases,
        hasLinks,
        showExtraSupport,
        focus,
        unfocus,
        user,
        isFocus,
        handleItemSelected,
        checkIfAllowed,
      };
    },
  });
</script>

<template>
  <fc-page v-if="playlist">
    <template #top>
      <fc-nav-bar
        :back-button="{
          name: 'home',
        }"
      >
        {{ $t('title') }}
      </fc-nav-bar>
    </template>

    <fc-page-content>
      <div class="image">
        <img :src="playlist.img" alt="Image for playlist" />
      </div>

      <default-spacer s />

      <div class="h-group">
        <jum-heading h3 bold class="title">
          {{ playlist.title }}
        </jum-heading>

        <jum-heading v-if="playlist.subtitle" h6 class="subtitle">
          {{ playlist.subtitle }}
        </jum-heading>

        <jum-heading h6 class="body">
          {{ playlist.body }}
        </jum-heading>
      </div>

      <default-spacer />

      <template v-if="playlist.benefits.length > 0">
        <jum-heading h4 bold> Benefits </jum-heading>

        <default-spacer s />

        <fc-check-list icon="jum-medal" :list="playlist.benefits" :icon-size="20" />

        <default-spacer />
      </template>

      <template v-if="hasPhases">
        <jum-collapsible
          v-for="(phase, index) in playlist.phases"
          :key="`phase-${index}`"
          :open="phase.isOpen"
        >
          <template #title>
            <jum-heading h5 bold class="phase-title">
              {{ phase.title }}
            </jum-heading>

            <jum-heading h5 class="phase-subtitle">
              {{ phase.subtitle }}
            </jum-heading>
          </template>

          <link-card-list
            class="clickable"
            :links="phase.links"
            :is-disabled="!isFocus"
            @item-selected="handleItemSelected"
            @click.native="checkIfAllowed"
          />

          <default-spacer s />
        </jum-collapsible>

        <default-spacer />
      </template>

      <extra-support v-if="playlist.showExtraSupport" />
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <jum-button v-if="!isFocus" block @click="focus">
          {{ $t('focusButton') }}
        </jum-button>

        <jum-button v-else block secondary @click="unfocus">
          {{ $t('unfocusButton') }}
        </jum-button>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<style scoped lang="scss">
  .image {
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .subtitle {
    color: #757575;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 0.75rem * 1.3;
  }

  .body {
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 1rem * 1.3 !important;
  }

  .h-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    z-index: 1;

    > * {
      margin: 0;
    }
  }

  .clickable {
    pointer-events: all;
  }

  .note {
    background-color: #fff8eb;
    padding: 16px;

    p {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 0.875rem * 1.3;
    }
  }
</style>

<i18n>
{
  "en": {
    "title": "My focus",
    "focusButton": "Start focus plan",
    "unfocusButton": "Unfocus",
    "modal": {
      "title": "We’ve noted progress you’ve made earlier",
      "message": "Would you like to continue were you’ve been or start this focus plan all over again?",
      "continueProgressButton": "Continue progress",
      "startOverButton": "Start over"
    },
    "modal2": {
      "title": "Start this focus plan to check the details of this challenge",
      "confirmButton": "Start focus plan",
      "cancelButton": "Cancel"
    }
  },
  "nl": {
    "title": "Mijn focus",
    "focusButton": "Start focus plan",
    "unfocusButton": "Switch focus plan",
    "modal": {
      "title": "We hebben gemerkt dat je eerder al bezig was met dit focus plan",
      "message": "Wil je verder gaan waar je gebleven was of wil je opnieuw beginnen?",
      "continueProgressButton": "Doorgaan",
      "startOverButton": "Opnieuw beginnen"
    },
    "modal2": {
      "title": "Start dit focus plan om de details van deze uitdaging te bekijken",
      "confirmButton": "Start focus plan",
      "cancelButton": "Annuleren"
    }
  }
}
</i18n>
