import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import { getItem } from '@/modules/shared/services/storage.service';

export default async (_, { bubbleName }) => {
  const defaultBubble = {
    bubbleShown: false,
    __typename: 'Bubble',
    _id: bubbleName,
    shouldBeShown: true,
  };
  const storedBubbles = (await getItem(PERSISTENCE_KEYS.BUBBLES)) || {};

  const currentBubble = storedBubbles[bubbleName];
  return { ...defaultBubble, ...currentBubble };
};
