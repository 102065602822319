export default {
  aboutThisApp: 'About this app',
  activityLevel: 'Activity level',
  activityHoursExplanation: 'Why do I have to enter my sports hours?',
  carbsPerHour: 'How many carbs do I need?',
  dateOfBirth: 'Date of birth',
  developer: 'Features',
  editRecipe: 'Change this recipe',
  exactMeasurement: 'Can I round up these ingredients?',
  fitnessOverview: 'Which intensity should I choose?',
  ftpValueExplanation: 'Learn more about FTP',
  gender: 'Gender',
  length: 'Height',
  myAccount: 'My account',
  myFtpValue: 'My FTP value',
  myGoal: 'My goal',
  myInfo: 'My data',
  mySport: 'My sport',
  myEnergyCalculation: 'My energy calculation',
  nutrientsInfo: 'How are these nutritional values ​ determined?',
  onActivityExplanation: 'Never hungry again?',
  progressBarExplanation: 'How do these bars work?',
  save: 'Save',
  sleepingHours: 'Sleeping hours',
  sportSettings: 'My sport settings',
  toHappyMany: 'Change my goal',
  trainingIntensity: 'Do you want to know more about training intensity?',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Type of work',
};
