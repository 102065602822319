const FtpForm = () => import('@/modules/planner/training/pages/training/ftp/FtpForm');

export const registerRoutes = (router, defaultGuards) => {
  const routes = [
    // Functional Threshold Power
    {
      path: '/ftp',
      name: 'training-ftp',
      component: FtpForm,
      beforeEnter: defaultGuards,
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
