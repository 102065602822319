import { getUserProfile } from '@/modules/shared/services/profile/profile.service';
import { hasValidSubscription } from '@/modules/core/utils/user';

const sportsTermsGuard = async (to, from, next) => {
  const redirectToOnboarding = () => next({ name: 'onboarding-personal-data' });

  try {
    const user = await getUserProfile();

    if (!hasValidSubscription(user) || user.acceptsSportsTermsAndConditions) {
      next();
    } else {
      redirectToOnboarding();
    }
  } catch {
    redirectToOnboarding();
  }
};

export default sportsTermsGuard;
