export default {
  showAllRecipes: 'Bekijk alle recepten',
  activate: 'Aanzetten',
  specificWishes: 'Ik heb specifieke wensen',
  backToHome: 'Terug naar Home',
  searchForProduct: 'Zoek een product',
  connectToYourCoach: 'Connect met een FoodCoach',
  shareFeedback: 'Geef Feedback',
  comingSoon: 'binnenkort beschikbaar',
  discoverPremium: 'Ontdek FoodCoach Premium',
  getWinterTrial: 'Krijg gratis 14-daagse proefperiode',
};
