import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import { getItem } from '@/modules/shared/services/storage.service';

export default async () => {
  const defaultAppOpens = {
    __typename: 'AppOpens',
    lastAppOpenDate: '',
    daysWithAppOpens: 0,
    todayAppOpens: 0,
    totalAppOpens: 0,
  };

  const storedAppOpens = await getItem(PERSISTENCE_KEYS.APP_OPENS);

  return storedAppOpens ? { ...defaultAppOpens, ...storedAppOpens } : defaultAppOpens;
};
