import { getCurrentInstance } from 'vue';

export const useVueProxy = () => {
  const {
    $apollo: apollo,
    $modaly: modaly,
    $popovery: popovery,
    $tipmenu: tipmenu,
    $i18n: i18n,
    $notificationy: notificationy,
    $inAppBrowser: inAppBrowser,
  } = getCurrentInstance().proxy;

  const apolloStrapi = apollo?.provider?.clients[0] ?? null;

  return {
    apollo,
    apolloStrapi,
    modaly,
    tipmenu,
    popovery,
    i18n,
    notificationy,
    inAppBrowser,
  };
};
