// eslint-disable-next-line max-classes-per-file
import { createChallengeFromStrapiResponse } from '@/util/gamification/challenge';
import { configService } from '@/config.service';

const { strapiImageUrl } = configService;

export class MedalLevel {
  constructor(name, challenge, imageUrl, isCompleted = false) {
    this.name = name;
    this.challenge = challenge;
    this.imageUrl = imageUrl;
    this.isCompleted = isCompleted;
  }
}

export class Medal {
  constructor(uuid, title, grey, bronze, silver, gold, tag) {
    this.uuid = uuid;
    this.title = title;
    this.grey = grey;
    this.bronze = bronze;
    this.silver = silver;
    this.gold = gold;
    this.tag = tag;
  }

  get currentLevel() {
    if (this.gold?.isCompleted) {
      return this.gold;
    }

    if (this.silver?.isCompleted) {
      return this.silver;
    }

    if (this.bronze?.isCompleted) {
      return this.bronze;
    }

    return this.grey;
  }

  get nextLevel() {
    if (this.currentLevel.name === 'grey') {
      return this.bronze;
    }

    if (this.currentLevel.name === 'bronze') {
      return this.silver;
    }

    if (this.currentLevel.name === 'silver') {
      return this.gold;
    }

    return null;
  }

  static createFromStrapiResponse(item, progress) {
    return new Medal(
      item.attributes.uuid,
      item.attributes.title,
      new MedalLevel(
        'grey',
        null,
        `${strapiImageUrl}${item.attributes.greyImage.data.attributes.url}`,
        true
      ),
      new MedalLevel(
        'bronze',
        createChallengeFromStrapiResponse(item.attributes.bronzeChallenge.data),
        `${strapiImageUrl}${item.attributes.bronzeImage.data.attributes.url}`,
        progress.some(medal => item.attributes.uuid === medal.id && medal.score === 'BRONZE')
      ),
      new MedalLevel(
        'silver',
        createChallengeFromStrapiResponse(item.attributes.silverChallenge.data),
        `${strapiImageUrl}${item.attributes.silverImage.data.attributes.url}`,
        progress.some(medal => item.attributes.uuid === medal.id && medal.score === 'SILVER')
      ),
      new MedalLevel(
        'gold',
        createChallengeFromStrapiResponse(item.attributes.goldChallenge.data),
        `${strapiImageUrl}${item.attributes.goldImage.data.attributes.url}`,
        progress.some(medal => item.attributes.uuid === medal.id && medal.score === 'GOLD')
      ),
      item.attributes?.tag?.type
    );
  }
}

export const mapMedalToLink = ({ challenge, isCompleted }) => ({
  title: challenge.title,
  url: challenge
    ? {
        name: 'challenge-detail-no-playlist',
        params: {
          uuid: challenge.uuid,
        },
      }
    : null,
  img: challenge.cardImageUrl,
  isExternal: false,
  tag: challenge.tag
    ? {
        type: challenge.tag,
        text: challenge.tag,
      }
    : null,
  uuid: challenge.uuid,
  completed: isCompleted,
});
