import progressBarExplanation from '@/modules/planner/messages/en/progressBarExplanation.en';
import activityHoursExplanation from './activityHoursExplanation.en';
import buttons from './buttons.en';
import counts from './counts.en';
import fitnessExplanation from './fitnessExplanation.en';
import carbsPerHourExplanation from './carbsPerHourExplanation.en';
import trainingImportanceExplanation from './trainingImportanceExplanation.en';
import headings from './headings.en';
import info from './info.en';
import labels from './labels.en';
import links from './links.en';
import misc from './misc.en';
import modals from './modals.en';
import networkErrors from './networkErrors.en';
import notifications from './notifications.en';
import placeholders from './placeholders.en';
import titles from './titles.en';
import trainingTypes from './trainingTypes.en';

export default {
  activityHoursExplanation,
  fitnessExplanation,
  carbsPerHourExplanation,
  trainingImportanceExplanation,
  progressBarExplanation,
  placeholders,
  trainingTypes,
  buttons,
  counts,
  headings,
  info,
  labels,
  links,
  modals,
  networkErrors,
  notifications,
  titles,
  misc,
};
