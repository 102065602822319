<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<template>
  <fc-page class="login-page">
    <template #top>
      <fc-nav-bar :back-button="false" />
    </template>

    <fc-page-content>
      <jum-heading h3 class="margin-bottom-m">
        <span v-html="$t('auth.headings.passwordReset')"></span>
      </jum-heading>

      <div class="body">
        <p v-for="(p, index) in $t('auth.body.passwordConfirmation')" :key="index">
          <span v-html="p"></span>
        </p>
      </div>
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <router-link v-slot="{ navigate }" :to="{ name: 'auth-login' }" custom>
          <jum-button secondary block @click="navigate">
            {{ $t('auth.buttons.backToLogin') }}
          </jum-button>
        </router-link>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<script>
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';
  import { validationMixin } from '@/modules/shared/mixins/validation.mixin';
  import { setAccessoryBarVisible } from '@/modules/shared/mixins/setAccessoryBarVisible.mixin';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ResetPasswordConfirmationPage',
    components: {
      JumButton,
      JumHeading,
    },
    mixins: [setAccessoryBarVisible, validationMixin],
    layout: 'login',
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .body {
    p {
      white-space: pre-line;
    }
  }
</style>
