export default {
  title: "Algemene Voorwaarden The Athlete's FoodCoach",
  list: {
    type: 'ol',
    items: [
      {
        text: '{0}',
        references: [{ type: 'bold', text: "The Athlete's FoodCoach" }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Deze algemene voorwaarden (hierna aangeduid als “{0}”) zijn samen met het Privacy Statement van toepassing op de The Athlete's FoodCoach (hierna aangeduid als “{1}”).",
              references: [
                { type: 'bold', text: 'Algemene  Voorwaarden' },
                { type: 'bold', text: "The Athlete's FoodCoach" },
              ],
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Deelname' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Bij deelname aan The Athlete's FoodCoach ga je akkoord met deze Algemene Voorwaarden.",
            },
            {
              text: "Om te kunnen genieten van alle voordelen van The Athlete's FoodCoach, zoals het ontvangen van een persoonlijk voedingsplan, dien je je in te schrijven voor The Athlete's FoodCoach. Dit kan door de The Athlete's FoodCoach app (hierna aangeduid als “{0}”) te downloaden via de Apple Store of via Google Play. De App valt onder de definitie van “{1}”.",
              references: [
                { type: 'bold', text: 'App' },
                { type: 'bold', text: 'Applicaties' },
              ],
            },
            {
              text: "Om mee te kunnen doen aan The Athlete's FoodCoach moet je een natuurlijk persoon van 18 jaar of ouder zijn. Door deelname aan The Athlete's FoodCoach verklaar je daaraan te hebben voldaan.",
            },
            {
              text: 'Jij staat er voor in dat alle in jouw profiel ingevulde gegevens volledig, juist en actueel zijn.',
            },
            {
              text: "De ingevulde persoonsgegevens worden opgeslagen in de Jumbo database. De persoonlijke informatie die je aan ons doorgeeft, wordt verwerkt in overeenstemming met de geldende privacy wetgeving. Je kunt hier meer over lezen in het The Athlete's FoodCoach Privacy & Cookie Statement.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: "Gebruik The Athlete's FoodCoach" }],
        list: {
          type: 'ul',
          items: [
            {
              text: "In The Athlete's FoodCoach ga je trainingen voor de door jou beoefende sport aanmaken. Met behulp van een trainingsschema en jouw gezondheidsgegevens wordt de energie berekend die je gaat verbruiken. Vervolgens wordt jouw energiebehoefte berekend en een voedingsschema opgesteld. Dit voedingsschema is verdeeld over de dag (ontbijt, lunch, diner, snacks en eten tijdens het sporten) we vertalen de benodigde ingredienten naar bij Jumbo verkrijgbare producten.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Controle en misbruik' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Jumbo behoudt zich het recht voor om het gebruik door deelnemers van The Athlete's FoodCoach te controleren op naleving van de voorwaarden.",
            },
            {
              text: "Jumbo mag de uitgifte van het The Athlete's FoodCoach account weigeren, terugtrekken of annuleren, in welke vorm dan ook en (op elk gewenst moment) een deelnemer verwijderen van The Athlete's FoodCoach indien er gegronde redenen zijn te geloven dat:",
              list: {
                type: 'ol',
                items: [
                  {
                    text: "misbruik gemaakt wordt of gepoogd wordt misbruik te maken van The Athlete's FoodCoach;",
                  },
                  {
                    text: 'elke overtreding of poging to schending van deze Algemene Voorwaarden;',
                  },
                  {
                    text: "elk gedrag met betrekking tot de The Athlete's FoodCoach pas dat diefstal, wangedrag, aanstootgevend gedrag of het verstrekken van onjuiste of misleidende informatie betreft.",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Aansprakelijkheid' }],
        list: {
          type: 'ol',
          items: [
            {
              text: "Ofschoon Jumbo met betrekking tot The Athlete's FoodCoach en de App de uiterste zorg nastreeft, kan zij niet garanderen dat deze geen gebreken of onvolkomenheden zullen bevatten.",
            },
            {
              text: "Jumbo is op geen enkele wijze aansprakelijk voor ongeautoriseerd gebruik door derden van je The Athlete's FoodCoach account",
            },
            {
              text: "Jumbo is niet aansprakelijk voor schade van welke aard dan ook, die voortvloeit uit of samenhangt met deelname aan The Athlete's FoodCoach, waaronder maar niet uitsluitend begrepen ziekte of een negatieve invloed op de (sport)prestatie van de deelnemer die het gevolg zou zijn van gebruikmaking van de The Athlete's FoodCoach. Deelname aan The Athlete's FoodCoach is dan ook op eigen risico.",
            },
            {
              text: "Je kunt ervoor kiezen om via de The Athlete's FoodCoach te worden doorverwezen naar een diëtist en/of voedingsdeskundige. Nadat het contact met de diëtist en/of voedingsdeskundige is gelegd, valt het verdere traject met de diëtist en/of voedingsdeskundige buiten de scope van de The Athlete's FoodCoach. Jumbo is niet aansprakelijk voor schade van welke aard dan ook, die voortvloeit uit of samenhangt met het traject met de diëtist en/of voedingsdeskundige.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Intellectuele eigendom' }],
        list: {
          type: 'ol',
          items: [
            {
              text: "Jumbo behoudt zich alle rechten voor die niet expliciet aan jou zijn verleend. Door jouw gebruik van de Applicaties komt geen overdracht tot stand van enige intellectuele eigendomsrechten van Jumbo aan jou. Je verkrijgt slechts een niet-overdraagbare, niet-exclusieve, niet-sublicentieerbaar, beperkte licentie om de The Athlete's FoodCoach te gebruiken voor de doeleinden aangegeven op de Applicaties.",
            },
            {
              text: 'Je erkent dat alle (intellectuele eigendoms)rechten ten aanzien van de Applicaties, waaronder begrepen maar niet beperkt tot de intellectuele eigendomsrechten ten aanzien van de teksten, foto’s, filmpjes, recepten en software, uitsluitend berusten bij Jumbo of haar licentiegevers.',
            },
            {
              text: 'De  (onderdelen  van  de)  Applicaties  mogen  op  geen  enkele  wijze  en  in  geen  enkele vorm (geheel of gedeeltelijk) verveelvoudigd of openbaar gemaakt worden tenzij in deze  Algemene  Voorwaarden  anders  is  bepaald  en/of  voor  zover  dwingend  recht anders bepaalt. Het is verder niet toegestaan:',
              list: {
                type: 'ol',
                items: [
                  {
                    text: 'een substantieel gedeelte van de inhoud van de Applicaties op te vragen en her te gebruiken en/of niet-substantiële delen van de inhoud van de Applicaties herhaald en systematisch op te vragen en her te gebruiken; en/of',
                  },
                  {
                    text: 'enige soft- en/of hardware matige tools en/of oplossingen (in eigen beheer of beschikbaar gesteld door derden) te gebruiken, voor zover deze gericht zijn op het overnemen van enige via de Applicaties toegankelijk gemaakte informatie, dan wel om de Applicaties via robots of op enigerlei andere wijze te spideren, scrapen, doorzoeken of op andere oneigenlijke wijze te gebruiken.',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Wijziging en beëindiging deelnemer' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Je kunt je deelname aan The Athlete's FoodCoach op elk moment beëindigen door je FoodCoach profiel in de app te verwijderen.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Wijzigingen en beëndiging Jumbo' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Jumbo heeft te allen tijde het recht om The Athlete's FoodCoach te wijzigen of te beëindigen en/of deze Algemene Voorwaarden en het Privacy & Cookie Statement te wijzigen, zonder op enige wijze tot schadevergoeding en/of enige andere compensatie gehouden te zijn jegens de deelnemers. Een significante wijziging van de Algemene Voorwaarden en/of het Privacy Statement wordt binnen de Applicaties onder jouw aandacht gebracht.",
            },
            {
              text: "Jumbo zal je uiterlijk één (1) maand van te voren informeren over een significante wijziging of beëindiging van The Athlete's FoodCoach via het opgegeven e-mailadres.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Overige bepalingen' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Op deze Algemene Voorwaarden en op The Athlete's FoodCoach is Nederlands recht van toepassing. Alle geschillen in verband hiermee zullen worden voorgelegd aan de bevoegde rechter in Nederland.",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Contact' }],
        list: {
          type: 'ul',
          items: [
            {
              text: "Als je vragen of opmerkingen hebt over The Athlete's FoodCoach, neem dan contact met ons op: Jumbo Supermarkten B.V., Rijksweg 15, 5462 CE Veghel, T: 0800 - 0220 161/ {0}",
              references: [
                {
                  type: 'link',
                  url: 'https://www.jumbo.com/klantenservice',
                  text: 'www.jumbo.com/klantenservice',
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
