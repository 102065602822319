<template>
  <ul class="check-list" :class="{ animated }">
    <template v-if="animated">
      <transition-group name="items" appear>
        <li v-for="(item, key) in animatedList" :key="key">
          <jum-icon :icon="icon" :size="iconSize" />

          <slot name="item" :item="item">
            {{ item }}
          </slot>
        </li>
      </transition-group>
    </template>
    <template v-else>
      <li v-for="(item, key) in list" :key="key">
        <jum-icon :icon="icon" :size="iconSize" />

        <slot name="item" :item="item">
          {{ item }}
        </slot>
      </li>
    </template>
  </ul>
</template>

<script>
  import { JumIcon } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'CheckList',
    components: {
      JumIcon,
    },
    props: {
      icon: {
        type: String,
        default: 'fc-check',
      },
      iconSize: {
        type: Number,
        default: 26,
      },
      list: {
        default: () => [],
        type: Array,
      },
      animated: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      animatedList: [],
    }),
    mounted() {
      if (!this.animated) return;

      this.addListItem();

      setInterval(() => {
        if (this.animatedList.length < this.list.length) {
          this.addListItem();
        } else {
          this.$emit('animationDone');
        }
      }, 1400);
    },
    methods: {
      addListItem() {
        this.animatedList.push(this.list[this.animatedList.length]);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .check-list {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;

    &.animated {
      overflow: visible;
    }

    li {
      display: flex;
      font-size: $font-size-small;
      font-weight: 300;
      line-height: $line-height-small;
      margin-bottom: $spacing-s;

      &:last-child {
        margin-bottom: 0;
      }

      .jum-icon:first-child {
        color: #171717;
        flex: 0 0 auto;
        margin-right: 4px;
      }
    }
  }

  .items-enter-active,
  .items-leave-active {
    transition: 1.8s cubic-bezier(0.2, 0.49, 0.28, 1);
    transition-property: opacity, transform;
  }

  .items-enter,
  .items-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }
</style>
