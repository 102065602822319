import { App as CapApp } from '@capacitor/app';
import { Device } from '@capacitor/device';

const androidBackButtonMixin = callback => ({
  data: () => ({
    listener: null,
    destroyed: false,
  }),
  async mounted() {
    const { platform } = await Device.getInfo();

    if (platform === 'android') {
      this.listener = await CapApp.addListener('backButton', () => {
        if (typeof callback === 'function' && !this.destroyed) callback.call(this);
      });
    }
  },
  async destroyed() {
    this.destroyed = true;
    await this.listener?.remove();
    this.listener = null;
  },
});

const androidBackButtonExitMixin = androidBackButtonMixin(() => CapApp.exitApp());

const androidBackButtonRedirectMixin = routeOptions =>
  androidBackButtonMixin(function () {
    this.$router.replace(routeOptions).catch(() => {});
  });

export { androidBackButtonExitMixin, androidBackButtonRedirectMixin, androidBackButtonMixin };
