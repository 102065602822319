import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import authRules from './validators/auth.validator';
import ftpRules from './validators/ftp.validator';
import generalRules from './validators/general.validator';
import eventRules from './validators/event.validator';
import onboardingRules from './validators/onboarding.validator';
import profileRules from './validators/profile.validator';
import trainingRules from './validators/training.validator';

const rules = [
  ...generalRules,
  ...eventRules,
  ...authRules,
  ...onboardingRules,
  ...profileRules,
  ...ftpRules,
  ...trainingRules,
];

const setupValidationRules = {
  install(Vue, { i18n }) {
    rules.forEach(({ name, rule, message }) =>
      extend(name, {
        ...rule,
        message: (_, values) => i18n.t(message, values),
      })
    );
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
  },
};

export default setupValidationRules;
