import { onError } from 'apollo-link-error';

export const unauthorizedErrorLink = () =>
  onError(error => {
    const errorCodes = error?.response?.errors.map(e => e.code);

    if (errorCodes?.includes('401')) {
      window.location = '/login';
    }
  });
