<template>
  <renderless-items type="popovers">
    <template #default="{ activePopover }">
      <transition v-if="activePopover && activePopover.type === 'popover'" name="roll">
        <div
          v-if="activePopover"
          ref="nicePopovers"
          class="nice-popovers"
          @mousedown.self="mouseDown(activePopover)"
        >
          <component
            :is="activePopover.component || 'NicePopover'"
            class="nice-popovers__popover"
            :popover="activePopover"
          />
        </div>
      </transition>
    </template>
  </renderless-items>
</template>

<script>
  import { defineComponent } from 'vue';
  import NicePopover from './NicePopover';

  export default defineComponent({
    name: 'NicePopovers',
    components: { NicePopover },
    data: () => ({
      activePopover: null,
    }),
    created() {
      document.addEventListener('mouseup', this.mouseUp);
    },
    beforeDestroy() {
      document.removeEventListener('mouseup', this.mouseUp);
    },
    methods: {
      mouseDown(activePopover) {
        this.activePopover = activePopover;
      },
      mouseUp($event) {
        if (
          this.activePopover &&
          this.activePopover.dismissible &&
          $event.target === this.$refs.nicePopovers
        ) {
          this.activePopover.close();
        }

        this.activePopover = null;
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-popovers {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-page-popover;

    &__popover {
      bottom: 0;
      flex-grow: 1;
      will-change: bottom, transform;
      z-index: 301;
    }

    &::after {
      background-color: rgba($color-black, 0.5);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 0.3s ease;
      z-index: 20;
    }
  }

  .roll-enter-active,
  .roll-leave-active {
    transition: 0.7s;

    &.nice-popovers {
      &::after {
        background-color: transparent;
      }
    }
  }

  .roll-enter,
  .roll-leave-to {
    transform: translateY(1200px);

    &.nice-popovers {
      &::after {
        background-color: transparent;
      }
    }
  }
</style>
