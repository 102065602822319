<template>
  <div class="fc-page-content">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcPageContent',
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-page-content {
    min-height: 100%;
    padding: $page-padding;
  }
</style>
