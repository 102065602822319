<script>
  import { computed, defineComponent } from 'vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  export default defineComponent({
    name: 'ExtraSupport',
    components: {
      DomPurify,
      JumHeading,
    },
    setup() {
      const { open } = useInAppBrowser();
      const { hasFlag } = useProfile();
      const showAiCoach = computed(() => hasFlag('foodcoach-ai'));
      const img = computed(() => {
        if (showAiCoach.value) {
          // eslint-disable-next-line global-require
          return require('@/assets/images/experts/ai-expert.png');
        }

        // eslint-disable-next-line global-require
        return require('@/assets/images/experts/expert.png');
      });

      return {
        img,
        open,
        showAiCoach,
      };
    },
  });
</script>

<template>
  <div class="extra-support">
    <template v-if="showAiCoach">
      <div class="image-container">
        <img :src="img" alt="Image of a FoodCoach" />
      </div>

      <div class="text-container">
        <jum-heading h5 bold>
          {{ $t('heading') }}
        </jum-heading>

        <p>
          <router-link :to="{ name: 'chat' }">
            <dom-purify :html="$t('bodyAi')" />
          </router-link>
        </p>
      </div>
    </template>
    <template v-else>
      <div class="image-container">
        <img :src="img" alt="Image of a FoodCoach" />
      </div>

      <div class="text-container">
        <jum-heading h5 bold>
          {{ $t('heading') }}
        </jum-heading>

        <p>
          {{ $t('body') }},
          <a
            @click="
              open(
                'https://www.theathletesfoodcoach.com/inspiration/when-should-i-book-a-slot-with-a-certified-foodcoach'
              )
            "
            >{{ $t('moreInfo') }}</a
          >.
        </p>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .extra-support {
    display: flex;
    gap: $spacing-m;

    img {
      max-height: 140px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 16px;
        font-weight: 300;

        a {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
</style>

<i18n>
{
    "en": {
      "heading": "Need extra support?",
      "body": "Connect with a FoodCoach to discuss your thoughts",
      "bodyAi": "<u>Ask your questions</u> to FoodCoach A.I. He’s 24/7 available for you",
      "moreInfo": "More info"
    },
  "nl": {
    "heading": "Extra ondersteuning nodig?",
    "body": "Neem contact op met een FoodCoach om je gedachten te bespreken",
    "bodyAi": "<u>Stel je vragen</u> aan FoodCoach A.I. Hij is 24/7 beschikbaar voor jou",
    "moreInfo": "Meer info"
  }
}
</i18n>
