var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fc-badge",class:{
    'fc-badge--primary': _vm.primary,
    'fc-badge--gray': _vm.gray,
    'fc-badge--orange': _vm.orange,
    'fc-badge--success': _vm.success,
    'fc-badge--blue': _vm.blue,
    'fc-badge--cyan': _vm.cyan,
    inverted: _vm.inverted,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }