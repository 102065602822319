import { NUMBER_OF_GROCERY_LIST_DAYS } from '@/modules/shared/constants/groceries.const';
import { SESSION_KEYS } from '@/modules/shared/constants/storage.const';
import apollo from '@/modules/shared/graphql/apollo.config';
import DAY_PLANS_QUERY from '@/modules/planner/graphql/queries/dayPlans.query.graphql';
import { getItem, setItem } from '@/modules/shared/services/storage.service';
import { createRange } from '@/modules/shared/utils/date.util';

export default async () => {
  const dates = createRange(NUMBER_OF_GROCERY_LIST_DAYS);
  const dayPlansResult = await apollo.defaultClient.query({
    query: DAY_PLANS_QUERY,
    variables: {
      startDate: dates.at(0),
      endDate: dates.at(-1),
    },
  });
  const daysWithMeals = dayPlansResult.data.dayPlans
    .filter(dayPlan => !!dayPlan)
    .map(dayPlan => dayPlan.date);
  const { groceryDates: persistedDates = [] } = (await getItem(SESSION_KEYS.GROCERY_DATES)) || {};
  const groceryDates = dates.map(date => {
    const hasPlannedMeal = daysWithMeals.some(dayWithMeal => dayWithMeal === date);

    const deselectedByUser = persistedDates.some(
      persistedDate =>
        persistedDate.date === date && !persistedDate.selected && persistedDate.hasPlannedMeal
    );

    return {
      __typename: 'GroceryDate',
      date,
      selected: deselectedByUser ? false : hasPlannedMeal,
      hasPlannedMeal,
    };
  });

  await setItem(SESSION_KEYS.GROCERY_DATES, { groceryDates });

  return groceryDates;
};
