import axios from 'axios';
import { configService } from '@/config.service';

const http = axios.create({
  baseURL: `${configService.strapiApiUrl}/api`,
  headers: {
    'content-type': 'application/json',
    authorization: `Bearer ${configService.strapiApiKey}`,
  },
  withCredentials: true,
});

export default http;
