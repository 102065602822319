<script>
  import { defineComponent, onBeforeMount, ref } from 'vue';
  import ScrollArea from '@/modules/home/components/scroll-area/ScrollArea.vue';
  import PlaylistCardList from '@/modules/home/components/playlist-card-list/PlaylistCardList.vue';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import { configService } from '@/config.service';

  export default defineComponent({
    name: 'PlaylistCarousel',
    components: { JumHeading, DefaultSpacer, PlaylistCardList, ScrollArea },
    setup() {
      const playlists = ref([]);

      onBeforeMount(async () => {
        const response = await http.get(
          '/playlists?populate[0]=phases&populate[1]=phases.items&populate[2]=phases.items.challenge,phases.items.video&populate[3]=cardImage'
        );
        playlists.value = response.data.data.map(playlist => ({
          uuid: playlist.attributes.uuid,
          slug: playlist.attributes.uuid,
          title: playlist.attributes.title?.trim(),
          body: playlist.attributes.description?.trim(),
          img: `${configService.strapiImageUrl}${playlist.attributes.cardImage.data.attributes.url}`,
        }));
      });

      return {
        playlists,
      };
    },
  });
</script>

<template>
  <section>
    <jum-heading h3 bold>
      {{ $t('home.headings.findMyFocus') }}
    </jum-heading>
    <default-spacer s />

    <fc-page-content-breakout side="right">
      <scroll-area orientation="horizontal">
        <playlist-card-list :playlists="playlists" />
      </scroll-area>
    </fc-page-content-breakout>
  </section>
</template>
