import { registerRoutes } from '@/modules/pro/routes';
import messages from '@/modules/pro/messages/nl';
import messagesEn from '@/modules/pro/messages/en';

/* eslint-disable import/no-mutable-exports */
export let guards = {};
export let utils = {};
/* eslint-enable import/no-mutable-exports */

export const proModule = ({ router }, authModule, sharedModule, i18nModule) => ({
  install() {
    guards = { ...authModule.guards };
    utils = { ...sharedModule.utils };

    i18nModule.setTranslations({ pro: { ...messages } }, 'nl');
    i18nModule.setTranslations({ pro: { ...messagesEn } }, 'en');

    registerRoutes(router);
  },
});
