import PlaylistDetail from '@/modules/home/pages/playlist-detail/PlaylistDetail.vue';
import ChallengeDetailPage from '@/modules/home/pages/challenge-detail/ChallengeDetailPage.vue';
import ChallengeDetailNoPlaylistPage from '@/modules/home/pages/challenge-detail-no-playlist/ChallengeDetailNoPlaylistPage.vue';
import VideoDetailPage from '@/modules/home/pages/video-detail/VideoDetailPage.vue';
import DoingDetailPage from '@/modules/home/pages/doing-detail/DoingDetail.vue';
import VideoDetailNoPlaylistPage from '@/modules/home/pages/video-detail-no-playlist/VideoDetailNoPlaylistPage.vue';
import Home from '../pages/home/Home';

export const TAB_ROUTE = {
  name: 'home',
  path: '/home',
  component: Home,
  meta: {
    noPayWall: true,
  },
};

const PLAYLIST_ROUTE = {
  name: 'playlist-detail',
  path: '/playlist/:slug',
  component: PlaylistDetail,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

const CHALLENGE_ROUTE = {
  name: 'challenge-detail',
  path: '/playlist/:slug/challenge/:challengeSlug',
  component: ChallengeDetailPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

// Temporarily added to prevent breaking old flow
const CHALLENGE_NO_PLAYLIST_ROUTE = {
  name: 'challenge-detail-no-playlist',
  path: '/challenge/:uuid',
  component: ChallengeDetailNoPlaylistPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

const DOING_DETAIL_ROUTE = {
  name: 'doing-detail',
  path: '/challenge/:challengeUuid/doing/:doingUuid',
  component: DoingDetailPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

const DOING_DETAIL_PLAYLIST_ROUTE = {
  name: 'doing-detail-playlist',
  path: '/playlist/:playlistUuid/challenge/:challengeUuid/doing/:doingUuid',
  component: DoingDetailPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

const VIDEO_ROUTE = {
  name: 'video-detail',
  path: '/playlist/:slug/video/:videoSlug',
  component: VideoDetailPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

const VIDEO_NO_PLAYLIST_ROUTE = {
  name: 'video-no-playlist',
  path: '/video/:videoSlug',
  component: VideoDetailNoPlaylistPage,
  meta: {
    noPayWall: true,
    savePosition: true,
  },
};

export const registerRoutes = router => {
  router.addRoute(PLAYLIST_ROUTE);
  router.addRoute(CHALLENGE_ROUTE);
  router.addRoute(CHALLENGE_NO_PLAYLIST_ROUTE);
  router.addRoute(VIDEO_ROUTE);
  router.addRoute(VIDEO_NO_PLAYLIST_ROUTE);
  router.addRoute(DOING_DETAIL_ROUTE);
  router.addRoute(DOING_DETAIL_PLAYLIST_ROUTE);
};
