<script>
  import DOMPurify from 'dompurify';
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    props: {
      html: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const sanitizedHtml = computed(() => DOMPurify.sanitize(props.html));

      return {
        sanitizedHtml,
      };
    },
  });
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <span v-html="sanitizedHtml"></span>
</template>
