<template>
  <section ref="niceNotification" class="nice-notification">
    <div v-if="bodyVisible" class="nice-notification__body">
      <slot name="body" :notification="notification">
        <jum-icon v-if="notification.icon" :icon="notification.icon" :color="iconColor" />
        <div class="nice-notification__message" v-html="notification.message"></div>
      </slot>
    </div>
  </section>
</template>

<script>
  import { JumIcon } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'NiceNotification',
    components: {
      JumIcon,
    },
    props: {
      notification: {
        required: true,
        type: Object,
      },
    },
    computed: {
      bodyVisible() {
        return this.notification.message || this.$scopedSlots.body;
      },
      iconColor() {
        return this.notification.iconColor || 'white';
      },
      duration() {
        return this.notification.duration || 1600;
      },
    },
    mounted() {
      setTimeout(() => {
        this.notification.close();
      }, this.duration);
    },
    beforeMount() {
      const { activeElement } = document;
      if (activeElement) activeElement.blur();
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-notification {
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: static;
    z-index: 200;

    &__body {
      background: $color-black;
      border-radius: $spacing-s;
      color: $color-white;
      font-size: $font-size-tiny;
      font-weight: bold;
      left: 50%;
      line-height: $line-height-tiny;
      margin: -100px auto 0 -60px;
      padding: 30px 15px 14px;
      position: fixed;
      text-align: center;
      top: 50%;
      width: 130px;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    &__message {
      // stylelint-disable-next-line selector-pseudo-element-disallowed-list
      :deep(p) {
        margin: 0 !important;
      }
    }
  }
</style>
