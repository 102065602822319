export default {
  personalDataCheckbox:
    'Ik ga akkoord met het gebruik van mijn gezondheidsgegevens voor het opstellen van een persoonlijk voedingsplan.',
  personalDataAsterix:
    "* Je kunt My Plan van The Athlete's FoodCoach niet gebruiken zonder opgave van enkele gezondheidsgegevens.",
  personalDataPrivacyStatement: 'Lees hier meer over in het {privacyStatement}',
  activityLevel: {
    question: 'Wanneer je niet sport of werkt, in welke mate ben je dan nog lichamelijk actief?',
    explanation:
      'Zit je bijvoorbeeld veel thuis, kijk je televisie en doe je tussendoor boodschappen of laat je de hond uit? Dan ben je gemiddeld actief. Erg actief ben je wanneer je urenlang achter de bar in de sportkantine staat. En ben je in je vrije tijd urenlang aan het klussen of tuinieren? Dan ben je extreem actief.',
    light:
      'Naast werk of sport zit ik vooral, doe wat boodschappen of laat de hond uit. Dit geldt voor bijna alle sporters.',
    moderate:
      'Naast werk of sport ben ik uren per dag in beweging. Zoals fietsen naar het werk, lange wandelingen of veldcoaching van sportteams.',
    heavy:
      'Naast werk of sport ben ik uren per dag intensief lichamelijk bezig. Zoals een huis of tuin renoveren.',
  },
  dailyEnergyNeed: {
    explanation:
      'Mooi, we hebben je profiel op een rustdag bepaald! We weten wat je gemiddelde energiebehoefte is en kunnen je een richting geven hoeveel koolhydraten, eiwitten en vetten je nodig hebt.',
    edit: 'Je kan deze gegevens controleren en later altijd wijzigen in je profiel.',
  },
  length:
    'Om je goed te kunnen coachen, berekenen we eerst wat je lichaam aan energie nodig heeft als je niet sport. Daarvoor hebben we een aantal gegevens van je nodig. Te beginnen met je lengte.',
  sleepingHours: 'Hoeveel uur slaap je per nacht?',
  sport: {
    hello: `Hallo! Leuk dat we je mogen coachen.`,
    helloName: `Hallo!`,
    onlyCycling:
      'Helaas wordt momenteel alleen de wielrennen ondersteund. Andere sporten volgen snel.',
    sportSelectorInfo:
      "The Athlete's FoodCoach is samen met de wielerprofs van Team Visma | Lease a Bike ontwikkeld. Wielrennen en hardlopen zijn de eerste sporten die door The Athlete's FoodCoach worden ondersteund. Andere sporten volgen snel.",
  },
  weight: 'Ook je gewicht speelt een belangrijke rol.',
  workType: {
    intro:
      'Daarnaast vergt de ene baan meer energie van je lichaam dan de andere baan. Daarom willen we graag weten wat voor werk je doet.',
    explanation:
      'Zit je veel achter je bureau? Dan heb je zittend werk. Een leerkracht verricht licht actief werk. Een postbode heeft gemiddeld actief werk en als stratenmaker heb je zwaar actief werk.',
  },
  changeParticipantsWarning:
    'Let op: door het aantal personen te wijzigen, veranderen de hoeveelheden voor ingrediënten, jouw portie als sporter is {portionPercentage} van deze maaltijd.',
  nutrientDirection: 'Ga in verhouding {direction} in {nutrient}',
  creatingPlanChecks: [
    'Berekenen van je basis dagelijkse energiebehoefte',
    'Verdelen van je energiebehoefte over je dagelijkse maaltijden',
    'Afstemmen van je plan op je hoofdsport',
    'Laden van bijpassende producten en recepten',
  ],
  selectSport: 'Selecteer de sport die je het meest beoefent.',
};
