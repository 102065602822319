<template>
  <fc-tooltip
    :show-by-default="showCoachMark"
    position="left"
    :highlight-size="44"
    :highlight-offset="[4, 0]"
    :trigger-on-click="false"
  >
    <template #trigger>
      <router-link
        :key="count"
        v-slot="{ navigate }"
        :to="{ name: 'groceries-products' }"
        class="basket-counter"
        custom
      >
        <jum-button circle tertiary @click="navigate">
          <jum-icon icon="jum-basket" size="30" />
          <span v-if="count > 0" class="basket-counter__total">
            {{ count }}
          </span>
        </jum-button>
      </router-link>
    </template>
    <template #content="{ hideTooltip }">
      <jum-heading h6>
        {{ $t('shared.basketCoachMark.content') }}
      </jum-heading>
      <jum-button compact @click="hideTooltip">
        {{ $t('shared.basketCoachMark.confirmText') }}
      </jum-button>
    </template>
  </fc-tooltip>
</template>

<script>
  import { inject, onMounted, onActivated, defineComponent } from 'vue';
  import { JumButton, JumIcon, JumHeading } from '@blancofoodcoach/kompas';
  import FcTooltip from '@/modules/shared/components/tooltip/Tooltip';

  export default defineComponent({
    name: 'FcBasketCounter',
    components: {
      JumButton,
      JumIcon,
      JumHeading,
      FcTooltip,
    },
    props: {
      showCoachMark: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { count, fetch } = inject('groceriesStore');

      onActivated(async () => {
        fetch();
      });

      onMounted(async () => {
        fetch();
      });

      return {
        count,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .basket-counter {
    height: 48px;
    position: relative;

    &__total {
      background: $color-prominent;
      border-radius: 50%;
      color: $color-white;
      display: block;
      font-size: 10px;
      font-weight: bold;
      left: 18px;
      line-height: 13px;
      min-width: 17px;
      padding: 2px;
      position: absolute;
      text-align: center;
      top: 4px;
    }
  }
</style>
