import { isNil } from 'ramda';
import apollo from '@/modules/shared/graphql/apollo.config';
import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';

export const fieldsAreValid = (fields, user) =>
  !fields.some(field =>
    typeof field === 'object' ? user[field.name] !== field.expectedValue : isNil(user[field])
  );

const redirectRoute = (redirectRouteName, { query: { redirect } }, next) => {
  next({ name: redirectRouteName, query: { redirect } });
};

const onboardingSportsStepGuard = (fields, redirectRouteName) => async (to, from, next) => {
  const redirectToLogin = () => next({ name: 'auth-login' });

  try {
    const {
      data: {
        getUserProfile: { user },
      },
    } = await apollo.defaultClient.query({ query: GET_USER_PROFILE_QUERY });

    if (fieldsAreValid(fields, user)) {
      next();
    } else {
      redirectRoute(redirectRouteName, to, next);
    }
  } catch {
    redirectToLogin();
  }
};

export default onboardingSportsStepGuard;
