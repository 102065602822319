export default {
  mealMoments: {
    breakfast: '{n} breakfast recipes | {n} breakfast recipe | {n} breakfast recipes',
    morningSnack: '{n} morning snacks | {n} morning snack | {n} morning snacks',
    preRaceSnack: '{n} pre-race snacks | {n} pre-race snack | {n} pre-race snacks',
    lunch: '{n} lunch recipes | {n} lunch recipe | {n} lunch recipes',
    afternoonSnack: '{n} afternoon snacks | {n} afternoon snack | {n} afternoon snacks',
    recoverySnack: '{n} shakes | {n} shake | {n} shakes',
    recoveryMeal1: '{n} recovery meals | {n} recovery meal | {n} recovery meals',
    recoveryMeal2: '{n} recovery meals | {n} recovery meal | {n} recovery meals',
    dinner: '{n} dinner recipes | {n} dinner recipe | {n} dinner recipes',
    eveningSnack: '{n} evening snacks | {n} evening snack | {n} evening snacks',
  },
  badges: {
    kcal: '{n} kcal',
    carbs: 'Carbs {n} g',
  },
  progress: {
    kcal: 'Energy: {planned}/{target}',
    carbs: 'Carbs: {planned}/{target} g',
    short: {
      kcal: '({planned}/{target} kcal)',
    },
  },
  min: '{n} min',
  ingredients: '{n} ingredients | {n} ingredient | {n} ingredients',
  kcalserving: '{n} kcal / serving',
  ingr: '{n} ingr.',
};
