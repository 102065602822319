export default {
  personalDataCheckbox:
    'I agree that my body metrics and baseline activity level data will be used to create my personal nutrition plan',
  personalDataPrivacyStatement:
    "For more information, read The Athlete's FoodCoach {privacyStatement}",
  activityLevel: {
    question: 'Which activity level should I select?',
    explanation:
      'For example, do you spend a lot of time at home, watch television and do your shopping in between or do you walk the dog? Then you are average active. You are quite active when you spend hours behind the bar in the sports canteen. And do you spend hours doing odd jobs or gardening in your spare time? Then you are very active.',
    light:
      'Next to work or sport, I mainly sit down, do some groceries shopping or walk the dog. Applies to almost all athletes.',
    moderate:
      'Next to work or sport, I spend hours a day moving around. Like commuting by bike, long walks or field coaching sport teams.',
    heavy:
      'Next to work or sports, I spend hours a day engaging in intensive physical activity. Like renovating a house or garden.',
  },
  dailyEnergyNeed: {
    explanation:
      'Great, we determined your profile on a rest day! We know what your average energy needs are and can give you a direction on how many carbohydrates, proteins and fats you need.',
    edit: 'You can check this information and always change it in your profile.',
  },
  length:
    "To be able to coach you properly, we first calculate what your body needs in energy if you don't exercise. For this we need some information from you. Start with your height.",
  sleepingHours: 'How many hours do you sleep each night?',
  sport: {
    hello: `Hey! Glad we got to coach you.`,
    helloName: `Hello!`,
    onlyCycling:
      'Helaas wordt momenteel alleen de wielrennen ondersteund. Andere sporten volgen snel.',
    sportSelectorInfo:
      "FoodCoach is samen met de wielerprofs van Team Visma | Lease a Bike ontwikkeld. Wielrennen en hardlopen zijn de eerste sporten die door The Athlete's FoodCoach worden ondersteund. Andere sporten volgen snel.",
  },
  weight: 'Your weight also plays an important role.',
  workType: {
    intro:
      'In addition, one job requires more energy from your body than the other job. That is why we would like to know what kind of work you do.',
    explanation:
      'Do you spend a lot of time at your desk? Then you you should select physically passive (sitting down). A teacher does mildly active work. A postman has quite active work and as a road worker you have very active work.',
  },
  changeParticipantsWarning:
    'Note: by changing the number of people, the quantities for ingredients change, your portion as an athlete is {portionPercentage} of this meal.',
  nutrientDirection: 'Proportionately go {direction} in {nutrient}',
  creatingPlanChecks: [
    'Calculating your basic daily energy needs',
    'Allocating your energy needs to your daily meals',
    'Matching your plan to your main sport',
    'Loading matching products and recipes',
  ],
  selectSport: 'Select the sport you participate in most often.',
};
