<template>
  <component :is="isLink ? 'a' : 'button'" class="fc-link" v-on="$listeners">
    <slot></slot>
  </component>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcLink',
    computed: {
      isLink: ({ $attrs }) => !!$attrs.href,
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-link {
    background: none;
    border: 0;
    box-sizing: border-box;
    color: currentColor;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }
</style>
