<template>
  <span
    class="sport-value__text"
    :class="{
      'sport-value__text--disabled': sport.disabled,
      'sport-value__text--teamsport': isTeamSport,
    }"
  >
    <jum-lottie
      v-if="sport.animation"
      :ref="sport.testId"
      class="sport-value__animation"
      :animation-data="sport.animation"
      loop
      :autoplay="isActive"
    />
    <jum-icon v-else class="sport-value__animation__icon" :icon="sport.icon" size="32" />
    {{ $t(sport.label) }}
    <fc-badge v-if="sport.disabled" gray>{{ $t('onboardingSports.labels.notAvailable') }}</fc-badge>
  </span>
</template>

<script>
  import { JumIcon, JumLottie } from '@blancofoodcoach/kompas';
  import FcBadge from '@/modules/shared/components/badge/Badge';
  import { TEAMSPORTS, TEAMSPORT } from '@/modules/shared/constants/profile.const';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SportValue',
    components: {
      JumIcon,
      JumLottie,
      FcBadge,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      sport: {
        type: Object,
        default: () => ({
          animation: null,
          sportValue: '',
          icon: '',
          disabled: '',
          testId: '',
          label: '',
        }),
      },
    },
    computed: {
      computedValue: ({ value }) => (TEAMSPORTS.includes(value) ? TEAMSPORT : value),
      isActive: ({ sport: { sportValue }, computedValue }) => sportValue === computedValue,
      isTeamSport: ({ computedValue, isActive }) => computedValue === TEAMSPORT && isActive,
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .sport-value {
    &__animation {
      height: $spacing-l;
      margin: 0 $spacing-s;
      position: relative;
      transition: opacity 0.15s ease-in;
      width: $spacing-l;

      &__icon {
        margin: 0 $spacing-s;
      }
    }

    &__text {
      align-items: center;
      display: flex;

      &--teamsport {
        margin-bottom: $spacing-m;
        margin-top: -5px;
        width: 100%;
      }

      &--disabled {
        color: $color-gray-lighten-30;
      }

      .fc-badge {
        margin-left: $spacing-m;
      }
    }
  }
</style>
