export default {
  app: 'App',
  hello: 'Hallo',
  integrations: 'Koppelingen',
  energyCalculation: 'Welke <strong>berekeningsmethode</strong> moeten we gebruiken',
  profile: 'Profiel',
  bodyMetrics: 'Lichaamsmetingen',
  baselineActivityLevel: 'Basisactiviteitsniveau',
  sport: 'Sport',
  subscription: '{type} abonnement',
  howToManageMySubscription: 'Hoe kan ik mijn abonnement beheren?',
  selectProductDatabase: 'Selecteer jouw productdatabase',
  whichDatabase: 'Welke database moet ik kiezen?',
  whatIsYourFtp: 'Wat is <strong>jouw FTP?</strong>',
  whichWorkType: 'Welk type werk is op mij van toepassing?',
  whatShouldIChoose: 'Wat moet ik kiezen?',
  whichNutritionView: 'Welke weergave past het beste bij mij?',
  whichWeightGoal: 'Welk gewichtsdoel moet ik kiezen?',
  smartWeightGain: 'Wat is een slim aankom richtpunt voor mij?',
};
