import { between, required } from 'vee-validate/dist/rules.umd';
// Ftp form rules
const ftpRules = [
  {
    name: 'ftp_required',
    message: 'core.inputErrors.invalid.ftp',
    rule: required,
  },
  {
    name: 'ftp_invalid',
    message: 'core.inputErrors.invalid.ftp',
    rule: between,
  },
];

export default ftpRules;
