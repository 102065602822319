import buttons from './buttons.nl';
import headings from './headings.nl';
import info from './info.nl';
import labels from './labels.nl';
import links from './links.nl';
import misc from './misc.nl';
import modals from './modals.nl';
import notifications from './notifications.nl';
import titles from './titles.nl';

export default {
  buttons,
  headings,
  info,
  labels,
  links,
  misc,
  titles,
  modals,
  notifications,
};
