import BaseType from './baseType';

export class Modal extends BaseType {
  constructor({
    title = '',
    message = '',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    component = null,
    onClose = null,
    onCancel = null,
    onConfirm = null,
    ...args
  }) {
    super(component, onClose);
    this.title = title;
    this.message = message;
    this.onCancel = onCancel;
    this.onConfirm = onConfirm;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
    this.type = 'modal';

    Object.keys(args).forEach(key => {
      this[key] = args[key];
    });
  }

  cancel() {
    super.close();
    if (this.onCancel) this.onCancel(this);
  }

  confirm() {
    if (this.onConfirm) this.onConfirm(this);
  }
}
