export default {
  addOwnIngredient: {
    title: 'Eigen ingrediënt toevoegen',
    message:
      'Het toevoegen van eigen ingrediënten werkt helaas nog niet. We werken eraan om dit zo snel mogelijk in de app te krijgen.',
    confirmText: 'Oké',
  },
  changeInFtp: {
    title: 'Wijziging FTP-waarde',
    message: `Door je wijziging is het wattage van al geplande trainingen mogelijk inaccuraat, pas dit evt. aan bij ‘trainingen’`,
    confirmText: 'Oké',
    cancelText: 'Annuleren',
  },
  changeInProfile: {
    title: 'Profiel wijziging',
    message:
      'Bij een wijziging van één van de profielgegevens verandert de dagelijkse energiebehoefte.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  confirmAccountReset: {
    title: "The Athlete's FoodCoach account verwijderen",
    message:
      "Wat jammer dat je je The Athlete's FoodCoach account wil verwijderen. Weet je het zeker?",
    confirmText: 'Verwijderen',
    cancelText: 'Annuleren',
  },
  consentToPersonalDataSharing: {
    title: 'Mijn persoonlijke voedingsplan',
    message:
      'Om je persoonlijke voedingsplan te maken, moeten we je energiebehoefte berekenen. Hiervoor hebben we enkele van je lichaamsmetingen en basisactiviteitsniveau nodig.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  createTraining: {
    title: 'Training toevoegen',
    message: 'Door het toevoegen van deze training verandert je energiebehoefte voor deze dag, ',
  },
  deleteUserRecipe: {
    title: 'Eigen recept verwijderen',
    message:
      'Door dit recept te verwijderen, wordt hij verwijderd van alle toekomstige ingeplande momenten.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  deleteUserProduct: {
    title: 'Weet je zeker dat je dit product wil verwijderen?',
    message:
      'Het verdwijnt uit de lijst met producten, maar blijft bestaan in alle maaltijden en planningen waar het is toegevoegd.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  userProductAlreadyDeleted: {
    title: 'Je hebt dit  product verwijderd',
    message:
      'Je kunt deze dus niet meer aanpassen. Het product blijft bestaan in alle maaltijden en planningen waar het is toegevoegd.',
    confirmText: 'Oké, sluiten',
  },
  energyIntroduction: {
    title: 'Je energiebehoefte',
    message:
      'Wanneer je een training plant, verandert je energiebehoefte. Dit heeft invloed op de hoeveelheid koolhydraten, eiwitten en vetten.',
    confirmText: 'Oké, duidelijk',
  },
  mealDetailIntroduction: {
    title: 'Maaltijden op maat',
    message: {
      default:
        'We stellen al jouw maaltijden op maat samen. Zo krijg je altijd de juiste hoeveelheid koolhydraten, eiwitten en vetten per maaltijd binnen.',
      userRecipe:
        'Op basis van de macronutriëntenverhouding van je recept gaan wij de ingrediënten schalen, zodat je het juiste aantal kilocalorieën binnen krijgt.',
    },
    confirmText: 'Oké, duidelijk',
  },
  moveTraining: {
    title: 'Training wijzigen',
    message:
      'Door je wijziging verandert je energiebehoefte en worden eventueel geplande maaltijden voor deze dag(en) verwijderd.',
  },
  removeTraining: {
    title: 'Training verwijderen',
    message: 'Door het verwijderen van deze training verandert je energiebehoefte voor deze dag, ',
  },
  trainingMoment: {
    mealsRemoved:
      ' | hierdoor wordt {n} geplande maaltijd verwijderd. | hierdoor worden {n} geplande maaltijden verwijderd.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  pairingNotPossible: {
    title: 'Koppelen niet mogelijk',
    message:
      'Je hebt al een activiteit gekoppeld aan je trainingssessie(s). Om wijzigingen aan te brengen, koppel eerst een van de activiteiten los.',
    confirmText: 'Oké, sluiten',
  },
  updateTraining: {
    title: 'Training wijzigen',
    message:
      'Door het wijzigen van deze training verandert je energiebehoefte voor deze dag. Mogelijk passen de reeds geplande maaltijden niet meer in je plan.',
  },
  ingredientNutrients: {
    title: '{ingredientName} (per {grams})',
  },
  missingRecipes: {
    message:
      'Bij één of meer maaltijdmomenten hebben we je niet kunnen verrassen omdat er geen passende recepten zijn.',
    confirmText: 'Oké',
  },
  productDatabaseOnboarding: {
    title: 'Selecteer een productdatabase',
    message:
      'Als je het loggen specifieker wilt maken door je favoriete producten toe te voegen, selecteer dan de database van het land waarin je je momenteel bevindt.',
  },
  weightGoalConflict: {
    title: 'Verander je gewichtsdoel',
    message:
      'Je hebt net een {type} dag gepland. We raden je aan om je gewichtsdoel terug te zetten naar ‘gewicht behouden’ voor het meest optimale plan.',
    confirm: 'Oké, aanpassen',
    cancel: 'Nee bedankt',
    types: {
      race: 'race',
      carbLoading: 'carb loading',
    },
  },
};
