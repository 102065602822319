import { render, staticRenderFns } from "./ProTermsConditionsPopover.vue?vue&type=template&id=78f4b9ac&scoped=true"
import script from "./ProTermsConditionsPopover.vue?vue&type=script&lang=js"
export * from "./ProTermsConditionsPopover.vue?vue&type=script&lang=js"
import style0 from "./ProTermsConditionsPopover.vue?vue&type=style&index=0&id=78f4b9ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f4b9ac",
  null
  
)

export default component.exports