export default {
  birthDateSaved: 'Date of birth is saved',
  dailyEnergyNeedSaved: 'Energy needs is saved',
  eatingOnActivityPlanned: 'Food during training is planned',
  ftpSaved: 'FTP is saved',
  heightSaved: 'Height is saved',
  mealPlanned: 'Your {mealMoment} is planned',
  mealRemoved: 'Your {mealMoment} is deleted',
  productAddedToPlan: '{product} is added to your plate',
  productUpdatedForRecipe: '{product} is updated',
  productUpdatedForPlan: '{product} is updated',
  productRemovedFromPlan: '{product} is deleted from your plate',
  productRemoved: '{product} is deleted',
  sleepingHoursSaved: 'Sleeping hours are saved',
  sportSaved: 'Sport is saved',
  userRecipeRemoved: 'Your own recipes is deleed',
  energyCalculationSaved: 'Energy calculation is saved',
  weightSaved: 'Weight is saved',
  workingHoursSaved: 'Work hours are saved',
  workTypeSaved: 'Work type is saved',
  mealsRecalculated: 'Your open meals are recalculated',
  invalidBarcode: 'Invalid barcode',
};
