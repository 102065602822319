export default {
  add: 'Toevoegen',
  addIngredients: 'Ingrediënten toevoegen',
  addMissingIngredient: 'Voeg een ontbrekend ingrediënt toe',
  addMissingProduct: 'Voeg een product toe',
  addOwnIngredient: 'Voeg een eigen ingrediënt toe',
  addOwnProduct: 'Voeg een eigen product toe',
  addProduct: 'Voeg een product toe',
  addProductsOrRecipes: 'Zoek product of recept',
  addToGroceryList: 'Plan dit recept in',
  addTraining: 'Training toevoegen',
  backToRecipe: 'Terug naar het recept',
  calculateEnergyNeed: 'Je energiebehoefte berekenen',
  cancelDeleteRecipe: 'Nee, annuleer',
  cancel: 'Nee, annuleren',
  confirm: 'Ja, doorgaan',
  close: 'Sluiten',
  confirmAdvancedFilters: 'Toon resultaten',
  confirmDeleteProductFromRecipe: 'Ja, verwijder dit product',
  deleteProductFromPlan: 'Weet je zeker dat je dit product uit je plan wil halen?',
  deleteProduct: 'Product verwijderen',
  deleteWarning: 'Dit kan niet ongedaan worden gemaakt',
  deleteRecipe: 'Weet je zeker dat je dit recept wilt verwijderen?',
  deleteRecipeWarning:
    'Het recept wordt verwijderd uit je plan. Dit kan niet ongedaan worden gemaakt',
  confirmDeleteRecipe: 'Ja, verwijder dit recept',
  done: 'Ik ben klaar',
  edit: 'Wijzigen',
  editInfo: 'Gegevens bewerken',
  editRecipeData: 'Naam en/of momenten wijzigen',
  editRecipe: 'Recept bewerken',
  editProduct: 'Product bewerken',
  letsGo: 'Aan de slag',
  next: 'Volgende',
  nextStep: 'Volgende stap',
  planMeals: 'Plan mijn maaltijden in',
  planYourOwnRecipe: 'Eigen recept toevoegen',
  removeFromGroceryList: 'Recept verwijderen',
  removeProduct: 'Product verwijderen',
  removeProductFromPlan: 'Haal product uit je plan',
  scanCode: 'Scan',
  scanAgain: 'Scan opnieuw',
  save: 'Opslaan',
  saveYourMeal: 'Maaltijd opslaan',
  saveIngredient: 'Product opslaan',
  select: 'Selecteren',
  selectProduct: 'Selecteer product',
  send: 'Versturen',
  showLess: 'Toon minder',
  showMore: 'Toon meer',
  stepByStep: 'Stap voor stap koken',
  updateGroceryList: 'Aanpassen',
  updateProduct: 'Product bijwerken',
  viewAll: 'Bekijk alles',
  viewRecipeDetails: 'Bekijk recept details',
  openSettings: 'Open instellingen',
  tryAgain: 'Probeer opnieuw',
};
