<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useRoute } from 'vue-router/composables';
  import { usePlaylist } from '@/composables/usePlaylist';

  const getYouTubeVideoId = url => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  export default defineComponent({
    name: 'VideoDetailPage',
    setup() {
      const route = useRoute();
      const { slug, videoSlug } = route.params;
      const { playlist, addPlaylistProgress } = usePlaylist(slug);
      const playerVars = { controls: 1, showinfo: 0, rel: 0, modestbranding: 1 };
      const video = computed(() => {
        if (!playlist.value) return null;

        return playlist.value.phases
          .flatMap(phase => phase.items)
          .find(item => item.meta.uuid === videoSlug && item.tag === 'video');
      });
      const videoId = computed(() =>
        video.value?.meta?.url ? getYouTubeVideoId(video.value?.meta?.url) : null
      );
      const markAsCompleted = async () => {
        await addPlaylistProgress(playlist.value.uuid, video.value.meta.uuid);
      };

      return {
        playlist,
        video,
        videoId,
        playerVars,
        markAsCompleted,
      };
    },
  });
</script>

<template>
  <fc-page v-if="playlist">
    <template #top>
      <fc-nav-bar
        :back-button="{
          name: 'playlist-detail',
          params: { slug: playlist.slug },
        }"
      >
        {{ video.meta.title }}
      </fc-nav-bar>
    </template>

    <fc-page-content>
      <youtube
        v-if="videoId"
        class="video-container"
        :video-id="videoId"
        :player-vars="playerVars"
        @ended="markAsCompleted"
      />
    </fc-page-content>
  </fc-page>
</template>

<style scoped lang="scss">
  .video-container {
    aspect-ratio: 16/9;
    height: 100%;
    overflow: hidden;
    width: 100%;

    :deep(iframe) {
      height: 100%;
      width: 100%;
    }
  }

  .fc-nav-bar {
    background-color: #0f0f0f;
    color: #fff;
  }

  .fc-page-content {
    align-items: center;
    background-color: #0f0f0f;
    display: flex;
  }
</style>
