import { apolloModule as registerApolloModule } from '@/modules/apollo';
import { catchModule as registerCatchModule } from '@/modules/catch';
import { coreModule as registerCoreModule } from '@/modules/core';
import { groceriesModule as registerGroceriesModule } from '@/modules/groceries';
import { homeModule as registerHomeModule } from '@/modules/home';
import { i18nModule as registerI18nModule } from '@/modules/i18n';
import { onboardingModule as registerOnboardingModule } from '@/modules/onboarding';
import { onboardingSportsModule as registerOnboardingSportsModule } from '@/modules/onboarding-sports';
import { plannerModule as registerPlannerModule } from '@/modules/planner';
import { trainingModule as registerTrainingModule } from '@/modules/planner/training';
import { proModule as registerProModule } from '@/modules/pro';
import { profileModule as registerProfileModule } from '@/modules/profile';
import { routerModule as registerRouterModule } from '@/modules/router';
import { sharedModule as registerSharedModule } from '@/modules/shared';
import { authModule as registerAuthModule } from '@/modules/shared/auth';
import { tabsModule as registerTabsModule } from '@/modules/tabs';
import { validationModule as registerValidationModule } from '@/modules/validation';

export const installModules = localVue => {
  // Service modules
  const routerModule = registerRouterModule();
  routerModule.install(localVue);

  const apolloModule = registerApolloModule();
  if (process.env.BABEL_ENV !== 'test') {
    apolloModule.install(localVue);
  }

  const i18nModule = registerI18nModule();
  i18nModule.install(localVue);

  const validationModule = registerValidationModule(i18nModule);
  validationModule.install(localVue);

  // Shared
  const sharedModule = registerSharedModule(i18nModule);
  sharedModule.install();

  // Authentication
  const authModule = registerAuthModule(sharedModule, routerModule, i18nModule);
  authModule.install();

  // Core
  const coreModule = registerCoreModule(sharedModule, routerModule, i18nModule);
  coreModule.install(localVue);

  // Feature modules
  const onboardingModule = registerOnboardingModule(routerModule, i18nModule);
  onboardingModule.install();

  const onboardingSportsModule = registerOnboardingSportsModule(routerModule, i18nModule);
  onboardingSportsModule.install();

  const homeModule = registerHomeModule(routerModule, sharedModule, onboardingModule, i18nModule);
  homeModule.install();

  const proModule = registerProModule(routerModule, authModule, sharedModule, i18nModule);
  proModule.install();

  const trainingModule = registerTrainingModule(
    sharedModule,
    routerModule,
    onboardingModule,
    onboardingSportsModule,
    i18nModule,
    proModule
  );
  trainingModule.install(localVue);

  const groceriesModule = registerGroceriesModule(
    sharedModule,
    routerModule,
    onboardingModule,
    i18nModule
  );
  groceriesModule.install(localVue);

  const plannerModule = registerPlannerModule(routerModule, coreModule, sharedModule, i18nModule);
  plannerModule.install();

  const profileModule = registerProfileModule(
    sharedModule,
    routerModule,
    i18nModule,
    onboardingModule,
    proModule
  );
  profileModule.install();

  const tabsModule = registerTabsModule(routerModule, homeModule, profileModule, plannerModule);
  tabsModule.install();

  // Catch - This module has to be registered last
  // so the catch-all (*) route only redirects if no match is found
  const catchModule = registerCatchModule(routerModule);
  catchModule.install();

  return { routerModule, i18nModule, apolloModule };
};
