<template>
  <div class="sports-selector">
    <fc-form-group>
      <fc-input-radio-panel
        v-for="(sport, key) in sports"
        :key="`sport-choice-${key}`"
        :checked="sport.sportValue === computedValue"
        class="sports-selector__panel"
      >
        <jum-input-radio
          :checked="computedValue"
          :value="sport.sportValue"
          :model-value="computedValue"
          name="sport"
          :disabled="sport.disabled"
          :data-test-id="sport.testId"
          class="sports-selector__button"
          :class="{
            'sports-selector__button--disabled': sport.disabled,
            'sports-selector__button--selected': sport.sportValue === computedValue,
            'sports-selector__button--teamsport':
              sport.sportValue === teamsport && sport.sportValue === computedValue,
          }"
          @update:modelValue="currentValue => handleChange(currentValue, sport.testId)"
        >
          <sport-value :ref="sport.testId" :sport="sport" :value="value" />
          <sport-dropdown
            v-if="sport.sportValue === teamsport && sport.sportValue === computedValue"
            :value="value"
            @on-sport-change="currentValue => handleChange(currentValue, sport.testId)"
          />
        </jum-input-radio>
      </fc-input-radio-panel>

      <jum-input-error-message :message="errors[0]" data-test-id="sport-error" />
    </fc-form-group>
  </div>
</template>

<script>
  import { JumInputRadio, JumInputErrorMessage } from '@blancofoodcoach/kompas';
  import { TEAMSPORT, TEAMSPORTS } from '@/modules/shared/constants/profile.const';
  import FcInputRadioPanel from '@/modules/shared/components/input-radio-panel/InputRadioPanel';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup';
  import { defineComponent } from 'vue';
  import SportValue from './SportValue';
  import SportDropdown from './SportDropdown';

  export default defineComponent({
    name: 'FcSportsPicker',
    components: {
      FcFormGroup,
      JumInputErrorMessage,
      JumInputRadio,
      SportValue,
      SportDropdown,
      FcInputRadioPanel,
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: {
        type: String,
        default: 'cycling',
      },
      errors: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      teamsport: TEAMSPORT,
    }),
    computed: {
      sports: () => [
        {
          label: 'onboardingSports.labels.sports.cycling',
          sportValue: 'cycling',
          testId: 'radioWielrennen',
          // eslint-disable-next-line global-require
          animation: require('@/modules/shared/assets/animations/cycling'),
          chosen: false,
          disabled: false,
        },
        {
          label: 'onboardingSports.labels.sports.running',
          sportValue: 'running',
          testId: 'radioHardlopen',
          // eslint-disable-next-line global-require
          animation: require('@/modules/shared/assets/animations/running'),
          chosen: false,
          disabled: false,
        },
        {
          label: 'onboardingSports.labels.sports.teamsport',
          sportValue: 'teamsport',
          testId: 'radioTeamsport',
          // eslint-disable-next-line global-require
          animation: require('@/modules/shared/assets/animations/teamsport'),
          chosen: false,
          disabled: false,
        },
        {
          label: 'onboardingSports.labels.sports.fitness',
          sportValue: 'fitness',
          testId: 'radioFitness',
          // eslint-disable-next-line global-require
          animation: require('@/modules/shared/assets/animations/fitness'),
          chosen: false,
          disabled: false,
        },
      ],
      isTeamSport: ({ value, teamsport }) => value === teamsport,
      computedValue: ({ value }) => (TEAMSPORTS.includes(value) ? TEAMSPORT : value),
    },
    methods: {
      handleChange(value, currentTestId) {
        this.sports.forEach(({ testId, animation }) => {
          const currentAnimation = this.$refs[testId][0];
          if (animation) currentAnimation.$refs[testId].animation.pause();
        });

        const currentAnimation = this.$refs[currentTestId][0];
        currentAnimation.$refs[currentTestId].animation.play();

        this.$emit('change', value);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .sports-selector {
    &__panel {
      margin-bottom: $spacing-xs;
      width: 100%;
    }

    &__button {
      align-items: center;
      display: flex !important;
      padding: 0;

      &--teamsport {
        align-items: flex-start;
        margin-top: 5px;
      }
    }
  }
</style>
