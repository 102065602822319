<script>
  import { defineComponent } from 'vue';
  import FoodCoachMedalsPlaceholder from '@/modules/home/components/medals-placeholder/FoodCoachMedalsPlaceholder.vue';
  import ProtectMedalsPlaceholder from '@/modules/home/components/medals-placeholder/ProtectMedalsPlaceholder.vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import RecoverMedalsPlaceholder from '@/modules/home/components/medals-placeholder/RecoverMedalsPlaceholder.vue';
  import PerformMedalsPlaceholder from '@/modules/home/components/medals-placeholder/PerformMedalsPlaceholder.vue';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { popovery } from '@/modules/core/plugins/renderlessBuss';
  import MedalsPopover from '@/modules/home/components/popovers/MedalsPopover.vue';

  export default defineComponent({
    name: 'MedalsPlaceholder',
    components: {
      DefaultSpacer,
      PerformMedalsPlaceholder,
      RecoverMedalsPlaceholder,
      JumHeading,
      ProtectMedalsPlaceholder,
      FoodCoachMedalsPlaceholder,
    },

    setup() {
      const showMedalsModal = async () => {
        popovery({
          component: MedalsPopover,
        });
      };

      return {
        showMedalsModal,
      };
    },
  });
</script>

<template>
  <section class="medals-placeholder">
    <jum-heading h3 bold>
      {{ $t('home.medals.title') }}
    </jum-heading>

    <p>
      {{ $t('home.medals.description') }}
      <span class="cta" @click="showMedalsModal">Read More</span>
    </p>

    <default-spacer s />

    <div class="placeholder-list">
      <perform-medals-placeholder />
      <recover-medals-placeholder />
      <protect-medals-placeholder />
      <food-coach-medals-placeholder />
    </div>
  </section>
</template>

<style scoped lang="scss">
  .placeholder-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cta {
    text-decoration: underline;
  }
</style>
