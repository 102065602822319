import { ApolloError } from 'apollo-client';
import { SESSION_KEYS } from '@/modules/shared/constants/storage.const';
import GROCERY_DATES_QUERY from '@/modules/shared/graphql/queries/groceryDates.query.graphql';
import { setItem } from '@/modules/shared/services/storage.service';

export default async (_, { date }, { cache }) => {
  const data = cache.readQuery({
    query: GROCERY_DATES_QUERY,
  });

  const groceryDate = data.groceryDates.find(({ date: cachedDate }) => cachedDate === date);

  if (!groceryDate) throw new ApolloError({ errorMessage: 'Date not found in range' });

  groceryDate.selected = !groceryDate.selected;

  cache.writeQuery({ query: GROCERY_DATES_QUERY, data });
  await setItem(SESSION_KEYS.GROCERY_DATES, data);

  return groceryDate.selected;
};
