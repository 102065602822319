<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ScrollArea',
    props: {
      orientation: {
        type: String,
        default: 'horizontal',
        validator: orientation => ['vertical', 'horizontal'].includes(orientation),
      },
    },
  });
</script>

<template>
  <div class="scroll-area" :class="{ [orientation]: orientation }">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
  .scroll-area {
    margin-bottom: -15px;
    max-width: 100vw;
    -ms-overflow-style: none;
    padding-bottom: 15px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.horizontal {
      overflow: scroll hidden;
      scroll-snap-type: x mandatory;
    }

    &.vertical {
      overflow: hidden scroll;
      scroll-snap-type: y mandatory;
    }

    > * {
      scroll-snap-align: start;
    }
  }
</style>
