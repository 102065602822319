<script>
  import { computed, defineComponent, onBeforeMount, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import qs from 'qs';
  import { Doing } from '@/util/gamification/doing';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';
  import addPlaylistProgressMutation from '@/modules/shared/graphql/mutations/addPlaylistProgress.mutation.graphql';
  import { useVueProxy } from '@/composables/useVueProxy';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import ChatIcon from '@/components/chat/ChatIcon.vue';

  export default defineComponent({
    name: 'DoingDetail',
    components: {
      ChatIcon,
      LinkCardList,
      JumButton,
      JumHeading,
      DefaultSpacer,
    },
    setup() {
      const { apollo } = useVueProxy();
      const router = useRouter();
      const route = useRoute();
      const { user, hasFlag } = useProfile();
      const showChat = computed(() => hasFlag('foodcoach-ai'));
      const { playlistUuid, challengeUuid, doingUuid } = route.params;
      const { stepUuid } = route.query;
      const doing = ref();

      onBeforeMount(async () => {
        const query = qs.stringify({
          populate: 'deep,8',
          filters: {
            uuid: {
              $eq: doingUuid,
            },
          },
        });
        const response = await http.get(`actions?${query}`);
        const [resource] = response.data.data;
        doing.value = Doing.createFromStrapi(resource);
      });

      const addProgress = async (id, itemId) => {
        await apollo.mutate({
          mutation: addPlaylistProgressMutation,
          variables: {
            playlistId: id,
            itemId,
          },
        });
      };

      const markAsCompleted = async () => {
        if (playlistUuid) {
          await addProgress(playlistUuid, stepUuid);
        } else {
          await addProgress(`challenge,${challengeUuid}`, stepUuid);
        }
        await router.go(-1);
      };

      const { open } = useInAppBrowser();

      return {
        doing,
        markAsCompleted,
        open,
        showChat,
        user,
      };
    },
  });
</script>

<template>
  <fc-page v-if="doing">
    <template #top>
      <fc-nav-bar>
        <template v-if="user" #append>
          <template v-if="showChat">
            <router-link v-slot="{ navigate }" :to="{ name: 'chat' }" custom>
              <chat-icon class="chat-icon" @click.native="navigate" />
            </router-link>
          </template>
        </template>

        {{ $t('title') }}
      </fc-nav-bar>
    </template>

    <fc-page-content :class="{ [doing.tag]: true }">
      <div v-if="doing.coverImageUrl" class="image">
        <img :src="doing.coverImageUrl" :alt="`Image for ${doing.title}`" />
      </div>

      <default-spacer s />

      <section>
        <jum-heading h3 bold>
          {{ doing.title }}
        </jum-heading>
      </section>

      <section v-if="doing.what.length">
        <jum-heading h4 bold>
          {{ $t('whatTitle') }}
        </jum-heading>

        <ol>
          <li v-for="step in doing.what" :key="step">
            <p class="newline">
              {{ step }}
            </p>
          </li>
        </ol>
      </section>

      <section v-if="doing.why">
        <jum-heading h4 bold>
          {{ $t('whyTitle') }}
        </jum-heading>

        <p class="newline">
          {{ doing.why }}
        </p>
      </section>

      <section v-if="doing.links.length">
        <jum-heading h4 bold>
          {{ $t('howTitle') }}
        </jum-heading>

        <link-card-list :links="doing.links" />
      </section>

      <fc-page-content-breakout>
        <fc-action-bar>
          <template v-if="doing.action">
            <template v-if="doing.action.external">
              <jum-button @click="open(doing.action.external)">
                {{ $t('actionLabel') }}
              </jum-button>
            </template>

            <template v-else>
              <router-link v-slot="{ navigate }" :to="doing.action" custom>
                <jum-button @click="navigate">
                  {{ $t('actionLabel') }}
                </jum-button>
              </router-link>
            </template>
          </template>

          <jum-button secondary @click="markAsCompleted">
            {{ $t('doneLabel') }}
          </jum-button>
        </fc-action-bar>
      </fc-page-content-breakout>
    </fc-page-content>
  </fc-page>
</template>

<style scoped lang="scss">
  section {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 45px;
    }
  }

  .image {
    overflow: hidden;

    img {
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .body {
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 1rem * 1.3 !important;
  }

  .h-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    z-index: 1;

    > * {
      margin: 0;
    }
  }

  .newline {
    white-space: pre-line;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding-left: 30px;

    li {
      position: relative;

      &::before {
        align-items: center;
        background-color: #000;
        border-radius: 50%;
        color: #fff;
        content: counter(list-item);
        display: inline-flex;
        font-size: 0.9rem;
        font-weight: 600;
        height: 20px;
        justify-content: center;
        left: -30px;
        margin-right: 10px;
        position: absolute;
        top: 2px;
        width: 20px;
      }
    }
  }

  .perform {
    ol {
      li {
        &::before {
          background-color: #f94d3f;
        }
      }
    }
  }

  .recover {
    ol {
      li {
        &::before {
          background-color: #ffb736;
        }
      }
    }
  }

  .protect {
    ol {
      li {
        &::before {
          background-color: #0d8933;
        }
      }
    }
  }

  .coach {
    ol {
      li {
        &::before {
          background-color: #908ac0;
        }
      }
    }
  }

  .specials {
    ol {
      li {
        &::before {
          background-color: #11aeed;
        }
      }
    }
  }
</style>

<i18n>
{
  "en": {
    "title": "Challenge details",
    "whatTitle": "What to do",
    "whyTitle": "Why",
    "howTitle": "How to do it",
    "actionLabel": "Let's start",
    "doneLabel": "I'm done"
  },
  "nl": {
    "title": "Uitdaging details",
    "whatTitle": "What to do",
    "whyTitle": "Why",
    "howTitle": "How to do it",
    "actionLabel": "Let's start",
    "doneLabel": "I'm done"
  }
}
</i18n>
