import progressBarExplanation from '@/modules/planner/messages/nl/progressBarExplanation.nl';
import activityHoursExplanation from './activityHoursExplanation.nl';
import buttons from './buttons.nl';
import counts from './counts.nl';
import fitnessExplanation from './fitnessExplanation.nl';
import trainingImportanceExplanation from './trainingImportanceExplanation.nl';
import carbsPerHourExplanation from './carbsPerHourExplanation.nl.js';
import headings from './headings.nl';
import info from './info.nl';
import labels from './labels.nl';
import links from './links.nl';
import misc from './misc.nl';
import modals from './modals.nl';
import networkErrors from './networkErrors.nl';
import notifications from './notifications.nl';
import placeholders from './placeholders.nl';
import titles from './titles.nl';
import trainingTypes from './trainingTypes.nl';

export default {
  activityHoursExplanation,
  carbsPerHourExplanation,
  fitnessExplanation,
  progressBarExplanation,
  placeholders,
  trainingTypes,
  trainingImportanceExplanation,
  buttons,
  counts,
  headings,
  info,
  labels,
  links,
  modals,
  networkErrors,
  notifications,
  titles,
  misc,
};
