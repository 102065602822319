import { Network } from '@capacitor/network';
import { GraphQLError } from 'graphql';

export default async () => {
  try {
    const { connected, connectionType } = await Network.getStatus();
    return { connected, connectionType, __typename: 'NetworkStatus' };
  } catch {
    throw new GraphQLError('Failed to read network status from device');
  }
};
