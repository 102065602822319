import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
import { Chart, DoughnutController, ArcElement } from 'chart.js';
import App from '@/App';
import { installModules } from '@/installModules';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { defineCustomElement as defineCustomElementIonContent } from '@ionic/core/components/ion-content';
import { defineCustomElement as defineCustomElementIonRefresher } from '@ionic/core/components/ion-refresher';
import { defineCustomElement as defineCustomElementIonRefresherContent } from '@ionic/core/components/ion-refresher-content';
import { initialize as initializeIonic } from '@ionic/core/components';
import * as Sentry from '@sentry/vue';
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { register as registerSwiper } from 'swiper/element/bundle';
import PortalVue from 'portal-vue';

Vue.use(PortalVue);
Vue.use(VueYouTubeEmbed);

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.config.ignoredElements = [/ion-\w*/];
initializeIonic();
defineCustomElementIonContent();
defineCustomElementIonRefresher();
defineCustomElementIonRefresherContent();
registerSwiper();

Chart.register(DoughnutController, ArcElement);

import('swiper/swiper-bundle.css');
import('@/styles/global.scss');

Vue.config.productionTip = false;

const { routerModule, i18nModule, apolloModule } = installModules(Vue);
const { router } = routerModule;

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV_NAME,
    release: `foodcoach-app@${process.env.PACKAGE_VERSION}`,
    ignoreErrors: ['Avoided redundant navigation to current location'],
    integrations: [Sentry.browserProfilingIntegration()],
    tracesSampleRate: 0.25,
    tracePropagationTargets: ['localhost', 'theathletesfoodcoach.com', 'blancofoodcoach.com'],
  });
}

if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Purchases')) {
  let revenueCatApiKey = null;

  if (Capacitor.getPlatform() === 'ios' && process.env.VUE_APP_REVENUECAT_API_KEY_IOS) {
    revenueCatApiKey = process.env.VUE_APP_REVENUECAT_API_KEY_IOS;
  } else if (
    Capacitor.getPlatform() === 'android' &&
    process.env.VUE_APP_REVENUECAT_API_KEY_ANDROID
  ) {
    revenueCatApiKey = process.env.VUE_APP_REVENUECAT_API_KEY_ANDROID;
  }

  if (revenueCatApiKey === null) {
    console.error('RevenueCat API Key not found');
  } else {
    console.debug(
      `RevenueCat API Key found, configuring SDK... (${process.env.VUE_APP_REVENUECAT_API_KEY})`
    );

    const configure = async () => {
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: revenueCatApiKey,
      });
    };

    configure()
      .then(() => {
        console.log('RevenueCat SDK configured!');
      })
      .catch(console.error);
  }
}

new Vue({
  router,
  i18n: i18nModule.i18n,
  apolloProvider: apolloModule.apolloProvider,
  render: h => h(App),
}).$mount('#app');

defineCustomElements(window).catch(console.error);
