export default {
  connectDevice: {
    feedback: {
      title: 'Help us to improve',
      text: 'FoodCoach is still in beta phase. With your feedback we can improve our services.',
      cta: 'Share feedback',
    },
    planned: 'Planned',
    adjusted: 'Adjusted',
  },
  sportUSPs: [
    'Personal performance nutrition plan',
    'A daily plan which matches your training',
    'Make simple improvements to your meals',
    'Enjoy a tasty, varied diet',
    'Perform better, faster and stronger',
  ],
  privacyStatementExplanation:
    "Stay up-to-date with the latest developments from the Athlete’s FoodCoach <span class='light'>(optional)</span>.",
  approveJoinHeading: 'Automatic JOIN training plan data import',
  approveJoinStatement:
    'I approve that FoodCoach will automatically import my latest training plan data from the JOIN cycling app.',
  manageSubscription:
    'All subscription related actions (like payment method and cancellation) can be found in Apple App Store or Google Play Store.',
  lifetimeSubscription: 'With a free subscription you will have access to all premium features.',
  subscriptionWillRenew: 'Will automatically be renewed on {date}.',
  whichDatabase: 'Which product database should I add?',
  whichDatabasePopover: [
    'By default the FoodCoach ingredient database, trusted by elite sports teams, is selected.',
    'You can add additional product data bases to make  food logging more convenient in certain regions. If your add a local data base you can now also log products available on this market by using the search bar of barcode scanner',
    "To manage your product databases later, go to 'Profile' > 'App Settings' > 'Product Database.'",
    "<strong>Open Food Facts</strong>\nOpen Food Facts is a global, community-driven database with over 3 million products. It emphasizes data accuracy and completeness. We've tested it with our FoodCoaches and Premium Members, confirming its reliability",
    '<strong>I want to add another database, how to do so?</strong>\nAt the moment we’ve started with product databases covering products in The Netherlands, United Kingdom and the Dutch area of Belgium. Other databases could be added later.',
  ],
  whichWorkType: [
    'The type and duration of your work significantly affect your daily energy requirements.',
    'Professional athletes consider sports their primary job.',
    'If you spend the day at a desk, opt for a sedentary lifestyle.',
    'Working in a grocery or convenience store, standing all day, is mildly active.',
    'A postman cycling and walking to deliver mail is considered quite active.',
    'Construction workers and gardeners have very active jobs.',
  ],
  whichGenderPopover: [
    "In the formula for energy calculation, gender is considered as a factor. If you do not identify with either male or female, we recommend choosing the option that aligns with your preference in the energy calculation. Please be aware that selecting 'male' results in a higher energy requirement compared to choosing 'female.' If you are unsure about the best choice for you, feel free to contact our Food Coaches.",
  ],
  whichWeightGoal: [
    'Align your weight goal with each phase of your training program, leveraging the systematic approach of periodization. This means coordinating your weight management strategy with the different stages of your training cycle, such as base training, strength training, race season and taper phase to fuel your performance.',
    '<strong>Maintain weight</strong> \n Optimal for sustaining health and performance year-round.',
    "<strong>Smart weight loss</strong> \n Gradually reduce weight before or during base training. Don't apply during race season and always stop at least one week before your big event. Our app supports a loss of 0.25kg or 0.5kg per week to preserve muscle, maintain performance, and promote sustainable weight management.",
    '<strong>Smart weight gain</strong> \n Opt for gradual weight gain or muscle-building approaches during periods where increasing muscle mass and strength are prioritized, such as the off-season or strength training phases. Our app supports a weight gain of roughly 0.25kg or 0.5kg per week, to support muscle growth while minimizing fat gain and enhancing overall performance.',
    '<strong>Get pro support</strong> \n If you hesitate which goal you should choose, you can always connect with a certified FoodCoach. Our coaches provide personalized consultation on your weight goal settings and can further personalize your weekly targets.',
  ],
  whichNutritionViewPopover: [
    '<strong>Core Performance</strong><br>Focus on the fundamental macronutrients essential for training and recovery: carbs and protein. Nutrition is a broad topic, and focusing on all aspects can be overwhelming. The core performance view is perfect for those who want to concentrate on the key elements that fuel performance and support recovery, ensuring you stay focused on what matters most for your athletic goals.',
    '<strong>Advanced Performance</strong><br>Track all nutritional details, including calories, carbs, protein, and fat. The advanced performance view is ideal for athletes who need detailed nutritional information for broader analysis, executing complex nutritional strategies (smart weight loss, body composition, other) and dealing with new situations (injuries, training camps, etc).',
    "<strong>Should I keep the same view throughout the year?</strong><br>You don't have to stick with the same view throughout the year. Depending on your training phase and competition schedule you can choose a different view.",
  ],
  smartWeightGain: [
    'When it comes to weight-gain, there is no quick fix. At FoodCoach we believe and stand behind the concept of smart weight gain, that is sustainable and supports your health.',
    '<strong>Which weekly target should I choose?</strong> \n Our app supports a weight gain of roughly 0.5kg per week, to support muscle growth while minimizing fat gain and enhancing overall performance. If you wish to gain weight at a slower rate, you can choose the target of +0.25kg per week.',
    '<strong>What if I want to gain weight faster?</strong> \n As a default app setting, this does not fit with the concept of smart weight gain. In consultation and guidance from a certified FoodCoach, it is possible to see whether there is still untapped potential for weight loss in your individual case.',
    '<strong>When do I see results?</strong> \n Responses to weight-gain approaches are highly individual and depend on physical characteristics, behavior and environmental factors. Setting a weight target and the associated calorie plus does not always lead to immediate and exact results. It is wise to monitor your process to reach your weight-gain goals.',
    "<strong>Get pro support</strong> \n If you hesitate which target you should go for, when you don’t see results after a while or when you feel you need a different weight-gain setting, don't hesitate to seek help from a certified FoodCoach. Our coaches provide personalized consultation on your weight goal settings and can further personalize your weekly targets.",
  ],
};
