export default {
  aboutThisApp: 'Over deze app',
  activityLevel: 'Activiteitenniveau',
  carbsPerHour: 'Hoeveel koolhydraten heb ik nodig?',
  dateOfBirth: 'Geboortedatum',
  developer: 'Features',
  editRecipe: 'Foodcoach je eigen recept',
  exactMeasurement: 'Kan ik deze ingrediënten afronden?',
  fitnessOverview: 'Welke intensiteit moet ik kiezen?',
  ftpValueExplanation: 'Meer weten over je FTP waarde?',
  gender: 'Geslacht',
  length: 'Lengte',
  myAccount: 'Mijn account',
  myFtpValue: 'Mijn FTP-waarde',
  myGoal: 'Mijn doel',
  myInfo: 'Mijn gegevens',
  mySport: 'Mijn sport',
  myEnergyCalculation: 'Mijn energieberekening',
  nutrientsInfo: 'Hoe komen deze voedingswaarden tot stand?',
  onActivityExplanation: 'Nooit meer een hongerklop?',
  progressBarExplanation: 'Hoe werkt deze grafiek?',
  save: 'Opslaan',
  sleepingHours: 'Slaapuren',
  sportSettings: 'Mijn sportinstellingen',
  toHappyMany: 'Pas mijn doel aan',
  trainingIntensity: 'Meer weten over je trainingsintensiteit?',
  weight: 'Gewicht',
  workingHours: 'Werkuren',
  workType: 'Type werk',
};
