<script>
  import { computed, defineComponent, onBeforeMount, ref } from 'vue';
  import { JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import LockedMedal from '@/modules/home/components/medals/LockedMedal.vue';
  import { useAsyncQuery } from '@/modules/shared/utils/apollo.util';
  import MEDALS_QUERY from '@/graphql/queries/medals.query.graphql';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import qs from 'qs';
  import { Medal } from '@/util/gamification/medals';
  import CardSkeletonLoader from '@/components/card-skeleton-loader/CardSkeletonLoader.vue';

  export default defineComponent({
    name: 'MyMedalsSection',
    components: {
      CardSkeletonLoader,
      JumIcon,
      LockedMedal,
      DefaultSpacer,
      JumHeading,
    },
    props: {
      sport: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const isLoading = ref(true);
      const medals = ref([]);
      const achievedMedals = computed(
        () => medals.value?.filter(medal => medal.currentLevel.name !== 'grey') ?? []
      );
      const totalMedals = computed(() => achievedMedals.value.length);

      onBeforeMount(async () => {
        const query = qs.stringify({
          populate: 'deep,4',
          filters: {
            sport: {
              type: {
                $eq: props.sport,
              },
            },
          },
        });
        const { result } = await useAsyncQuery(MEDALS_QUERY, null, 0, 'no-cache');
        const response = await http.get(`/medals?${query}`);
        medals.value = response.data.data.map(item =>
          Medal.createFromStrapiResponse(item, result.value?.medals ?? [])
        );
        isLoading.value = false;
      });

      const viewMedals = computed(() => achievedMedals.value.slice(0, 4));

      return {
        totalMedals,
        medals,
        viewMedals,
        isLoading,
      };
    },
  });
</script>

<template>
  <section class="my-medals">
    <header>
      <jum-heading h3 bold>
        {{ $t('title') }} <small>({{ totalMedals }})</small>
      </jum-heading>

      <router-link :to="{ name: 'my-medals', params: { sport } }">
        <span>{{ $t('link') }}</span>
        <jum-icon icon="jum-chevron-right" size="16" />
      </router-link>
    </header>

    <default-spacer s />

    <template v-if="!isLoading">
      <div class="highlighted-medals">
        <router-link
          v-for="medal in viewMedals"
          :key="medal.uuid"
          :to="{ name: 'medal-detail', params: { uuid: medal.uuid } }"
        >
          <img
            class="medal"
            :src="medal.currentLevel.imageUrl"
            :alt="`${medal.currentLevel.name} medal for ${medal.title}`"
          />
        </router-link>
        <locked-medal v-for="i in Array(4 - viewMedals.length)" :key="i" />
      </div>
    </template>
    <template v-else>
      <card-skeleton-loader :height="101" />
    </template>
  </section>
</template>

<style scoped lang="scss">
  section.my-medals {
    header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      a {
        align-items: center;
        display: flex;
        font-weight: 300;
        gap: 3px;
        text-decoration: none;

        svg {
          margin-top: 2px;
        }
      }
    }
  }

  :deep(h3) {
    small {
      color: #9e9e9e;
      font-weight: 300;
    }
  }

  .highlighted-medals {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    padding: 15px;

    .locked-medal {
      min-width: 64px;
    }

    @media (min-width: 286px) and (max-width: 365px) {
      a,
      .locked-medal {
        &:nth-child(4) {
          display: none;
        }
      }
    }
  }

  .medal {
    height: 64px;
    width: 64px;
  }
</style>

<i18n>
{
  "en": {
    "title": "My Medals",
    "link": "All Medals"
  },
  "nl": {
    "title": "Mijn medailles",
    "link": "Alle medailles"
  }
}
</i18n>
