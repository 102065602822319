import buttons from './buttons.nl';
import faqs from './faqs.nl';
import headings from './headings.nl';
import info from './info.nl';
import labels from './labels.nl';
import links from './links.nl';

export default {
  buttons,
  faqs,
  headings,
  info,
  links,
  labels,
  medals: {
    title: 'Mijn medailles',
    description:
      'Binnenkort beschikbaar - Verdien medailles om je prestaties bij te houden en te tonen.',
    readMore: 'Lees meer',
    popover: {
      title: 'Mijn medailles - Binnenkort beschikbaar',
      description: [
        "Bij The Athlete's FoodCoach geloven we dat het echte verschil in prestatie zit in je dagelijkse voeding 🍏",
        "De medailles ondersteunen je reis door het complexe onderwerp voeding op te splitsen in specifieke prestatiethema's. Deze aanpak helpt jou je te concentreren op uitvoerbare strategieën voor korte-, middellange- en langetermijndoelen, waardoor jouw voedingsreis een avontuur wordt.",
        'Binnenkort kun je je reis naar een hoger niveau tillen met de sensatie van het verdienen en presenteren van je welverdiende medailles 🚀',
      ],
    },
  },
};
