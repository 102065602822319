export default {
  add: 'Add',
  addIngredients: 'Add ingredients',
  addMissingIngredient: 'Add missing ingredient',
  addMissingProduct: 'Add missing product',
  addOwnIngredient: 'Add own product',
  addOwnProduct: 'Add own product',
  addProduct: 'Add a product',
  addProductsOrRecipes: 'Add products or recipes',
  addToGroceryList: 'Plan meal',
  addTraining: 'Add training',
  backToRecipe: 'Back to recipe',
  calculateEnergyNeed: 'Calculate energy needs',
  cancelDelete: 'No, cancel',
  close: 'Close',
  confirmAdvancedFilters: 'Show results',
  confirmDeleteProductFromRecipe: 'Yes, delete this product',
  deleteProductFromRecipe: 'Are you sure you want to remove this product?',
  deleteProductFromPlan: 'Are you sure you want to remove this product from your plan?',
  deleteProduct: 'Delete product',
  deleteRecipe: 'Are you sure you want to remove this recipe?',
  deleteRecipeWarning: 'The recipe will be removed from your plan. This cannot be undone.',
  confirmDeleteRecipe: 'Yes, delete this recipe',
  cancelDeleteRecipe: 'No, cancel',
  deleteWarning: 'This cannot be undone',
  done: "I'm done",
  edit: 'Change',
  editInfo: 'Change data',
  editRecipeData: 'Change name and/or moments',
  editProduct: 'Edit product',
  editRecipe: 'Change recipe',
  letsGo: "Let's go",
  next: 'Next',
  nextStep: 'Next step',
  planMeals: 'Plan my meals',
  planYourOwnRecipe: 'Tune your meal',
  removeFromGroceryList: 'Delete recipe',
  removeProduct: 'Remove product',
  removeProductFromPlan: 'Remove product from plan',
  scanCode: 'Scan barcode',
  scanAgain: 'Scan again',
  save: 'Save',
  saveYourMeal: 'Save your meal',
  saveIngredient: 'Save product',
  select: 'Select',
  selectProduct: 'Select product',
  send: 'Send',
  showLess: 'Show less',
  showMore: 'Show more',
  stepByStep: 'Cooking focus mode',
  updateGroceryList: 'Change',
  updateProduct: 'Update product',
  viewAll: 'Show everything',
  viewRecipeDetails: 'View recipe details',
  openSettings: 'Open settings',
  tryAgain: 'Try again',
};
