export default {
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  weekDay: {
    weekday: 'long',
  },
  dayMonth: {
    day: 'numeric',
    month: 'long',
  },
  weekDayShort: {
    weekday: 'short',
  },
  weekDayNarrow: {
    weekday: 'narrow',
  },
  month: {
    month: 'long',
  },
  dayMonthNumber: {
    day: 'numeric',
  },
  dayMonthShort: {
    day: 'numeric',
    month: 'short',
  },
};
