import { required } from 'vee-validate/dist/rules.umd';
import {
  minimumLength,
  validDate,
} from '@/modules/core/plugins/veeValidateExtend/validators/utils.validator';
import { isDecimal, toDecimal } from '@/modules/shared/utils/decimal.util';

const generalRules = [
  {
    name: 'general_required',
    message: 'core.inputErrors.required.default',
    rule: required,
  },
  {
    name: 'general_date_invalid',
    message: 'core.inputErrors.invalid.date',
    rule: {
      validate: validDate,
    },
  },
  {
    name: 'general_number',
    message: 'core.inputErrors.invalid.number',
    rule: {
      validate: value => typeof value === 'number' && Number.isFinite(value),
    },
  },
  {
    name: 'general_decimal',
    message: 'core.inputErrors.invalid.decimal',
    rule: {
      validate: isDecimal,
    },
  },
  {
    name: 'general_decimal_max_precision',
    message: 'core.inputErrors.invalid.maxPrecision',
    rule: {
      params: ['maxPrecision'],
      validate: (value, { maxPrecision = 0 }) =>
        isDecimal && toDecimal(value).decimalPlaces() <= maxPrecision,
    },
  },
  {
    name: 'general_greater_than',
    message: 'core.inputErrors.invalid.greaterThan',
    rule: {
      params: ['greaterThanValue'],
      validate: (value, { greaterThanValue = 0 }) =>
        isDecimal(value) && toDecimal(value).gt(greaterThanValue),
    },
  },
  {
    name: 'general_greater_than_or_equals',
    message: 'core.inputErrors.invalid.greaterThanOrEquals',
    rule: {
      params: ['greaterThanOrEqualsValue'],
      validate: (value, { greaterThanOrEqualsValue = 0 }) =>
        isDecimal(value) && toDecimal(value).gte(greaterThanOrEqualsValue),
    },
  },
  {
    name: 'general_smaller_than',
    message: 'core.inputErrors.invalid.smallerThan',
    rule: {
      params: ['smallerThanValue'],
      validate: (value, { smallerThanValue = 0 }) =>
        isDecimal(value) && toDecimal(value).lt(smallerThanValue),
    },
  },
  {
    name: 'general_smaller_than_or_equals',
    message: 'core.inputErrors.invalid.smallerThanOrEquals',
    rule: {
      params: ['smallerThanOrEqualsValue'],
      validate: (value, { smallerThanOrEqualsValue = 0 }) =>
        isDecimal(value) && toDecimal(value).lte(smallerThanOrEqualsValue),
    },
  },
  {
    name: 'general_max_length',
    message: 'core.inputErrors.invalid.maxLength',
    rule: {
      params: ['maxLengthValue'],
      validate: (value, { maxLengthValue = 150 }) => value.length <= maxLengthValue,
    },
  },
  {
    name: 'general_min_length',
    message: 'core.inputErrors.invalid.minLength',
    rule: {
      params: ['minLengthValue'],
      validate: minimumLength,
    },
  },
];

export default generalRules;
