export default {
  title: "The Athlete's FoodCoach Terms and Conditions",
  list: {
    type: 'ol',
    items: [
      {
        text: '{0}',
        references: [{ type: 'bold', text: "The Athlete's FoodCoach " }],
        list: {
          type: 'ul',
          items: [
            {
              text: "These general terms and conditions (hereinafter referred to as “{0}”), together with the Privacy Statement, apply to The Athlete's FoodCoach (hereinafter referred to as“{1}”).",
              references: [
                { type: 'bold', text: 'Terms and Conditions' },
                { type: 'bold', text: 'FoodCoach' },
              ],
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Participation' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'By participating in FoodCoach you agree to these Terms and Conditions',
            },
            {
              text: "To enjoy all the benefits of FoodCoach, such as receiving a personal nutrition plan, you must register for FoodCoach. This can be done by downloading The Athlete's FoodCoach app (hereinafter referred to as “{0}”) via the Apple Store or via Google Play. The app falls under the definition of “{1}”.",
              references: [
                { type: 'bold', text: 'App' },
                { type: 'bold', text: 'Applications' },
              ],
            },
            {
              text: 'To participate in FoodCoach you must be a natural person aged 18 or older. By participating in FoodCoach you declare that you have complied with this.  ',
            },
            {
              text: 'You guarantee that all information entered in your profile is complete, correct and up-to-date.              ',
            },
            {
              text: "The personal data entered will be stored in the FoodCoach database. The personal information you provide to us is processed in accordance with the applicable privacy legislation. You can read more about this in The Athlete's FoodCoach Privacy & Cookie Statement.            ",
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Usage FoodCoach        ' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'In FoodCoach you will create training sessions for the sport you practice. With the training schedule and your health data your daily energy balance is calculated. From your energy balance a nutrition plan is calculated. This nutrition plan is divided over the day (breakfast, lunch, dinner, snacks and eating during exercise). We translate the necessary ingredients into products.              ',
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: '        Control and abuse        ' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'FoodCoach reserves the right to check the use of FoodCoach by participants for compliance with the terms and conditions.              ',
            },
            {
              text: 'FoodCoach may refuse, withdraw or cancel the issuance of the FoodCoach account in any form and (at any time) remove a participant from FoodCoach if there are reasonable grounds to believe that:              ',
              list: {
                type: 'ol',
                items: [
                  {
                    text: 'abuse or attempted abuse of FoodCoach;',
                  },
                  {
                    text: 'any violation or attempted violation of these Terms and Conditions;                    ',
                  },
                  {
                    text: 'any behavior related to FoodCoach that involves theft, misconduct, offensive behavior or the provision of incorrect or misleading information.                    ',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Liability' }],
        list: {
          type: 'ol',
          items: [
            {
              text: 'Although FoodCoach strives for the utmost care with regard to FoodCoach and the App, it cannot guarantee that they will not contain defects or imperfections.',
            },
            {
              text: 'FoodCoach is in no way liable for unauthorized use of your FoodCoach account by third parties.',
            },
            {
              text: 'FoodCoach is not liable for damage of any nature whatsoever resulting from or related to participation in FoodCoach, including but not limited to illness or a negative influence on the (sports) performance of the participant that would be the result of using the Food Coach. Participation in FoodCoach is therefore at your own risk.              ',
            },
            {
              text: 'You can choose to connect to a dietitian and/or nutritionist via the FoodCoach. After contact with the dietician and/or nutritionist has been made, the further process with the dietician and/or nutritionist falls outside the scope of the FoodCoach. FoodCoach is not liable for damage of any nature whatsoever resulting from or related to the process with the dietician and/or nutritionist.',
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Intellectual property' }],
        list: {
          type: 'ol',
          items: [
            {
              text: 'FoodCoach reserves all rights not expressly granted to you. Your use of the Applications does not constitute a transfer of any intellectual property rights from FoodCoach to you. You are only granted a non-transferable, non-exclusive, non-sublicensable, limited license to use the FoodCoach for the purposes specified on the Applications.',
            },
            {
              text: 'You acknowledge that all (intellectual property) rights with regard to the Applications, including but not limited to the intellectual property rights with regard to the texts, photos, videos, recipes and software, are vested exclusively in FoodCoach or its licensors.',
            },
            {
              text: 'The (parts of the) Applications may not be reproduced or made public (in whole or in part) in any way and in any form, unless stipulated otherwise in these General Terms and Conditions and/or insofar as mandatory law stipulates otherwise. It is also not allowed:',
              list: {
                type: 'ol',
                items: [
                  {
                    text: 'retrieve and reuse a substantial portion of the content of the Applications and/or repeatedly and systematically retrieve and reuse non-substantial portions of the content of the Applications; and/or',
                  },
                  {
                    text: 'to use any software and/or hardware tools and/or solutions (in-house or made available by third parties), insofar as these are aimed at taking over any information made accessible via the Applications, or to use the Applications via robots or spider, scrape, search, or otherwise misuse in any way.',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Changes and Termination of Participant' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'You can terminate your participation in FoodCoach at any time by deleting your FoodCoach profile in the app.',
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Changes and Termination of FoodCoach' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'FoodCoach has the right at all times to change or terminate FoodCoach and/or to change these General Terms and Conditions and the Privacy & Cookie Statement, without being obliged to pay damages and/or any other compensation to the participants in any way. A significant change to the General Terms and Conditions and/or the Privacy Statement will be brought to your attention within the Applications.',
            },
            {
              text: 'FoodCoach will inform you at least one (1) month in advance about a significant change or termination of FoodCoach via the email address provided.',
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Other conditions' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'Dutch law applies to these General Terms and Conditions and to FoodCoach. All disputes in connection with this will be submitted to the competent court in the Netherlands.',
            },
          ],
        },
      },
      {
        text: '{0}',
        references: [{ type: 'bold', text: 'Contact' }],
        list: {
          type: 'ul',
          items: [
            {
              text: 'If you have any questions or comments about FoodCoach, please contact us: {0}',
              references: [
                {
                  type: 'link',
                  url: 'mailto:info@theathletesfoodcoach.com',
                  text: 'info@theathletesfoodcoach.com',
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
