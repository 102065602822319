export default {
  profile: 'Profiel',
  myPreferences: 'Mijn voorkeuren',
  myInfo: 'Mijn gegevens',
  nextEvent: 'Mijn volgende',
  privacySettings: 'Privacy instellingen',
  aboutThisApp: 'Over deze app',
  performanceGoal: 'Wat is <strong>jouw prestatiedoel?</strong>',
  language: 'Taal',
  myNutritionPlanMetrics: 'Mijn voedingsplan-metrics',
  appSettings: 'App-instellingen',
  myGoals: 'Mijn doelen',
  mySubscription: 'Mijn abonnement',
  productDatabase: 'Productdatabase',
  bodyMetrics: 'Lichaamsmetingen',
  baselineActivityLevel: 'Basisactiviteitsniveau',
  nutritionView: 'Voedingsweergave',
  sport: 'Sport',
  myPersonalNutritionPlan: 'Mijn persoonlijke voedingsplan',
  myGoal: 'Mijn doel',
};
