import apollo from '@/modules/shared/graphql/apollo.config';
import GROCERY_DATES_QUERY from '@/modules/shared/graphql/queries/groceryDates.query.graphql';

export default async function getGroceryDates() {
  const {
    data: { groceryDates },
  } = await apollo.defaultClient.query({
    query: GROCERY_DATES_QUERY,
  });

  return groceryDates;
}
