import { Keyboard } from '@capacitor/keyboard';

// eslint-disable-next-line no-console
const warn = () => console.warn('Keyboard not available in this environment');

export const setAccessoryBarVisible = {
  async beforeRouteEnter(to, from, next) {
    try {
      await Keyboard.setAccessoryBarVisible({ isVisible: true });
    } catch {
      warn();
    } finally {
      next();
    }
  },
  async beforeRouteLeave(to, from, next) {
    try {
      await Keyboard.setAccessoryBarVisible({ isVisible: false });
    } catch {
      warn();
    } finally {
      next();
    }
  },
};
