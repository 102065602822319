export default {
  activityHoursExplanation: [
    {
      text: 'FoodCoach gives you a professional and personal nutrition plan. The app does this based on your personal factors, such as height, age and weight and your scheduled training sessions. In order to tailor your nutrition plan to your needs, it is therefore important to know how many hours a week you exercise.',
    },
    {
      text: 'Due to the relatively high fat-free mass of athletes, their energy needs are quickly underestimated. Athletes who exercise 10 hours or more per week fall within this group. They therefore need a different calculation of the BMR.',
    },
  ],
};
