<template>
  <div ref="navBar" class="fc-nav-bar">
    <div v-if="backButton" class="back-button">
      <fc-clear-button
        class="back-button__button"
        data-test-id="buttonPreviousPage"
        @click="backButtonNavigation"
      >
        <jum-icon class="back-button__icon" icon="jum-chevron-left" size="24" />
      </fc-clear-button>
    </div>

    <div class="prepend">
      <slot name="prepend"></slot>
    </div>

    <div
      ref="title"
      class="title"
      :style="{ transform: `translate3d(${titleOffsetCorrection * -1}px, 0, 0)` }"
    >
      <jum-heading ref="titleText" h1 styled="h5" bold class="title__text">
        <slot></slot>
      </jum-heading>
    </div>

    <div class="append">
      <slot name="append">
        <jum-button v-if="menuItems.length > 0" tertiary @click="toggleTipMenu($event)">
          <jum-icon icon="jum-dots" size="36" />
        </jum-button>
      </slot>
    </div>
  </div>
</template>

<script>
  import { JumButton, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import FcClearButton from '@/modules/shared/components/clear-button/ClearButton';
  import { androidBackButtonMixin } from '@/modules/shared/mixins/androidBackButton.mixin';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcNavBar',
    components: { JumButton, FcClearButton, JumHeading, JumIcon },
    mixins: [
      androidBackButtonMixin(function () {
        this.backButtonNavigation();
      }),
    ],
    props: {
      backButton: {
        type: [Boolean, String, Object],
        default: true,
      },
      menuItems: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['backButtonClick'],
    data: () => ({
      titleTextWidth: 0,
      titleWidth: 0,
      titleOffset: {},
      showDropdown: false,
    }),
    computed: {
      titleOffsetCorrection() {
        const offsetDifference = this.titleOffset.left - this.titleOffset.right;
        if (this.titleWidth >= this.titleTextWidth) return offsetDifference / 2;
        return 0;
      },
    },
    mounted() {
      this.calculateOffsets();
    },
    methods: {
      toggleTipMenu(event) {
        event.stopPropagation();
        const { bottom } = event.target.getBoundingClientRect();
        this.$tipmenu({
          buttons: this.menuItems,
          y: bottom,
        });
      },
      calculateOffsets() {
        const titleTextEl = this.$refs.titleText.$el;
        const titleEl = this.$refs.title;

        titleTextEl.style.display = 'inline';
        this.titleTextWidth = titleTextEl.offsetWidth;
        titleTextEl.style.display = '';

        this.titleWidth = titleEl.offsetWidth;

        this.titleOffset = {
          left: titleEl.offsetLeft,
          right: this.$refs.navBar.offsetWidth - (titleEl.offsetWidth + titleEl.offsetLeft),
        };
      },
      backButtonNavigation() {
        this.$emit('backButtonClick');

        // Only handle routing when the parent component is not listening to the backButtonClick event
        if (!this.$listeners.backButtonClick) {
          if (this.backButton === true) {
            this.$router.back();
          } else {
            this.$router.replace(this.backButton);
          }
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-nav-bar {
    align-items: center;
    display: flex;
    max-width: 100%;
    min-height: $spacing-xl;
    padding: 0 $spacing-m;

    .prepend,
    .append {
      flex: 0;
      z-index: $z-index-reset;
    }

    .title {
      flex-grow: 1;
      min-width: 0;

      &__text {
        margin-bottom: 0;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .back-button {
      flex: 0;
      z-index: $z-index-router-view;

      &__button {
        display: block;
        margin: $spacing-xs * -3 0 $spacing-xs * -3 $spacing-m * -1;
        padding: $spacing-xs * 3 0 $spacing-xs * 3 $spacing-m;
      }

      &__icon {
        display: block;
      }
    }
  }
</style>
