// GraphQl
// Constants
import CHAT_BUBBLE_SHOWN_MUTATION from '@/modules/shared/graphql/mutations/bubbleShown.mutation.graphql';
import { removeFromCacheByKey } from '@/modules/shared/utils/graphqlCache.util';

export default {
  methods: {
    async reviseChatBubble(bubbleName) {
      await this.$apollo.mutate({
        mutation: CHAT_BUBBLE_SHOWN_MUTATION,
        variables: {
          bubbleName,
        },
        update: cache => removeFromCacheByKey('Bubble', cache),
      });
    },
  },
};
