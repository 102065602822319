<template>
  <renderless-items type="modals">
    <template #default="{ activeModal }">
      <transition v-if="activeModal && activeModal.type === 'modal'" name="fade">
        <div
          v-if="activeModal"
          ref="niceModals"
          class="nice-modals"
          @mousedown.self="mouseDown(activeModal)"
        >
          <component
            :is="activeModal.component || 'NiceModal'"
            class="nice-modals__modal"
            :modal="activeModal"
          />
        </div>
      </transition>
    </template>
  </renderless-items>
</template>

<script>
  import { defineComponent } from 'vue';
  import NiceModal from './NiceModal';

  export default defineComponent({
    name: 'NiceModals',
    components: { NiceModal },
    data: () => ({
      activeModal: null,
    }),
    created() {
      document.addEventListener('mouseup', this.mouseUp);
    },
    beforeDestroy() {
      document.removeEventListener('mouseup', this.mouseUp);
    },
    methods: {
      mouseDown(activeModal) {
        this.activeModal = activeModal;
      },
      mouseUp($event) {
        if (this.activeModal && $event.target === this.$refs.niceModals) {
          this.activeModal.close();
        }

        this.activeModal = null;
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-modals {
    align-items: center;
    backface-visibility: hidden;
    background: rgba($color-black, 0.3);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-page-dialog;

    &__modal {
      flex-grow: 1;
      will-change: bottom, transform;
      z-index: $z-index-page-dialog;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity;
  }

  .fade-enter-active {
    transition: 0.3s;
  }

  .fade-leave-active {
    transition: 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
