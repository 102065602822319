import messages from './messages/nl';
import messagesEn from './messages/en';
import { registerRoutes, TAB_ROUTE } from './routes';

/* eslint-disable import/no-mutable-exports */
export let utils;
export let guards;
export let constants;
/* eslint-enable import/no-mutable-exports */

export const profileModule = (sharedModule, routerModule, i18nModule, guardsModule, proModule) => ({
  install() {
    this.tabRoute = {
      ...TAB_ROUTE,
    };
    utils = { ...sharedModule.utils };
    constants = { ...proModule.constants };

    i18nModule.setTranslations({ profile: { ...messages } }, 'nl');
    i18nModule.setTranslations({ profile: { ...messagesEn } }, 'en');

    registerRoutes(routerModule.router);
  },
});
