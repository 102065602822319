export default {
  buttons: {
    completeAccount: 'Create my account',
  },
  headings: {
    completeYourAccount: `<strong> You’re almost ready, <strong>  one final (boring put important) step`,
    additionalInfo: 'Sign up',
  },
  info: {
    optOutEasily: '',
    healthInformationAsterisk: 'For more information, read ',
  },
  labels: {
    genderQuestion: 'What is your gender?',
    targetedAdvertising:
      "I agree that my data may be collected by The Athlete's FoodCoach and used for marketing activities like emails and special offers {optional}.",
    termsAndConditions: 'I accept {termsAndConditions}',
  },
  modals: {
    returnLogout: {
      title: 'Are you sure?',
      message: 'We will log you out if you go back',
      confirmText: 'Yes, log out',
      cancelText: 'No, cancel',
    },
  },
};
