export default {
  buttons: {
    completeAccount: 'Opslaan en doorgaan',
  },
  headings: {
    completeYourAccount:
      "Wil je een <strong>persoonlijke ervaring</strong> in de The Athlete's FoodCoach app?",
    additionalInfo: 'Persoonlijke ervaring',
  },
  info: {
    optOutEasily:
      'Je kan je overal makkelijk voor afmelden als het niet bevalt (al lijkt ons dat onwaarschijnlijk 😉)',
    healthInformationAsterisk: 'Lees hier meer over in het',
  },
  labels: {
    genderQuestion: 'Ben je een',
    targetedAdvertising:
      "Ik ga ermee akkoord dat mijn gegevens die door het gebruik van de The Athlete's FoodCoach worden verzameld, worden gebruikt voor marketing activiteiten zoals het versturen van e-mails en aanbiedingen {optional}.",
    termsAndConditions: 'Ik ga akkoord met de {termsAndConditions}',
  },
  modals: {
    returnLogout: {
      title: 'Weet je het zeker?',
      message: 'Als je teruggaat word je uitgelogd.',
      confirmText: 'Ja, log mij uit',
      cancelText: 'Nee, toch niet',
    },
  },
};
