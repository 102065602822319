export default {
  birthDateSaved: 'Je geboortedatum is opgeslagen',
  dailyEnergyNeedSaved: 'Jouw dagelijkse energiebehoefte is opgeslagen',
  eatingOnActivityPlanned: 'Je eten tijdens je training is ingepland',
  ftpSaved: 'Je FTP-waarde is opgeslagen',
  heightSaved: 'Je lengte is opgeslagen',
  mealPlanned: 'Je {mealMoment} is ingepland',
  mealRemoved: 'Je {mealMoment} is verwijderd',
  sleepingHoursSaved: 'Je gemiddeld aantal slaapuren zijn opgeslagen',
  sportSaved: 'De gekozen sport is opgeslagen',
  userRecipeRemoved: 'Je eigen recept is verwijderd',
  weightSaved: 'Je gewicht is opgeslagen',
  workingHoursSaved: 'Je werkuren zijn opgeslagen',
  workTypeSaved: 'Je type werk is opgeslagen',
  pairActivitySuccess: '"{name}" is gekoppeld aan je training',
  pairActivityFailed:
    'Er ging iets mis bij het koppelen van deze activiteit. Probeer het later opnieuw',
  unpairActivitySuccess: '"{name}" is ontkoppeld van je training',
  unpairActivityFailed:
    'Er ging iets mis bij het ontkoppelen van deze activiteit. Probeer het later opnieuw',
  deleteActivitySuccess: '"{name}" is succesvol verwijderd',
  deleteActivityFailed:
    'Er ging iets mis bij het verwijderen van deze activiteit. Probeer het later opnieuw',
  trainingPeaksSyncSuccess: 'Trainingsschema van TrainingPeaks voor vandaag gesynchroniseerd.',
  trainingPeaksSyncFailed: 'Synchronisatie van trainingsschema mislukt. Probeer het later opnieuw.',
};
