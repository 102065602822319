export default {
  title: 'PRIVACY & COOKIE STATEMENT',
  subtitle: "The Athlete's FoodCoach",
  content: [
    {
      title: 'Privacy statement',
      paragraphs: [
        {
          text: "Via jouw deelname aan The Athlete's FoodCoach (hierna: “{0}”) verzamelt The Athlete's FoodCoach persoonsgegevens over jou (hierna: “{1}”). In dit Privacy Statement wordt beschreven welke Informatie Jumbo over jou verzamelt en voor welke doelen deze Informatie wordt gebruikt.",
          references: [
            { type: 'bold', text: "The Athlete's FoodCoach" },
            { type: 'bold', text: 'Informatie' },
          ],
        },
      ],
      sections: [
        {
          title: '1. Wie is FoodCoach?',
          paragraphs: [
            {
              text: 'Blanco FoodCoach B.V. (“{0}”) ”) levert gepersonaliseerd voedingsadvies via een FoodCoach app en gecertificeerde professionele FoodCoaches. We werken samen met topsporters en prestatieteams (bijvoorbeeld Team Visma | Lease a Bike, PSV Eindhoven). Blanco FoodCoach is verantwoordelijk voor het verzamelen, opslaan en gebruiken van uw gegevens.',
              references: [{ type: 'bold', text: 'FoodCoach' }],
            },
            {
              text: "Blanco FoodCoach B.V., KvK 12345678, Het Zuiderkruis 23, 5215 MV 's-Hertogenbosch",
            },
            {
              text: 'The contact details of the data protection officer: Robert Jan Koens - info@theathletesfoodcoach.com',
            },
          ],
        },
        {
          title:
            "2. Welke Informatie wordt via The Athlete's FoodCoach verzameld en voor welke doeleinden?",
          sections: [
            {
              title: 'Registratie Mijn Jumbo account',
              paragraphs: [
                {
                  text: "Om mee te kunnen doen aan The Athlete's FoodCoach moet je over een Mijn Jumbo account beschikken. Voor zover je hier nog niet over beschikt vragen we je eerst een Mijn Jumbo account aan te maken.",
                },
                {
                  text: 'Wanneer je het Mijn Jumbo Account creëert, wordt aan je gevraagd bepaalde Informatie te verstrekken. Je bent niet verplicht alle Informatie te verstrekken. Het verstrekken van een geldig e-mailadres, wachtwoord, voor- en achternaam, postcode, huisnummer en telefoonnummer is verplicht. De Informatie die je verstrekt kun je altijd aanpassen.',
                },
                {
                  text: 'De verstrekte Informatie gebruiken we, naast de verder in dit Privacy Statement besproken doeleinden, in de eerste plaats om het Mijn Jumbo Account te kunnen aanmaken en je de daarbij behorende functionaliteiten te kunnen leveren. Dit gebruik van jouw Informatie is noodzakelijk voor de uitvoering van een overeenkomst waarbij jij partij bent met betrekking tot je gebruik van de diensten die Jumbo jou aanbiedt.',
                },
                {
                  text: 'De Informatie die je opgeeft bij het aanmaken van het Mijn Jumbo Account alsmede de Informatie die je daarna aan het Mijn Jumbo Account toevoegt of via andere functionaliteiten verstrekt, wordt centraal opgeslagen in de database van Jumbo en aan het Mijn Jumbo Account gekoppeld.',
                },
              ],
            },
            {
              title: "Gebruik The Athlete's FoodCoach – Algemene module",
              paragraphs: [
                {
                  text: "De The Athlete's FoodCoach biedt de volgende functionaliteiten: (i) een receptendatabase, (ii) de ‘Wisselaar’ en (iii) de ‘Verras me functionaliteit’.",
                },
                {
                  text: "(i) Met behulp van de The Athlete's FoodCoach heb je toegang tot een enorme receptendatabase. Hierin vind je allerlei lekkere en gezonde recepten.",
                },
                {
                  text: "(ii) Een andere functionaliteit van de The Athlete's FoodCoach is de Wisselaar. De Wisselaar ondersteunt jou in het maken van gezondere eetwissels.",
                },
                {
                  text: '(iii) Met behulp van de Verras me functionaliteit worden er maaltijdsuggesties gedaan voor een weekmenu.',
                },
              ],
            },
            {
              title: "Gebruik The Athlete's FoodCoach – Sport module",
              paragraphs: [
                {
                  text: "Nadat je bent aangemeld voor de The Athlete's FoodCoach, kan je ervoor kiezen om de The Athlete's FoodCoach Sport module te activeren. Via de The Athlete's FoodCoach Sport module kan je je eigen, persoonlijke, voedingsplan op maat samenstellen om meer resultaat uit het sporten te halen. Deze module kan je vinden onder Profiel -> Mijn voorkeuren -> Sportprestaties verbeteren. ",
                },
              ],
            },
            {
              title: 'Bijzondere persoonsgegevens',
              paragraphs: [
                {
                  text: "Door jouw gebruik van de The Athlete's FoodCoach Sport module verwerkt Jumbo direct dan wel indirect enkele bijzondere persoonsgegevens van jou. Voorbeelden hiervan zijn gezondheidsgegevens (lengte, gewicht, FTP-waarde). Bij de activatie van de The Athlete's FoodCoach Sport module geef je toestemming voor de verwerking van deze bijzondere persoonsgegevens door Jumbo. Jumbo zal de bijzondere persoonsgegevens voor geen ander doel verwerken dan voor het gebruik van de The Athlete's FoodCoach Sport module",
                },
              ],
            },
            {
              title: 'Bestellen',
              paragraphs: [
                {
                  text: "In de The Athlete's FoodCoach Sport module ga je trainingen voor de door jou beoefende sport aanmaken. Met behulp van een trainingsschema en jouw gezondheidsgegevens wordt de energie berekend die je gaat verbruiken. Vervolgens wordt jouw energiebehoefte berekend en een persoonlijk voedingsplan opgesteld. Dit voedingsplan is verdeeld over de dag (ontbijt, lunch, diner, snacks en eten tijdens het sporten). De producten waar de maaltijden uit bestaan kun je op je boodschappenlijstje plaatsen, waarna je de mogelijkheid hebt om de boodschappen via {0} te bestellen of om een winkel te bezoeken. Je kunt het boodschappenlijstje ook meenemen naar de winkel. ",
                  references: [
                    {
                      type: 'link',
                      text: 'www.jumbo.com',
                      url: 'https://www.jumbo.com',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '3. Met wie deelt Jumbo jouw Informatie?',
          paragraphs: [
            {
              text: "Jumbo verstrekt alleen Informatie aan derden wanneer dat noodzakelijk is voor de uitvoering van The Athlete's FoodCoach en zorgvuldig gebeurt, namelijk bedrijven die de e-mailcommunicatie van The Athlete's FoodCoach verzorgen, pushberichten versturen in de app van The Athlete's FoodCoach, de analyses uitvoeren met betrekking tot jouw energiebehoefte en voedingsschema. Met deze derden heeft Jumbo een verwerkersovereenkomst gesloten. Verder kan Jumbo jouw Informatie  aan derden verstrekken indien dit verplicht is op basis van de geldende privacywetgeving. Jumbo verstrekt Informatie bijvoorbeeld wanneer dat noodzakelijk is om te voldoen aan een wettelijke verplichting of gerechtvaardigd belang van Jumbo.",
            },
          ],
        },
        {
          title: '4. Bewaren van Informatie',
          paragraphs: [
            {
              text: "Jumbo zal Informatie over jou niet langer bewaren dan noodzakelijk is voor het verwerkelijken van de doeleinden waarvoor de Informatie is verkregen. Nadat je je hebt uitgeschreven voor The Athlete's FoodCoach wordt jouw Informatie binnen 6 maanden verwijderd of geanonimiseerd. Indien je The Athlete's FoodCoach gedurende een periode van 2 jaar niet hebt gebruikt, wordt jouw Informatie na 2 jaar automatisch verwijderd. In andere gevallen wordt je Informatie bewaard zolang je geregistreerd bent, tenzij wetgeving zich daartegen verzet.",
            },
          ],
        },
        {
          title: '5. Bescherming van jouw Informatie',
          paragraphs: [
            {
              text: 'Jumbo hecht veel waarde aan een goede beveiliging van jouw Informatie. Jumbo gebruikt passende technische en organisatorische maatregelen om jouw Informatie te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking.',
            },
          ],
        },
        {
          title: '6. Wat zijn je rechten?',
          paragraphs: [
            {
              text: 'Als je wilt weten welke Informatie Jumbo over jou verzamelt, kun je Jumbo vragen om een overzicht te sturen van deze Informatie. Om er zeker van te zijn dat de Informatie die je opvraagt ook echt bij jou hoort, kunnen we je vragen om een kopie van een geldig legitimatiebewijs mee te zenden. We verzoeken je om het BSN onleesbaar te maken.',
            },
            {
              text: 'Als je op basis van het overzicht jouw persoonsgegevens wilt laten wijzigen, laten verwijderen, af laten schermen of de verwerking door Jumbo wilt laten beperken, kun je daartoe een nieuw verzoek sturen aan het hierboven vermelde adres.  Ook heb je in sommige gevallen het recht om bezwaar te maken of om jouw gegevens in een geschikte vorm overgedragen te krijgen. Waar de verwerking van je persoonsgegevens is gebaseerd op je toestemming, heb je het recht je toestemming op elk moment in te trekken. De intrekking van toestemming heeft geen invloed op de rechtmatigheid van verwerking op basis van toestemming voordat deze is ingetrokken.',
            },
            {
              text: 'We proberen zo snel mogelijk en in ieder geval binnen een maand op je verzoeken te reageren. De mogelijkheid bestaat echter dat we meer tijd nodig hebben. Indien dat het geval is, zullen we je hierover voor afloop van de hierboven bedoelde maand informeren. De extra tijd zal niet meer dan twee maanden bedragen.',
            },
          ],
        },
        {
          title: '7. Wijzigingen in dit Privacy Statement',
          paragraphs: [
            {
              text: 'Dit Privacy Statement kan worden gewijzigd. In geval van significante wijzigingen die je in aanmerkelijke mate kunnen treffen, streven wij ernaar je daarover direct te informeren. De laatste versie van het Privacy Statement is van 8 februari 2022.',
            },
          ],
        },
        {
          title: '8. Vragen?',
          paragraphs: [
            {
              text: 'Mocht je nog vragen hebben over dit Privacy Statement dan kun je een bericht sturen naar {0}.',
              references: [
                {
                  type: 'link',
                  text: 'serviceformulier',
                  url: 'mailto:info@theathletesfoodcoach.com.nl',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'COOKIE STATEMENT',
      paragraphs: [
        {
          text: "Dit Cookie Statement beschrijft van welke cookies Jumbo gebruik maakt via de app van The Athlete's FoodCoach (hierna: de “App”) en waarvoor deze cookies worden gebruikt. We raden je aan om dit Cookie Statement aandachtig door te nemen.",
        },
      ],
      sections: [
        {
          title: '1. Wat zijn Cookies?',
          paragraphs: [
            {
              text: 'Cookies zijn kleine stukjes (tekst)informatie die bij het bezoek van de App worden meegestuurd aan jouw browser en vervolgens op de harde schijf of in het geheugen van jouw computer, tablet of mobiele telefoon (hierna: “Apparaat”) worden opgeslagen. De browser kan deze informatie bij een volgend bezoek aan de App terugsturen. De cookies die via de App worden geplaatst, kunnen jouw Apparaat of de bestanden die daarop opgeslagen staan niet beschadigen.',
            },
            {
              text: '{0}',
              references: [
                {
                  type: 'underline',
                  text: 'Welke cookies hanteert Jumbo en voor welk doel?',
                },
              ],
            },
          ],
        },
        {
          title: '2. Functionele cookies',
          paragraphs: [
            {
              text: 'Wij maken in de eerste plaats gebruik van functionele cookies. Deze cookies zijn noodzakelijk om aan jou de diensten en functionaliteiten te kunnen leveren waar je om hebt gevraagd. Zo onthouden functionele cookies bijvoorbeeld de informatie die je in (bestel)formulieren opneemt en hoef je na het inloggen niet bij elk nieuw bezoek in de App opnieuw in te loggen. Het is niet mogelijk dat je je afmeldt voor functionele cookies. De functionele cookies blijven gedurende een periode van twee (2) jaar na plaatsing actief.',
            },
          ],
        },
        {
          title: '2. Functionele cookies',
          paragraphs: [
            {
              text: 'Wij maken in de eerste plaats gebruik van functionele cookies. Deze cookies zijn noodzakelijk om aan jou de diensten en functionaliteiten te kunnen leveren waar je om hebt gevraagd. Zo onthouden functionele cookies bijvoorbeeld de informatie die je in (bestel)formulieren opneemt en hoef je na het inloggen niet bij elk nieuw bezoek in de App opnieuw in te loggen. Het is niet mogelijk dat je je afmeldt voor functionele cookies. De functionele cookies blijven gedurende een periode van twee (2) jaar na plaatsing actief.',
            },
          ],
        },
        {
          title: '3. Analytics cookies',
          paragraphs: [
            {
              text: 'Voor het verzamelen van web statistieken over het gebruik en bezoek van de App maakt Jumbo gebruik van Google Analytics-cookies. Deze cookie registreert jouw gebruik van de App. Deze gegevens worden vervolgens door Google geanalyseerd en de resultaten hiervan worden aan Jumbo verstrekt. Jumbo verkrijgt op deze wijze inzicht in de wijze waarop de App wordt gebruikt en kan aan de hand van die gegevens zo nodig aanpassingen in de App en/of haar dienstverlening doorvoeren. In dit kader heeft Jumbo met Google een verwerkersovereenkomst gesloten. Verder wordt het laatste octet van jouw IP-adres dat aan Google wordt toegezonden gemaskeerd en is de optie ‘gegevens delen’ binnen de Google Analytics omgeving uit gezet. Tot slot maakt Jumbo geen gebruik van andere Google-diensten in combinatie met Google analytics cookies, tenzij je daarvoor toestemming hebt verleend.',
            },
          ],
        },
        {
          title: '4. Profiling ',
          paragraphs: [
            {
              text: `Jumbo maakt op haar website gebruikt van re-targeting/re-marketing technologieën van advertentienetwerken waaronder Facebook, Google Adwords en DoubleClick. Het doel van deze technologieën is om je interessante en op maat gemaakte aanbevelingen te kunnen doen.
              Via de App wordt een cookie op jouw apparaat geplaatst met een uniek nummer. Deze cookie maakt het mogelijk om jouw apparaat te herkennen wanneer je na een bezoek aan de App naar een andere website surft, die onderdeel uitmaakt van het advertentienetwerk. Dit maakt het mogelijk voor Jumbo om jou op die andere website een advertentie van Jumbo te tonen.
              De gegevens die met behulp van Google Analytics worden verzameld, worden naast de doeleinden als vermeld onder de paragraaf “Analytics cookies”, tevens gebruikt voor het tonen van advertenties op maat indien je bij de cookie voorkeuren hebt aangegeven dat je ook profiling cookies wil ontvangen. De Google Analytics re-marketing cookie blijft gedurende een periode van twee (2) jaar na plaatsing actief.`,
            },
          ],
        },
        {
          title: '5. Cookies van derden',
          paragraphs: [
            {
              text: `Via de App worden cookies geplaatst die afkomstig zijn van derde partijen. Zo worden bij het tonen van video’s die afkomstig zijn van websites van derden, waaronder YouTube, door deze derde partijen cookies geplaatst die het mogelijk maken om de video’s aan jou te tonen. Daarnaast kunnen er cookies worden geplaatst die worden gebruikt om je surfgedrag over meerdere websites te volgen en een profiel van jouw surfgedrag op te bouwen.`,
            },
            {
              text: 'Op het gebruik van de cookies die via de video’s in de App worden geplaatst, zijn nadere voorwaarden van YouTube van toepassing. Jumbo accepteert geen verantwoordelijkheid en aansprakelijkheid voor deze voorwaarden.  Voor meer informatie verwijst Jumbo je naar de voorwaarden van YouTube.',
            },
          ],
        },
        {
          title: '6. Verwijderen cookies',
          paragraphs: [
            {
              text: 'De toestemming die jij hebt gegeven aan Jumbo voor het plaatsen en uitlezen van cookies, kun je te allen tijde intrekken door jouw browser zo in te stellen dat deze geen cookies accepteert en/of door alle in je browser reeds geplaatste cookies te verwijderen.',
            },
          ],
        },
        {
          title: '7. Wijzigingen',
          paragraphs: [
            {
              text: 'Dit Cookie Statement kan worden gewijzigd. De laatste versie van het Cookie Statement is van 13 maart 2020.',
            },
          ],
        },
        {
          title: '8. Vragen',
          paragraphs: [
            {
              text: `Mocht je nog vragen hebben over dit Cookie Statement dan kun je een bericht sturen middels het {0}. Verder kun je, onder meer, aanvullende informatie vinden over het gebruik van cookies op de website van de Consumentenbond.`,
              references: [
                {
                  type: 'link',
                  text: 'service formulier',
                  url: 'http://hallo.jumbo.com/klantenservice/stel-een-vraag/algemeen',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
