import { configService } from '@/config.service';
import { actionMapping } from '@/util/gamification/actions';

const { strapiImageUrl } = configService;

export const mapChallengeItemToLink = item => {
  const tag = {
    type: item.tag,
    text: item.tag,
  };

  if (item.tag === 'learning' && item.blog?.data?.attributes) {
    return {
      title: item.blog.data.attributes.title,
      url: item.blog?.data?.attributes?.url,
      img: `${strapiImageUrl}${item.blog.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: true,
      tag,
      uuid: item.blog.data.attributes.uuid,
      completed: false,
    };
  }

  if (item.tag === 'doing' && item.action?.data?.attributes) {
    return {
      title: item.action?.data?.attributes?.title ?? '',
      url: item?.action?.data?.attributes?.type
        ? actionMapping.get(item.action.data.attributes.type)
        : null,
      img: `${strapiImageUrl}${item.action?.data?.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: false,
      tag,
      uuid: item.uuid ?? null,
      completed: false,
    };
  }

  return null;
};

const mapPlaylistItemToLink = playlistId => item => {
  const tag = {
    type: item.tag,
    text: item.tag,
  };

  if (item.tag === 'video' && item?.video?.data?.attributes) {
    return {
      title: item.video.data.attributes.title,
      url: item.video
        ? {
            name: 'video-detail',
            params: {
              slug: playlistId,
              videoSlug: item.video.data.attributes.uuid,
            },
          }
        : null,
      img: `${strapiImageUrl}${item.video.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: false,
      tag,
      uuid: item.video.data.attributes.uuid,
      completed: false,
    };
  }

  if (item.tag === 'challenge' && item?.challenge?.data?.attributes) {
    return {
      title: item.challenge.data.attributes.title,
      url: item.challenge
        ? {
            name: 'challenge-detail',
            params: {
              slug: playlistId,
              challengeSlug: item.challenge.data.attributes.uuid,
            },
          }
        : null,
      img: `${strapiImageUrl}${item.challenge.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: false,
      tag,
      uuid: item.challenge.data.attributes.uuid,
      completed: false,
    };
  }

  return null;
};

const getSubtitleForPlaylist = playlist => {
  if (playlist.attributes.subtitle) {
    return playlist.attributes.subtitle?.trim();
  }

  const challengeCount = playlist.attributes.phases.reduce(
    (acc, phase) => acc + phase.items.length,
    0
  );

  const label = challengeCount === 1 ? 'challenge' : 'challenges';

  return `${challengeCount} ${label}`;
};

const getMeta = item => {
  if (item?.tag === 'video' && item?.video?.data?.attributes) {
    return item.video.data.attributes;
  }

  if (item?.tag === 'challenge' && item?.challenge?.data?.attributes) {
    const medalTypes = ['bronzeMedal', 'silverMedal', 'goldMedal'];
    const medalNames = ['BRONZE', 'SILVER', 'GOLD'];

    return {
      ...item.challenge.data.attributes,
      steps: item?.challenge?.data.attributes.steps.map(step => ({
        ...step,
        links: step.items.map(mapChallengeItemToLink),
        givesMedal:
          medalNames[medalTypes.findIndex(type => item?.challenge?.data?.attributes?.[type])],
        completedCount() {
          return step.links.filter(link => link.completed).length;
        },
      })),
    };
  }

  return null;
};

export const mapPlaylist = playlist => ({
  uuid: playlist.attributes.uuid,
  slug: playlist.attributes.uuid,
  title: playlist.attributes.title?.trim(),
  subtitle: getSubtitleForPlaylist(playlist),
  challengeCount: playlist.attributes.phases.reduce((acc, phase) => acc + phase.items.length, 0),
  body: playlist.attributes.description?.trim(),
  phases: playlist.attributes.phases.map(phase => ({
    title: phase.title?.trim(),
    subtitle: phase.subtitle?.trim(),
    items: phase.items.map(item => ({
      tag: item.tag,
      meta: getMeta(item),
    })),
    links: phase.items.map(mapPlaylistItemToLink(playlist.attributes.uuid)),
  })),
  img: `${strapiImageUrl}${playlist.attributes.cardImage.data.attributes.url}`,
  benefits: playlist.attributes.benefits.map(benefit => benefit.text?.trim()),
  showExtraSupport: playlist.attributes.showExtraSupport,
});
