import { getItem, setItem } from '@/modules/shared/services/storage.service';

const STATE = {
  show: 'show',
  triggered: 'triggered',
};

export const useBasketCoachMark = () => {
  const enableCoachMark = async () => {
    const basketCoachMark = await getItem('basket_coach_mark');
    if (!basketCoachMark) {
      await setItem('basket_coach_mark', STATE.show);
    }
  };

  const shouldShowCoachMark = async () => {
    const state = await getItem('basket_coach_mark');
    if (state === STATE.show) {
      await setItem('basket_coach_mark', STATE.triggered);
      return true;
    }
    return false;
  };

  return {
    shouldShowCoachMark,
    enableCoachMark,
  };
};
