import { useVueProxy } from '@/composables/useVueProxy';

export const useNotification = () => {
  const { notificationy } = useVueProxy();
  const notify = options => notificationy(options);
  const notifySuccess = message =>
    notify({
      message,
      icon: 'jum-check',
      iconColor: '#0fc647',
    });
  const notifyFailed = message =>
    notify({
      message,
      icon: 'jum-cross',
      iconColor: '#e90000',
    });

  return {
    notify,
    notifySuccess,
    notifyFailed,
  };
};
