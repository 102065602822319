import { Browser } from '@capacitor/browser';

export const useInAppBrowser = () => {
  const open = async url => {
    await Browser.open({
      presentationStyle: 'popover',
      url,
    });
  };

  return {
    open,
  };
};
