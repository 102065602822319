export default {
  aboutThisApp: 'Over deze app',
  activityLevel: 'Je activiteitenniveau',
  searchProductsRecipes: 'Zoek producten of recepten',
  searchProducts: 'Zoek producten',
  addIngredients: 'Ingrediënten toevoegen',
  addRecipes: 'Ingrediënten toevoegen',
  addTraining: 'Training toevoegen',
  buffetOptions: 'Buffet opties',
  createIngredient: 'Voeg eigen product toe',
  editIngredient: 'Wijzig eigen product',
  customRecipe: 'Eigen recept',
  customRecipeDetails: 'Gegevens van je recept',
  customRecipeIntroduction: 'Hoe het werkt',
  customRecipeOptions: 'Eigen recept opties',
  dailyEnergyNeed: 'Je dagelijkse energiebehoefte',
  dateOfBirth: 'Geboortedatum',
  developers: 'Developers',
  editTraining: 'Training wijzigen',
  FAQs: 'Veelgestelde vragen',
  ftpValue: 'Je conditieniveau',
  ingredients: 'Ingrediënten',
  product: 'Product',
  jumboFoodcoachRecipes: "The Athlete's FoodCoach recepten",
  length: 'Je lengte',
  macroNutrientsRatio: `<strong>Macronutriëntverhouding</strong> van je recept`,
  mealPlannerOverview: 'Mijn plan',
  myAccount: 'Mijn account',
  myEnergyCalculation: 'Mijn energieberekening',
  myGoal: 'Mijn doel',
  myInfo: 'Mijn gegevens',
  mySport: 'Mijn sport',
  profile: 'Profiel',
  recipe: 'Recept',
  sleepingHours: 'Je slaapuren',
  sportSettings: 'Mijn sportinstellingen',
  searchResults: 'Zoekresultaten',
  trainingSchedule: 'Trainingsschema',
  userRecipes: 'Eigen recepten',
  weight: 'Je gewicht',
  workingHours: 'Je werkuren',
  workType: 'Je type werk',
  yourSport: 'Jouw sport',
  changeIngredientName: 'Verander naam van ingrediënt',
};
