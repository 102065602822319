export default {
  personalData:
    'We maken gebruik van <strong>je lichaamsmetingen</strong> en <strong>je basisactiviteitsniveau</strong> om je persoonlijke voedingsplan te berekenen.',
  chooseYourSport: `Wat is <strong>je hoofdsport?</strong>`,
  dailyEnergyNeed: `Je <strong>gemiddelde energiebehoefte</strong>`,
  ftpValueExplanation: 'Meer weten over je FTP waarde?',
  energyCalculation:
    'Welke <strong>berekeningsmethode voor het rustmetabolisme</strong> moeten we gebruiken voor jou?',
  fitnessExplanation: 'Welke intensiteit moet ik kiezen?',
  gender: 'Wat is je <strong>geslacht?</strong>',
  birthdate: 'Wat is je <strong>geboortedatum?</strong>',
  height: 'Wat is je <strong>lengte?</strong>',
  weight: 'Wat is je <strong>gewicht?</strong>',
  workType: 'Wat voor <strong>type werk</strong> heb je?',
  workingHours: 'Hoeveel <strong>uur werk</strong> je per dag?',
  activityLevel: 'Hoe <strong>actief</strong> ben je in je <strong>vrije tijd?</strong>',
  sleepingHours: 'Hoeveel <strong>uur slaap</strong> je per nacht?',
  creatingPlan: 'We maken nu je voedingsplan',
  planCreated: 'Je voedingsplan is klaar',
  weightGoal: 'Wat is <strong>je gewichtsdoel?</strong>',
  nutritionView: '<strong>Welke weergave</strong> wil je voor het bijhouden van je voeding?',
};
