import LOGIN_MUTATION from '@/modules/shared/auth/graphql/mutations/login.mutation.graphql';
import REGISTER_MUTATION from '@/modules/shared/auth/graphql/mutations/register.mutation.graphql';
import LOGOUT_MUTATION from '@/modules/shared/auth/graphql/mutations/logout.mutation.graphql';
import { LOGOUT_FAILED } from '@/modules/shared/constants/auth.const';
import apollo from '@/modules/shared/graphql/apollo.config';
import { SESSION_KEYS } from '@/modules/shared/constants/storage.const';
import { removeItem } from '@/modules/shared/services/storage.service';
import { DEFAULT_LANGUAGE } from '@/modules/shared/constants/profile.const';

export const register = (
  username,
  password,
  givenName,
  middleName,
  familyName,
  preferredLanguage = DEFAULT_LANGUAGE
) =>
  apollo.defaultClient.mutate({
    mutation: REGISTER_MUTATION,
    variables: {
      username,
      password,
      givenName,
      middleName,
      familyName,
      preferredLanguage,
    },
  });

export const login = (username, password) =>
  apollo.defaultClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      username,
      password,
    },
  });

export const logout = async () => {
  try {
    await apollo.defaultClient.mutate({ mutation: LOGOUT_MUTATION });
    await removeItem(SESSION_KEYS.GROCERY_DATES);
    await apollo.defaultClient.cache.reset();
  } catch (error) {
    throw new Error(LOGOUT_FAILED);
  }
};
