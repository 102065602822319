import { useAsyncMutation } from '@/modules/shared/utils/apollo.util';
import ADD_ITEM_TO_DAY_PLAN_MUTATION from '@/modules/planner/graphql/mutations/addItemToDayPlan.mutation.graphql';
import DAY_PLAN_QUERY from '@/modules/planner/graphql/queries/dayPlan.query.graphql';
import REMOVE_ITEM_FROM_DAY_PLAN_MUTATION from '@/modules/planner/graphql/mutations/removeItemFromDayPlan.mutation.graphql';
import { ScaledMealMapper } from '@blancofoodcoach/content-scaling';
import Decimal from 'decimal.js';
import { useMoment } from '@/modules/shared/composables/useMoment';

export default {
  apollo: {
    selectedRecipe: {
      query: DAY_PLAN_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          date: this.selectedDate,
        };
      },
      update({ dayPlan }) {
        if (dayPlan) {
          const mealPlan = dayPlan.meals.find(
            meal =>
              meal.moment === this.moment &&
              (this.trainingId === meal.trainingId || meal.trainingId === undefined)
          );
          const items =
            mealPlan?.items.map(item => {
              const ingredients = item.ingredients?.map(ingredient => ({
                ...ingredient,
                id: ingredient.ingredientId,
                factor:
                  item.itemOverrides
                    ?.filter(value => value.id === ingredient.ingredientId)
                    .map(value => value.factor)
                    .pop() || new Decimal(1),
              }));
              return {
                ...item,
                ingredients,
              };
            }) || [];

          const scaledRecipe = ScaledMealMapper.instance
            .fromMealPlan({
              ...mealPlan,
              items,
            })
            .recipes.find(recipe => recipe.id === this.recipeId);

          if (scaledRecipe) {
            return { ...scaledRecipe, note: mealPlan?.note };
          }

          return undefined;
        }

        return undefined;
      },
    },
  },
  data: () => ({
    chooseRecipeLoading: false,
  }),
  setup() {
    const { momentWithTrainingId, moment, trainingId } = useMoment();
    return {
      momentWithTrainingId,
      moment,
      trainingId,
    };
  },
  methods: {
    /**
     * Choose (insert), update or remove the current recipe choice.
     * This function handles the following situations:
     *  - When the current recipe is not chosen and the _removeRecipe_ parameter is false'ish, it will insert a new recipe choice.
     *  - When the current recipe is chosen and the _removeRecipe_ parameter is false'ish, it will update the recipe choice.
     *  - When the current recipe is chosen and the _removeRecipe_ parameter is true'ish, it will remove the recipe choice.
     * @param deleteRecipe Boolean - Flag to determine of the current recipe should be removed.
     */
    async mutateRecipe(deleteRecipe = false, factor = 1) {
      this.chooseRecipeLoading = true;
      // Mutually exclusive flags for the current operation (insert, update or delete of recipe choice)
      const isInsert = !deleteRecipe;
      const isUpdate = this.isChosen && !deleteRecipe;
      const isDelete = !isInsert && !isUpdate;

      try {
        if (deleteRecipe) {
          await useAsyncMutation(REMOVE_ITEM_FROM_DAY_PLAN_MUTATION, {
            date: this.selectedDate,
            moment: this.moment,
            trainingId: this.trainingId,
            itemIds: [this.recipeId],
          });
        } else {
          await useAsyncMutation(ADD_ITEM_TO_DAY_PLAN_MUTATION, {
            date: this.selectedDate,
            moment: this.moment,
            trainingId: this.trainingId,
            itemId: this.recipeId,
            itemType: this.recipeType,
            factor,
          });
        }
        this.$emit('change');

        const notificationMessage = isDelete
          ? 'training.notifications.mealRemoved'
          : 'training.notifications.mealPlanned';

        this.$notificationy({
          message: this.$t(notificationMessage, {
            mealMoment: this.$t(`planner.labels.moments.${this.moment}`).toLowerCase(),
          }),
          icon: 'jum-check',
          iconColor: '#0fc647',
        });

        // On recipe choice insertion or deletion redirect to meal planner
        if (isInsert || isDelete) {
          // Implemented in MealDetailPage component
          await this.redirectToMealPlanner();
        }

        // After mutation refetch recipe choices
        await this.$apollo.queries.selectedRecipe.refetch();
      } catch (err) {
        this.$notificationy(this.generateErrorMessage());
      } finally {
        this.chooseRecipeLoading = false;
      }
    },
  },
  computed: {
    /**
     * Determine if the current visited recipe is chosen or not.
     */
    isChosen: ({ selectedRecipe }) => !!selectedRecipe,
  },
};
