import { useRoute } from 'vue-router/composables';

export function useMoment(providedRoute = null) {
  const route = providedRoute || useRoute();

  const { moment: momentWithTrainingId } = route.params;

  const trainingId =
    momentWithTrainingId && momentWithTrainingId.includes('-')
      ? momentWithTrainingId.split('-')[1]
      : null;
  const moment =
    momentWithTrainingId && momentWithTrainingId.includes('-')
      ? momentWithTrainingId.split('-')[0]
      : momentWithTrainingId;

  return {
    momentWithTrainingId,
    trainingId,
    moment,
  };
}
