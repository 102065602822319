export default {
  app: 'App',
  hello: 'Hello',
  integrations: 'Connections',
  energyCalculation:
    'What <strong>calculation method</strong> should we use to calculate your resting energy needs?',
  profile: 'Profile',
  approve: 'Approve',
  bodyMetrics: 'Body Metrics',
  baselineActivityLevel: 'Baseline Activity Level',
  sport: 'Sport',
  subscription: '{type} subscription',
  howToManageMySubscription: 'How to manage my subscription?',
  selectProductDatabase: 'Select your product database',
  whichDatabase: 'Which database should I choose?',
  whatIsYourFtp: 'What is <strong>your FTP?</strong>',
  whichWorkType: 'Which type of work applies to me?',
  whatShouldIChoose: 'What should I choose?',
  whichNutritionView: 'Which view applies best to me?',
  whichWeightGoal: 'Which weight goal should I choose?',
  smartWeightGain: 'What is a smart weight-gain target for me?',
};
