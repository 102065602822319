export default {
  activityLevel: {
    question:
      'When you are not exercising or working, to what extent are you still physically active?',
    explanation:
      'For example, do you spend a lot of time at home, watch television and do your shopping in between or do you walk the dog? Then you are average active. You are quite active when you spend hours behind the bar in the sports canteen. And do you spend hours doing odd jobs or gardening in your spare time? Then you are very active.',
  },
  addFirstTraining: 'Ready to ad your first training?',
  blogs:
    'Do you want to have enough energy around sports, get your resistance in order and recover well? We inspire and coach you.',
  customRecipeIntroductionUSPs: [
    'Enter your own recipe and the ingredients',
    'Play around with the ratio of the ingredients to make your recipe more suitable for your nutritional plan',
    'Schedule your recipe. We save it for you so that you can reschedule the recipe on other days.',
  ],
  dailyEnergyNeed: {
    explanation:
      'Great, we determined your profile on a rest day! We know what your average energy needs are and can give you a direction on how many carbohydrates, proteins and fats you need.',
    edit: 'You can check this information and always change it in your profile.',
  },
  emptyLockedMoment: 'No ingredients or recipes were added at this moment, and you have locked it',
  doesNotMatchMealPlan:
    'Note: the macronutrient ratio of your recipe does not fit within your nutrition plan. {editRecipe} before inserting it.',
  favoriteRecipes: {
    emptyTitle: 'You have not yet added a favorite {moment}',
    emptyParagraph: 'Tap the heart next to the recipe to mark it as a favourite',
  },
  ftpExplanationIntro:
    'The most reliable way to measure your FTP is to do an exercise test with an exercise physiologist or sports doctor. An alternative method is to measure it yourself. Smart trainers with power measurement often offer standard 20-minute FTP tests or work on standard tests of software programs like Zwift.',
  ftpExplanation: [
    {
      text: 'You can also perform the 20-minute test outdoors, provided your bike has a power meter. Try to do this test under favorable conditions: on a flat course (or one long steady climb of max 6%) and preferably without bends and without too much traffic. After a good warm-up, you cycle as hard as possible for 20 minutes at a constant pace. From the average power you pedal in those 20 minutes, subtract 5% to estimate your FTP.',
    },
    {
      text: 'With the above tests you can measure your FTP quite accurately. Please note that the estimated FTP of FoodCoach may be an exaggerated or incorrect estimate. As a result, the energy calculation may be incorrect.',
    },

    {
      text: 'You can also look up your FTP via activity trackers or connected apps. The same applies to the estimated FTP  of FoodCoach: this is less accurate than an exercise test with an exercise physiologist or sports physician or when you perform the test yourself.',
    },
  ],
  ftpExplanationEstimation:
    'Based on your data, we have made an initial, conservative estimate of your FTP .',
  ftpExplanationQuestion: 'Do you know your FTP? Then you can adjust it yourself above.',
  ftpValue: {
    explanation:
      "Just like with the riders of Team Visma | Lease a Bike, we determine how intensive an effort is for you based on your FTP. Don't know your FTP? Then we will help you. We have made an initial, conservative estimate of your FTP.",
    edit: 'When your FTP changes, you can easily adjust it in your profile.',
  },
  length:
    "To be able to coach you properly, we first calculate what your body needs in energy if you don't exercise. For this we need some information from you. Start with your height.",
  myGoal:
    'Eat healthier? Adjust your goal here. Healthier eating habits for more energy: plan a weekly menu, make food changes and be more conscious about food.',
  noMatchingIngredients: 'Add you missing ingredient here',
  noMatchingProducts: 'Add you missing product here',
  onActivityExplanation: [
    {
      text: 'Sore muscles, dizziness and a blur in his eyes. It\'s the symptoms when Wout van Aert has a bonk. "You want to, but you feel completely empty. You ask things of your body that it no longer has the energy for," says the Team Visma | Lease a Bike rider.',
    },
    {
      text: "When you're hungry, you've completely depleted your carbohydrate stores: your body's favorite fuel for high-intensity exercise. Your body is forced to switch to your fat burning. A much less fast fuel, so you can no longer keep cycling at the same pace. \"Your body enters a kind of state of emergency,\" says Martijn Redegeld, sports dietician at Team Visma | Lease a Bike.",
    },
    {
      text: "The Athlete's FoodCoach helps you prevent hunger pangs by ensuring that you fill your carbohydrate stores (in your liver and muscles) to the maximum with your meals before training. The Athlete's FoodCoach also advises what carbohydrates you should consume during your training, so that you do not run out of carbohydrates on your bike.",
    },
    {
      text: 'Are you going to train intensively for a maximum of two hours? Then The Athlete\'s FoodCoach advises you to eat at least 20 grams of carbohydrates per hour. For efforts longer than two hours, the advice is at least 40 grams per hour. Redegeld: "By constantly replenishing your carbohydrates, you prevent the bottom of your fuel tank from being reached and you experience a bonk."',
    },
    {
      text: 'Van Aert: "The Athlete\'s FoodCoach gives me insight into how many carbohydrates I should eat before and during my training. I hold on to that. Nutrition plays an increasingly important role in performing well. For me, training is still number one, but nutrition soon follows. The Athlete\'s FoodCoach makes the difference in this."',
    },
  ],
  planMealsEnabled:
    'Based on your training we have adjusted the portioning and composition of your nutrition plan for today.',
  schedule: {
    explanation: 'Your training has a major impact on what, when and how much you should eat.',
    selectTraining: 'Are you ready to start? Select the date of your first training and add it.',
  },
  searchProductsToAddToRecipe: 'Search for products and add them to your own recipe.',
  sleepingHours: 'How many hours do you sleep each night?',
  sport: {
    hello: `Hey! Glad we got to coach you.`,
    helloName: `Hello`,
    onlyCycling: 'Unfortunately, only cycling is currently supported. Other sports soon followed.',
    sportSelectorInfo:
      'FoodCoach was developed together with the cycling professionals of Team Visma | Lease a Bike. Cycling and running are the first sports supported by FoodCoach. Soccer, hockey and fitnes are also part of the app. Other sports will follow soon.',
  },
  trainingIntensity: 'You can sharpen this wattage yourself.',
  weight: 'Your weight also plays an important role.',
  workingHours: 'How many hours do you work per day?',
  workType: {
    intro:
      'In addition, one job requires more energy from your body than the other job. That is why we would like to know what kind of work you do.',
    explanation:
      'Do you spend a lot of time at your desk? Then you should select Physically passive (sitting down). A teacher does mildy active work. A postman has quite active work and as a road worker you have very active work.',
  },
  changeParticipantsWarning:
    'Note: by changing the number of people, the quantities for ingredients change, your portion as an athlete is {portionPercentage} of this meal.',
  mealOnActivity: {
    intro:
      'Are you going to train for more than an hour? Then it is important that you provide your body with fuel during training.',
    explanation:
      'Select the products you want to take with you during your training. Try to add  products until the bars are filled.',
  },
  mealPlanner: {
    intro: 'We divided your energy needs into multiple meal moments.',
    explanation: 'For every meal moment you can tune your meal or choose a pro meal you like.',
  },
  noTrainingScheduled: 'Are you going to train today?',
  noMatchingRecipes: 'Try to search for another recipe',
  pullToRefresh: {
    pulling: 'Pull to refresh',
    loosing: 'Let go to refresh',
    refresh: 'Loading…',
    success: ' ',
  },
  thankYouForAddingThisIngredient:
    'From now on you can use this ingredient while tuning your meal.\n \n Our team will review your ingredient. If your suggestion is a great addition to our database we will publish the ingredient for al users.',
  productNotFound:
    'We can’t find a product matching with this barcode ({gtin}). Please try again or add this product yourself.',
  multipleProductsFound:
    'We found multiple products matching with this barcode ({gtin}). Please select one.',
  cameraDeniedDescription:
    'The app needs access to the camera to scan a barcode. Go to settings and enable the camera.',
};
