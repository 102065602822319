export default {
  logout: 'Log out',
  deleteFoodcoachAccount: 'Delete FoodCoach account',
  editAccountJumboSite: 'Wijzig je gegevens op jumbo.com',
  resetPassword: 'Rest my password',
  save: 'Save',
  readMore: 'Read more',
  approve: 'Approve',
  reject: 'Reject',
};
