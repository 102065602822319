export default {
  trainingImportanceExplanation: [
    {
      text: 'Classificeer je trainingssessies bij het toevoegen van meerdere sessies op basis van hun belang:',
    },
    {
      type: 'list',
      items: [
        '<strong>Primaire Training:</strong> Dit is je belangrijkste trainingssessie, de kern van je trainingsroutine. Het is het meest cruciale onderdeel van je trainingsplan, gericht op je belangrijkste fitnessdoelen.',
        '<strong>Secundaire Training:</strong> Dit zijn aanvullende sessies die je primaire training aanvullen. Ze ondersteunen je belangrijkste doelen en voegen variëteit toe aan je routine.',
      ],
    },
    {
      text: 'Wanneer slechts één trainingssessie is gepland, wordt deze altijd geclassificeerd als de primaire training.',
    },
    {
      title: 'Waarom is dit belangrijk?',
      text: 'Het aanpassen van je voeding aan je trainingssessies ondersteunt de trainingsefficiëntie en het herstel. Door het "belang van de training" voor elke sessie in te stellen, kun je je workouts prioriteren. Als gevolg hiervan zal de FoodCoach-app automatisch je voedingsplan optimaliseren rond je primaire training om je fitnessdoelstellingen beter te ondersteunen.',
    },
  ],
};
