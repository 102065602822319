<script>
  import { defineComponent } from 'vue';
  import { JumHeading } from '@blancofoodcoach/kompas';

  export default defineComponent({
    name: 'PlaylistCard',
    components: {
      JumHeading,
    },
    props: {
      playlist: {
        type: Object,
        required: true,
      },
    },
  });
</script>

<template>
  <div class="playlist-card" :style="{ 'background-image': `url(${playlist.img})` }">
    <div class="h-group">
      <jum-heading h5 bold class="title">
        {{ playlist.title }}
      </jum-heading>

      <jum-heading h6 class="subtitle">
        {{ playlist.subtitle }}
      </jum-heading>

      <jum-heading h6 class="body">
        {{ playlist.body }}
      </jum-heading>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .playlist-card {
    align-items: end;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    display: inline-flex;
    flex-shrink: 0;
    height: 220px;
    overflow: hidden;
    padding: 16px;
    position: relative;
    scroll-snap-align: start;
    transition: opacity 0.25s ease-in-out;
    width: 280px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &::after {
      background: linear-gradient(180deg, rgba(23, 23, 23, 0) 21.73%, #171717 77.74%);
      content: '';
      inset: 0;
      position: absolute;
    }

    .title {
      font-size: 1rem;
      line-height: 1rem * 1.3;
    }

    .subtitle {
      color: #9e9e9e;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 0.75rem * 1.3;
    }

    .body {
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 0.8rem * 1.3;
    }

    .h-group {
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      z-index: 1;

      > * {
        margin: 0;
      }
    }
  }
</style>
