import { email, required } from 'vee-validate/dist/rules.umd';
import {
  includesOneLowercaseChar,
  includesOneNumber,
  includesOneUppercaseChar,
  includesSpecialSymbols,
} from './utils.validator';

// Auth form rules
const authRules = [
  {
    name: 'auth_username_required',
    message: 'core.inputErrors.required.username',
    rule: required,
  },
  {
    name: 'auth_username_email',
    message: 'core.inputErrors.invalid.username',
    rule: email,
  },
  {
    name: 'auth_password_required',
    message: 'core.inputErrors.required.password',
    rule: required,
  },
  {
    name: 'auth_password_special_characters',
    message: 'core.inputErrors.invalid.specialCharacters',
    rule: {
      validate: includesSpecialSymbols,
    },
  },
  {
    name: 'auth_password_one_uppercase',
    message: 'core.inputErrors.invalid.oneUppercase',
    rule: {
      validate: includesOneUppercaseChar,
    },
  },
  {
    name: 'auth_password_one_lowercase',
    message: 'core.inputErrors.invalid.oneLowercase',
    rule: {
      validate: includesOneLowercaseChar,
    },
  },
  {
    name: 'auth_password_one_number',
    message: 'core.inputErrors.invalid.oneNumber',
    rule: {
      validate: includesOneNumber,
    },
  },
  {
    name: 'auth_given_name_required',
    message: 'core.inputErrors.required.givenName',
    rule: required,
  },
];

export default authRules;
