import { computed } from 'vue';
import { useQuery } from '@/modules/shared/utils/apollo.util';
import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';

export const useUserProfile = () => {
  const { result, loading, refetch } = useQuery(GET_USER_PROFILE_QUERY);
  const user = computed(() => result.value?.getUserProfile?.user);

  return {
    user,
    loading,
    refetch,
  };
};
