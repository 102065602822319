export const WORK_TYPES = {
  UNEMPLOYED: 'training.labels.workTypes.unemployed',
  SEDENTARY: 'training.labels.workTypes.sedentary',
  LIGHT: 'training.labels.workTypes.light',
  MODERATE: 'training.labels.workTypes.moderate',
  HEAVY: 'training.labels.workTypes.heavy',
};

export const ACTIVITY_LEVELS = {
  LIGHT: 'training.labels.activityLevels.light',
  MODERATE: 'training.labels.activityLevels.moderate',
  HEAVY: 'training.labels.activityLevels.heavy',
};

export const LANGUAGES_OPTIONS = [
  {
    label: 'shared.languages.en',
    value: 'en',
  },
  {
    label: 'shared.languages.nl',
    value: 'nl',
  },
];

const FORMULAE = {
  TEN_HAAF: 'tenHaaf',
  HARRIS_BENEDICT: 'harrisBenedict',
};

export const SPORT_LEVELS = {
  AMATEUR: 'amateur',
  FANATIC: 'fanatic',
};

export const AMATEUR_FANATIC_THRESHOLD = 10;

export const SPORTS = {
  CYCLING: 'cycling',
  RUNNING: 'running',
  HOCKEY: 'hockey',
  SOCCER: 'soccer',
  FITNESS: 'fitness',
};

export const TEAMSPORT = 'teamsport';

export const TEAMSPORTS = [SPORTS.HOCKEY, SPORTS.SOCCER];

export const SPORTS_FORMULAE = {
  [SPORTS.CYCLING]: {
    [SPORT_LEVELS.AMATEUR]: FORMULAE.HARRIS_BENEDICT,
    [SPORT_LEVELS.FANATIC]: FORMULAE.TEN_HAAF,
  },
  [SPORTS.RUNNING]: {
    [SPORT_LEVELS.AMATEUR]: FORMULAE.HARRIS_BENEDICT,
    [SPORT_LEVELS.FANATIC]: FORMULAE.TEN_HAAF,
  },
  [SPORTS.HOCKEY]: {
    [SPORT_LEVELS.AMATEUR]: FORMULAE.HARRIS_BENEDICT,
    [SPORT_LEVELS.FANATIC]: FORMULAE.HARRIS_BENEDICT,
  },
  [SPORTS.SOCCER]: {
    [SPORT_LEVELS.AMATEUR]: FORMULAE.HARRIS_BENEDICT,
    [SPORT_LEVELS.FANATIC]: FORMULAE.HARRIS_BENEDICT,
  },
  [SPORTS.FITNESS]: {
    [SPORT_LEVELS.AMATEUR]: FORMULAE.HARRIS_BENEDICT,
    [SPORT_LEVELS.FANATIC]: FORMULAE.TEN_HAAF,
  },
};

export const DEFAULT_LANGUAGE = 'en';
