export default {
  title: 'PRIVACY & COOKIE STATEMENT',
  subtitle: "The Athlete's FoodCoach",
  content: [
    {
      title: 'Privacy statement',
      paragraphs: [
        {
          text: "In connection with your participation in The Athlete's FoodCoach (hereafter: “{0}”) we  will collect personal information of you (hereafter: “{1}”). This Privacy Statement will  explain which Information will be collected and for what purposes we will use the Information. ",
          references: [
            { type: 'bold', text: 'FoodCoach' },
            { type: 'bold', text: 'Information' },
          ],
        },
      ],
      sections: [
        {
          title: '1. Who is FoodCoach?',
          paragraphs: [
            {
              text: 'Blanco FoodCoach B.V. (hereafter: “{0}”) delivers personalized nutrition advice  via an FoodCoach app and certified professional FoodCoaches. We work together with world class athletes and performance teams (e.g., Team Jumbo Visma, PSV Eindhoven). Blanco  FoodCoach is responsible for the collection, storage and usage of your data.',
              references: [{ type: 'bold', text: 'Blanco FoodCoach' }],
            },
            {
              text: "Blanco FoodCoach B.V., KvK 12345678, Het Zuiderkruis 23, 5215 MV 's-Hertogenbosch",
            },
            {
              text: 'The contact details of the data protection officer: Robert Jan Koens - info@theathletesfoodcoach.com',
            },
          ],
        },
        {
          title: 'Which Information is collected via FoodCoach and for which purposes?',
          sections: [
            {
              title: 'Registration FoodCoach account ',
              paragraphs: [
                {
                  text: 'You need an FoodCoach account to participate in FoodCoach. If you don’t have an account yet,  we will ask you to first create a FoodCoach account.',
                },
                {
                  text: 'When creating a FoodCoach account you will be asked to provide some Information. You’re not  obligated to share all Information. Providing a legit email address, password and name is  mandatory. It’s always possible to change your Information. ',
                },
                {
                  text: 'We use the Information provided, apart from the purposes as discussed in this Privacy Statement, to create your account and to deliver the features of our app. This use of your Information is  necessary for the implementation of an agreement in which you are the party in connection to the  usage of the services that Blanco FoodCoach is offering you.',
                },
                {
                  text: 'The Information you added while creating a FoodCoach account and Information you will add  later to your account or is added via other functionalities will be stored centrally in the FoodCoach database. This Information will also be used to make personal suggestions provided you accepted  this while creating your account.',
                },
              ],
            },
            {
              title: 'Usage of FoodCoach',
              paragraphs: [
                {
                  text: 'If you have a FoodCoach account, you will be asked to share additional Information to make use  of the My Plan functionality. This additional Information includes your gender, birthday, the  sport you practice, your length, your weight, how many hours you practice your sport per week,  how many hours per day you work, the type of training you do, the type of work you do, your  activity level, your free time and your FTP (condition level). This Information is required so we  can calculate your personal energy balance and / or your personal performance nutrition plan. Further, the FoodCoach app offers you the option to save your favourite recipes and log Information on your own favourite meals and ingredients. ',
                },
                {
                  text: 'Processing this Information is necessary for the usage of FoodCoach. Furthermore, the FoodCoach app offers you the option to connect to one of our certified  FoodCoaches. If you decide to connect to one of our FoodCoaches, you will be asked to approve  that the FoodCoach assigned to you will also have access to all your Information. This access is  required for your FoodCoach to further personalize and optimize your performance nutrition  plan. Furthermore, at a later stage this connection will also make direct communication via the FoodCoach app possible with your assigned FoodCoach.',
                },
                {
                  text: 'At a later stage you can add on a voluntary basis more Information about your household  composition and your upcoming sport events and goals. Also, you can add Information in your  profile on your eating preferences (e.g. gluten free, vegetarian, vegan, nut allergy, lactose intolerant and other allergies/intolerances). Furthermore, you will on a voluntary basis be able to decide to connect your FoodCoach app to your activity trackers and platforms.',
                },
                {
                  text: 'This in the FoodCoach context gathered Information will be stored in the FoodCoach database.',
                },
              ],
            },
            {
              title: 'Buying behaviour ',
              paragraphs: [
                {
                  text: 'FoodCoach will use your Information to calculate your daily performance nutrition plan that is  adaptive to your training schedule. Subsequently, using the My Plan functionality you can choose  a meal that matches your plan. The product which your meal consists of can be added to a  shopping list. In the shopping list you have the opportunity, if a connected retailer is available in your country (e.g. Jumbo Supermarkets in the Netherlands), to order your groceries online via the  connected retailers website. You can also bring this shopping list to your own store. Information  on your online shopping behaviour can be used to optimize the future FoodCoach shopping lists.',
                },
              ],
            },
            {
              title: 'Special personal Information ',
              paragraphs: [
                {
                  text: 'By participating in FoodCoach, Blanco FoodCoach will process directly of indirectly certain  special personal Information of you. Examples of this are: health Information (length, weight,  energy, FTP-value) and diet preferences as halal. You give Blanco FoodCoach permission to  process this special personal Information. Blanco FoodCoach will not process this data for any  other purpose than the usage of FoodCoach.',
                },
              ],
            },
            {
              title: 'Personalised promotions ',
              paragraphs: [
                {
                  text: 'We gather Information on your usage of FoodCoach and online shopping behaviour to build a  personal profile of you. We will only do this if you have authorized us when creating your  personal account or later if you authorize us via your profile settings. Based on your personal  profile we can present personal promotions in the FoodCoach app or via third party channels on  which promotions of FoodCoach and/or third parties are visible (Facebook, YouTube, etc).',
                },
              ],
            },
          ],
        },
        {
          title: '3. With whom shares Blanco FoodCoach your Information',
          paragraphs: [
            {
              text: 'Blanco FoodCoach will only share your Information with third parties when that is necessary for  the implementation of FoodCoach and this is conducted in a careful way, namely companies that  provide e-mail communication services for Blanco FoodCoach, companies that conduct analyses  regarding your personal nutrition plan and push notification send from the FoodCoach app. With  these third parties Blanco FoodCoach has a concluded a processing agreement. Moreover, Blanco FoodCoach can share your Information to third parties if this obliged based on the applicable  privacy legislation. Blanco FoodCoach will for example share Information that is necessary to  comply to legal obligations or a justified interest of Blanco FoodCoach.',
            },
          ],
        },
        {
          title: '4. Storage of Information ',
          paragraphs: [
            {
              text: 'Blanco FoodCoach will not store Information longer than necessary to realize the purposes for  which the Information is obtained. After you have unsubscribed from FoodCoach your  Information will be deleted or anonymised within 6 months. If you do not use FoodCoach for 2  years, your Information will automatically be removed of anonymized. In other cases, the  Information will be stored as long as you are registered, unless this is not allowed by law.',
            },
          ],
        },
        {
          title: '5. Protection of your Information ',
          paragraphs: [
            {
              text: 'Blanco FoodCoach attaches great importance to the protection of your Information. Blanco  FoodCoach applies appropriate technical and organisational measures to protect your Information  against loss or against any form of illegal processing.',
            },
          ],
        },
        {
          title: '6. What are your rights?',
          paragraphs: [
            {
              text: 'If you want to know which Information Blanco FoodCoach collects on you, you can request  Blanco FoodCoach to send and overview of this Information. To be certain that the Information  requested concerns really you, we ask you to also send us a copy of a valid proof of identity in  which you make the BSN illegible. ',
            },
            {
              text: 'If you, based on your overview, want to change, remove, shield or limit the processing by Blanco  FoodCoach of your personal Information, you can send a new request to the above mentioned  address. Also in certain situations you are entitled to object or to receive a transfer in a suitable  way of your Information. If the processing of the personal Information is based on your approval, you are entitled to redraw your approval at any moment. The withdrawal of the approval has no influence on the legality of the processing based on approval prior to the moment that this is  withdrew. ',
            },
            {
              text: 'We will try to respond to each request as soon as possible and in any case within one month time. It may however be necessary to have more time to respond. If this is the case, we will inform you  on this at the end of the first month. The extra time will not be more that two months. The request  can be filed via info@theathletesfoodcoach.com.',
            },
          ],
        },
        {
          title: '7. Changes in this Privacy Statement',
          paragraphs: [
            {
              text: 'This Privacy Statement can be altered. In case of significant changes that can have a substantial  impact for you, we strive to directly inform you on this. The last version of this Privacy  Statement is dated December 14, 2022.',
            },
          ],
        },
        {
          title: '8. Questions?',
          paragraphs: [
            {
              text: 'In case you have questions regarding this Privacy Statement you can send an email message to {0}.',
              references: [
                {
                  type: 'link',
                  text: 'serviceformulier',
                  url: 'mailto:info@theathletesfoodcoach.com.nl',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'COOKIE STATEMENT',
      paragraphs: [
        {
          text: 'This Cookie Statement describes which cookies Blanco FoodCoach uses via the FoodCoach app (hereinafter: the <strong>“App”<strong>) and what these cookies are used for. We recommend that you read this  Cookie Statement carefully.',
        },
      ],
      sections: [
        {
          title: '1. What are Cookies?',
          paragraphs: [
            {
              text: 'Cookies are small pieces of (text) information that are sent to your browser when you visit the  App and are then stored on the hard disk or in the memory of your computer, tablet or mobile  phone (hereinafter: "Device"). The browser can return this information on a subsequent visit to the  App. The cookies placed via the App cannot damage your Device or the files stored on it. ',
            },
            {
              text: '{0}',
              references: [
                {
                  type: 'underline',
                  text: 'Which cookies does Blanco FoodCoach use and for what purpose?',
                },
              ],
            },
          ],
        },
        {
          title: '2. Functional cookies ',
          paragraphs: [
            {
              text: 'We primarily use functional cookies. These cookies are necessary to provide you with the services  and functionalities you have requested. For example, functional cookies remember the  information you enter in (order) forms and after logging in you do not have to log in again every  time you visit the App. It is not possible to opt out of functional cookies. The functional cookies  remain active for a period of two (2) years after placement. ',
            },
          ],
        },
        {
          title: '3. Analytics cookies ',
          paragraphs: [
            {
              text: "Blanco FoodCoach uses Google Analytics cookies to collect web statistics about the use and visit  of the App. This cookie registers your use of the App. This data is then analysed by Google and  the results are provided to Blanco FoodCoach. In this way, Blanco FoodCoach gains insight into  the way in which the App is used and can, if necessary, make adjustments to the App and/or its  services on the basis of that data. In this context, Blanco FoodCoach has concluded a processing  agreement with Google. Furthermore, the last octet of your IP address that is sent to Google is  masked and the option 'sharing data' within the Google Analytics environment has been turned  off. Finally, Blanco FoodCoach does not use other Google services in combination with Google  analytics cookies, unless you have given permission for this.",
            },
          ],
        },
        {
          title: '4. Profiling  ',
          paragraphs: [
            {
              text: `Blanco FoodCoach uses re-targeting/re-marketing technologies from advertising networks  including Facebook, Google Adwords and DoubleClick on its website. The purpose of these  technologies is to provide you with interesting and customized offers. 
              A cookie with a unique number is placed on your device via the App. This cookie makes it  possible to recognize your device when you visit another website after visiting the App which is  part of the advertising network. This makes it possible for Blanco FoodCoach to show you an  advertisement from Blanco FoodCoach on that other website.
              In addition to the purposes stated in the section “Analytics cookies”, the data collected using  Google Analytics is also used to display customized advertisements if you have indicated in the  cookie preferences that you also want to receive profiling cookies. The Google Analytics re marketing cookie remains active for a period of two (2) years after placement. 
              `,
            },
          ],
        },
        {
          title: '5. Third Party Cookies ',
          paragraphs: [
            {
              text: `Cookies from third parties are placed via the App. For example, when showing videos from third party websites, including YouTube, these third parties place cookies that make it possible to show  the videos to you. In addition, cookies can be placed that are used to track your surfing behaviour across multiple websites and to build a profile of your surfing behaviour. 
              Further terms and conditions of YouTube apply to the use of the cookies that are placed in the  App via the videos. Blanco FoodCoach does not accept any responsibility or liability for these  terms and conditions. For more information, Blanco FoodCoach refers you to YouTube's terms  and conditions. 
              The other tracking/profiling cookies remain active for an average period of six (6) months after  placement. 
              `,
            },
          ],
        },
        {
          title: '6. Delete cookies ',
          paragraphs: [
            {
              text: 'You can withdraw the permission you have given to Blanco FoodCoach to place and read cookies  at any time by setting your browser so that it does not accept cookies and/or by removing all  cookies already placed in your browser.   ',
            },
            {
              text: `{0} you can read how you can delete the different types of cookies within the different internet  browsers. You must bear in mind that deleting cookies may result in certain parts of the app not  working or not working properly.`,
              references: [
                {
                  type: 'link',
                  text: 'Here',
                  url: 'https://www.consumentenbond.nl/internet-privacy/cookies-verwijderen',
                },
              ],
            },
          ],
        },
        {
          title: '7. Changes ',
          paragraphs: [
            {
              text: 'This Cookie Statement can be changed. The latest version of the Cookie Statement is from  December 14, 2022.',
            },
          ],
        },
        {
          title: '8. Questions ',
          paragraphs: [
            {
              text: `If you have any questions about this Cookie Statement, you can send an e-mail to {0} `,
              references: [
                {
                  type: 'link',
                  text: 'info@theathletesfoodcoach.com',
                  url: 'mailto:info@theathletesfoodcoach.com.',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
