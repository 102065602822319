import { nowISOString } from '@/modules/shared/utils/date.util';
import { configService } from '@/config.service';

export const actionMapping = new Map([
  ['open my plan', { name: 'planner', params: { date: nowISOString() } }],
  ['open performance goal', { name: 'profile-performance-goal' }],
  ['open weight goal', { name: 'profile-weight-goal' }],
  ['open my goal page', { name: 'profile-my-goals' }],
  ['open my nutrition plan metrics', { name: 'profile-my-nutrition-plan-metrics' }],
  ['open profile > connect devices', { name: 'profile-connect-devices' }],
  ['open foodcoach ai', { name: 'chat' }],
  [
    'open carbs per hour calculator',
    { external: `${configService.landingPageUrl}/carbs-per-hour-calculator` },
  ],
]);
