export default {
  mealDetailIntroduction: {
    title: 'Maaltijden op maat',
    message: {
      default:
        'We stellen al jouw maaltijden op maat samen. Zo krijg je altijd de juiste hoeveelheid koolhydraten, eiwitten en vetten per maaltijd binnen.',
      userRecipe:
        'Op basis van de macronutriëntenverhouding van je recept gaan wij de ingrediënten schalen, zodat je het juiste aantal kilocalorieën binnen krijgt.',
    },
    confirmText: 'Oké, duidelijk',
  },
};
