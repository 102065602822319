export default {
  aboutThisApp: "About <strong>The Athlete's FoodCoach</strong> app",
  carbsPerHourExplanation: 'How many carbs do I need?',
  customRecipeIntroduction: `Add your <strong>own recipe</strong> in three steps`,
  doesNotMatchMealPlan: 'Does not meet nutritional plan',
  doesNotMatchMealPlanFavorite:
    'Please note: the macronutrient ratio of this recipe does not fit into your nutritional plan.',
  trainingIntensity: 'Do you want to know more about training intensity?',
  ftpValueExplanation: 'Learn more about your FTP',
  fitnessExplanation: 'Which intensity should I choose?',
  onActivityPopoverExplanation: 'Never bonk again',
  recipeSections: {
    numberOfParticipants: 'Number of people',
    ingredients: 'Ingredients',
    methodOfPreparation: 'Preparation',
    nutritionalValue: 'Nutritional values ​​(per serving)',
    components: 'Components',
    pieces: 'Pieces',
  },
  changeParticipantsWarning: 'You will change the number of people',
  trainingImportanceExplanation: 'What is the training importance ?',
};
