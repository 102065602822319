export const NUMBER_OF_GROCERY_LIST_DAYS = 14;

export const SCOPE_PRO = {
  calories: false,
  carbs: true,
  fat: false,
  fiber: false,
  protein: true,
};

export const SCOPE_ADVANCED_PRO = {
  calories: true,
  carbs: true,
  fat: true,
  fiber: false,
  protein: true,
};
