import {
  androidBackButtonExitMixin,
  androidBackButtonMixin,
  androidBackButtonRedirectMixin,
} from './androidBackButton.mixin';
import chatBubbleMixin from './chatBubbles.mixin';
import env from './env.mixin';
import { setAccessoryBarVisible } from './setAccessoryBarVisible.mixin';
import { validationMixin } from './validation.mixin';

export default {
  androidBackButtonExitMixin,
  androidBackButtonRedirectMixin,
  androidBackButtonMixin,
  chatBubbleMixin,
  env,
  setAccessoryBarVisible,
  validationMixin,
};
