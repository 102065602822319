import appOpenMutationResolver from '@/modules/shared/graphql/resolvers/appOpen.mutation.resolver';
import appOpensResolver from '@/modules/shared/graphql/resolvers/appOpens.query.resolver';
import chatBubblesResolver from '@/modules/shared/graphql/resolvers/chatBubble.query.resolver';
import chatBubblesMutationResolver from '@/modules/shared/graphql/resolvers/chatBubbleShown.mutation.resolver';
import groceryDatesQueryResolver from '@/modules/shared/graphql/resolvers/groceryDates.query.resolver';
import modalQueryResolver from '@/modules/shared/graphql/resolvers/modal.query.resolver';
import modalShownMutationResolver from '@/modules/shared/graphql/resolvers/modalShown.mutation.resolver';
import navigationIntentMutationResolver from '@/modules/shared/graphql/resolvers/navigationIntent.mutation.resolver';
import navigationIntentQueryResolver from '@/modules/shared/graphql/resolvers/navigationIntent.query.resolver';
import networkStatusMutationResolver from '@/modules/shared/graphql/resolvers/networkStatus.mutation.resolver';
import networkStatusQueryResolver from '@/modules/shared/graphql/resolvers/networkStatus.query.resolver';
import scrollPositionMutationResolver from '@/modules/shared/graphql/resolvers/scrollPosition.mutation.resolver';
import scrollPositionQueryResolver from '@/modules/shared/graphql/resolvers/scrollPosition.query.resolver';
import selectedDateMutationResolver from '@/modules/shared/graphql/resolvers/selectedDate.mutation.resolver';
import selectedDateQueryResolver from '@/modules/shared/graphql/resolvers/selectedDate.query.resolver';
import toggleGroceryDateMutationResolver from '@/modules/shared/graphql/resolvers/toggleGroceryDate.mutation.resolver';
import weeksMutationResolver from '@/modules/shared/graphql/resolvers/weeks.mutation.resolver';
import weeksQueryResolver from '@/modules/shared/graphql/resolvers/weeks.query.resolver';

export const authClientResolvers = {
  Query: {
    appOpens: appOpensResolver,
    chatBubble: chatBubblesResolver,
    groceryDates: groceryDatesQueryResolver,
    modal: modalQueryResolver,
    navigationIntent: navigationIntentQueryResolver,
    networkStatus: networkStatusQueryResolver,
    scrollPosition: scrollPositionQueryResolver,
    selectedDate: selectedDateQueryResolver,
    weeks: weeksQueryResolver,
  },
  Mutation: {
    appOpen: appOpenMutationResolver,
    toggleGroceryDate: toggleGroceryDateMutationResolver,
    selectedDate: selectedDateMutationResolver,
    networkStatus: networkStatusMutationResolver,
    navigationIntent: navigationIntentMutationResolver,
    scrollPosition: scrollPositionMutationResolver,
    modalShown: modalShownMutationResolver,
    weeks: weeksMutationResolver,
    bubbleShown: chatBubblesMutationResolver,
  },
};
