import { ref } from 'vue';
import apollo from '@/modules/shared/graphql/apollo.config';
import { removeFromCacheByKeys } from '@/modules/shared/utils/graphqlCache.util';

export const useAsyncMutation = async (mutation, variables, cacheKeys) => {
  const result = ref();
  const loading = ref(true);
  const error = ref();

  await apollo.defaultClient
    .mutate({
      mutation,
      variables,
    })
    .then(response => {
      result.value = response.data;
      loading.value = response.loading;

      if (cacheKeys.length > 0) {
        removeFromCacheByKeys(cacheKeys, apollo.defaultClient.cache);
      }
    })
    .catch(err => {
      error.value = err;
    });

  return {
    result,
    loading,
    error,
  };
};

export const useMutation = (mutation, variables, cacheKeys) => {
  const result = ref();
  const loading = ref(true);
  const error = ref();

  apollo.defaultClient
    .mutate({
      mutation,
      variables,
    })
    .then(response => {
      result.value = response.data;
      loading.value = response.loading;

      if (cacheKeys.length > 0) {
        removeFromCacheByKeys(cacheKeys, apollo.defaultClient.cache);
      }
    })
    .catch(err => {
      error.value = err;
    });

  return {
    result,
    loading,
    error,
  };
};

export const useAsyncQuery = async (
  query,
  variables = null,
  debounce = 0,
  fetchPolicy = 'cache-first'
) => {
  const result = ref();
  const loading = ref(true);
  const error = ref();

  const refetch = async () => {
    await apollo.defaultClient
      .query({
        query,
        variables,
        debounce,
        fetchPolicy,
      })
      .then(response => {
        result.value = response.data;
        loading.value = response.loading;
      })
      .catch(err => {
        error.value = err;
      });
  };

  await refetch();

  return {
    result,
    loading,
    error,
    refetch,
  };
};

export const useQuery = (query, variables = null, debounce = 0, fetchPolicy = 'cache-first') => {
  const result = ref();
  const loading = ref(true);
  const error = ref();

  const refetch = () => {
    apollo.defaultClient
      .query({
        query,
        variables,
        debounce,
        fetchPolicy,
      })
      .then(response => {
        result.value = response.data;
        loading.value = response.loading;
      })
      .catch(err => {
        error.value = err;
      });
  };

  refetch();

  return {
    result,
    loading,
    error,
    refetch,
  };
};
