export const validationMixin = {
  methods: {
    fieldIsValid: (fields, fieldName) =>
      fields[fieldName] &&
      (fields[fieldName].touched || fields[fieldName].passed) &&
      fields[fieldName].valid,
    fieldIsInvalid: (fields, fieldName) =>
      fields[fieldName] &&
      (fields[fieldName].touched || fields[fieldName].passed) &&
      fields[fieldName].invalid,
    hasError: errors => errors.length > 0,
  },
};
