export default {
  myInfo: 'Personal data',
  mySportSettings: 'Sport settings',
  coach: 'My coach',
  applications: 'Apps & devices',
  aboutThisApp: 'About this app',
  feedback: 'Share feedback',
  myAccount: 'My account',
  performanceGoal: 'Performance goal',
  weightGoal: 'Weight goal',
  privacySettings: 'Privacy settings',
  dateOfBirth: 'Date of birth',
  language: 'Language',
  length: 'Height',
  weight: 'Weight',
  gender: 'Gender',
  typeOfWork: 'Type of work',
  workingHours: 'Working hours',
  baselineActivityLevel: 'Baseline activity level',
  sleepingHours: 'Sleeping hours',
  sports: 'Improve sport performance',
  viewAllFAQs: 'Check all frequently asked questions',
  energyCalculationExplanation: 'What method applies best to me?',
  myMainSport: 'My main sport',
  myFtp: 'My FTP',
  energyInRestCalculation: 'Energy in rest calculation',
  mySubscription: 'My subscription',
  manageSubscriptionInAppStore: 'Manage my subscription in {store}',
  productDatabase: 'Product database',
  nutritionView: 'Nutrition view',
  whatGenderShouldIChoose:
    'I don’t identify myself with male or female, <u>what should I choose?</u>',
  workTypePopover: 'Which type of work applies to me?',
  whichWeightGoal: 'Which weight goal should I choose?',
  whichNutritionView: 'Which view applies best to me?',
  smartWeightLoss: 'What is a smart weight loss target for me?',
  smartWeightGain: 'What is a smart weight gain target for me?',
};
