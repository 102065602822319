<script lang="ts">
  import { computed, defineComponent, onBeforeMount, ref } from 'vue';
  import { useRoute } from 'vue-router/composables';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import qs from 'qs';

  const getYouTubeVideoId = url => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  export default defineComponent({
    name: 'VideoDetailNoPlaylistPage',
    setup() {
      const route = useRoute();
      const { videoSlug } = route.params;
      const playerVars = { controls: 1, showinfo: 0, rel: 0, modestbranding: 1 };
      const video = ref();
      const videoId = computed(() =>
        video.value?.url ? getYouTubeVideoId(video.value?.url) : null
      );

      onBeforeMount(async () => {
        const query = qs.stringify({
          populate: 'deep,8',
          filters: {
            uuid: {
              $eq: videoSlug,
            },
          },
        });
        const response = await http.get(`videos?${query}`);
        const [resource] = response.data.data;
        video.value = resource.attributes;
      });

      return {
        video,
        videoId,
        playerVars,
      };
    },
  });
</script>

<template>
  <fc-page v-if="video">
    <template #top>
      <fc-nav-bar>
        {{ video.title }}
      </fc-nav-bar>
    </template>

    <fc-page-content>
      <youtube
        v-if="videoId"
        class="video-container"
        :video-id="videoId"
        :player-vars="playerVars"
        @ended="markAsCompleted"
      />
    </fc-page-content>
  </fc-page>
</template>

<style scoped lang="scss">
  .video-container {
    aspect-ratio: 16/9;
    height: 100%;
    overflow: hidden;
    width: 100%;

    :deep(iframe) {
      height: 100%;
      width: 100%;
    }
  }

  .fc-nav-bar {
    background-color: #0f0f0f;
    color: #fff;
  }

  .fc-page-content {
    align-items: center;
    background-color: #0f0f0f;
    display: flex;
  }
</style>
