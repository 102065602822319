import buttons from './buttons.en';
import headings from './headings.en';
import info from './info.en';
import labels from './labels.en';
import links from './links.en';
import misc from './misc.en';
import modals from './modals.en';
import notifications from './notifications.en';
import titles from './titles.en';

export default {
  buttons,
  headings,
  info,
  labels,
  links,
  misc,
  titles,
  modals,
  notifications,
};
