import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import { getItem, setItem } from '@/modules/shared/services/storage.service';

export default async (_, { bubbleName }) => {
  const bubble = {
    shouldBeShown: false,
    _id: bubbleName,
    bubbleShown: true,
    __typename: 'Bubble',
  };

  const bubbles = (await getItem(PERSISTENCE_KEYS.BUBBLES)) || {};
  bubbles[bubbleName] = bubble;
  await setItem(PERSISTENCE_KEYS.BUBBLES, bubbles);

  return bubble;
};
