export default {
  activityLevel: {
    question: 'Wanneer je niet sport of werkt, in welke mate ben je dan nog lichamelijk actief?',
    explanation:
      'Zit je bijvoorbeeld veel thuis, kijk je televisie en doe je tussendoor boodschappen of laat je de hond uit? Dan ben je gemiddeld actief. Erg actief ben je wanneer je urenlang achter de bar in de sportkantine staat. En ben je in je vrije tijd urenlang aan het klussen of tuinieren? Dan ben je extreem actief.',
  },
  addFirstTraining: 'Klaar om je eerste training toe te voegen?',
  blogs:
    'Wil jij voldoende energie hebben rondom het sporten, je weerstand op orde krijgen en goed herstellen? Wij inspireren en coachen je.',
  customRecipeIntroductionUSPs: [
    'Voer je eigen recept en de ingrediënten in',
    'Speel met de verhouding van de ingrediënten om je recept beter passend te maken voor jouw voedingsplan',
    'Plan je recept in. Wij slaan het voor je op, zodat je het recept op andere dagen opnieuw kunt inplannen.',
  ],
  dailyEnergyNeed: {
    explanation:
      'Mooi, we hebben je profiel op een rustdag bepaald! We weten wat je gemiddelde energiebehoefte is en kunnen je een richting geven hoeveel koolhydraten, eiwitten en vetten je nodig hebt.',
    edit: 'Je kan deze gegevens controleren en later altijd wijzigen in je profiel.',
  },
  doesNotMatchMealPlan:
    'Let op: de macronutriëntenverhouding van je recept past niet binnen je voedingsplan. {editRecipe} voordat je hem inplant.',
  emptyLockedMoment:
    'Er zijn op dit moment geen producten of recepten toegevoegd, en je hebt het vergrendeld',
  favoriteRecipes: {
    emptyTitle: 'Je hebt nog geen favoriet(e) {moment} toegevoegd',
    emptyParagraph: 'Druk op het hartje bij het recept om hem als favoriet te markeren',
  },
  ftpExplanationIntro:
    'De meest betrouwbare manier om je FTP-waarde te meten, is een inspanningstest doen bij een inspanningsfysioloog of sportarts. Een alternatieve methode is het zelf meten. Smart hometrainers met vermogensmeting bieden vaak standaard 20 minuten FTP-testen aan of werken op standaard testen van software programma’s als Zwift.',
  ftpExplanation: [
    {
      text: 'Je kunt de 20 minuten test ook buiten uitvoeren, mits je fiets beschikt over een vermogensmeter. Probeer deze test onder gunstige omstandigheden te doen: op een vlak parcours (of één lange gelijkmatige klim van max 6%) en het liefst zonder bochten en zonder al te veel verkeer. Na een goede warming-up fiets je 20 minuten zo hard mogelijk in een constant tempo. Van het gemiddelde vermogen dat je in die 20 minuten trapt, trek je 5% af om je FTP-waarde in te schatten.',
    },
    {
      text: "Met bovenstaande testen kun je je FTP-waarde vrij nauwkeurig meten. Houd er rekening mee dat de geschatte FTP-waarde van The Athlete's FoodCoach een ruime of onjuiste schatting kan zijn. Hierdoor kan de energieberekening onjuist zijn.",
    },

    {
      text: "Ook kun je je FTP-waarde terugzoeken via activity trackers of verbonden apps. Hiervoor geldt hetzelfde als de geschatte FTP-waarde van The Athlete's FoodCoach: deze is minder accuraat dan een inspanningstest bij een inspanningsfysioloog of sportarts of wanneer je de test zelf uitvoert.",
    },
  ],
  ftpExplanationEstimation:
    'Op basis van je gegevens hebben we een eerste, voorzichtige schatting van je FTP-waarde gemaakt.',
  ftpExplanationQuestion: 'Weet jij je FTP-waarde? Dan kun je die hierboven zelf aanpassen.',
  ftpValue: {
    explanation:
      'Net als bij de renners van Team Visma | Lease a Bike bepalen we aan de hand van je FTP-waarde hoe intensief een inspanning voor jou is. Weet jij jouw FTP-waarde niet? Dan helpen we je. We hebben een eerste, voorzichtige inschatting van je FTP-waarde gemaakt.',
    edit: 'Wanneer je FTP-waarde verandert, kun je die eenvoudig aanpassen in je profiel.',
  },
  length:
    'Om je goed te kunnen coachen, berekenen we eerst wat je lichaam aan energie nodig heeft als je niet sport. Daarvoor hebben we een aantal gegevens van je nodig. Te beginnen met je lengte.',
  myGoal:
    'Gezonder eten? Pas hier je doel aan. Gezondere eetgewoontes voor meer energie: plan een weekmenu, maak eetwissels en ga bewuster om met voeding.',
  noMatchingIngredients: 'Voeg hier je ontbrekende ingrediënt toe',
  noMatchingProducts: 'Voeg hier je ontbrekende product toe',
  onActivityExplanation: [
    {
      text: 'Pijnlijke spieren, duizeligheid en een waas voor zijn ogen. Het zijn de verschijnselen wanneer Jonas Vingegaard een hongerklop heeft. ‘Je wilt wel, maar voelt je helemaal leeg. Je vraagt dingen van je lichaam, waar het de energie niet meer voor heeft’, zegt de renner van Team Visma | Lease a Bike.',
    },
    {
      text: 'Bij een hongerklop ben je volledig door je koolhydraatvoorraden heen: de favoriete brandstof van je lichaam om op hoge intensiteit in te spannen. Je lichaam moet noodgedwongen overschakelen op je vetverbranding. Een veel minder snelle brandstof, waardoor je niet meer op hetzelfde tempo kan blijven fietsen. ‘Je lichaam komt in een soort noodtoestand’, zegt Martijn Redegeld, sportdiëtist van Team Visma | Lease a Bike.',
    },
    {
      text: "The Athlete's FoodCoach helpt je bij het voorkomen van een hongerklop door ervoor te zorgen dat je met je maaltijden voor een training je koolhydraatvoorraden (in je lever en spieren) maximaal vult. Ook adviseert The Athlete's FoodCoach wat je tijdens je training aan koolhydraten moet nemen, zodat je op je fiets niet zonder koolhydraten komt te staan.",
    },
    {
      text: "Ga je maximaal twee uur intensief trainen? Dan raad The Athlete's FoodCoach je aan om minimaal 20 gram koolhydraten per uur te pakken. Bij inspanningen langer dan twee uur is het advies minimaal 40 gram per uur. Redegeld: ‘Door je koolhydraten voortdurend aan te vullen, voorkom je dat de bodem van je brandstoftank bereikt wordt en je een hongerklop ervaart.’",
    },
    {
      text: "Dumoulin: ‘The Athlete's FoodCoach geeft mij inzicht in hoeveel koolhydraten ik moet eten voor en tijdens mijn training. Daar houd ik mij aan vast. Voeding speelt een steeds grotere rol om goed te presteren. Voor mij staat trainen nog altijd op één, maar voeding komt daar al snel achteraan. Daarin maakt The Athlete's FoodCoach het verschil.’",
    },
  ],
  planMealsEnabled:
    'Op basis van je training kunnen we nu bepalen wat, wanneer en hoeveel je moet eten. Hierop kun je je maaltijden inplannen.',
  schedule: {
    explanation: 'Je training heeft een grote invloed op wat, wanneer en hoeveel je moet eten.',
    selectTraining:
      'Ben je klaar om te beginnen? Selecteer de datum van je eerste training en voeg deze toe.',
  },
  searchProductsToAddToRecipe: 'Begin met zoeken van producten en voeg ze toe aan je eigen recept.',
  sleepingHours: 'Hoeveel uur slaap je per nacht?',
  sport: {
    hello: `Hallo! Leuk dat we je mogen coachen.`,
    helloName: `Hallo!`,
    onlyCycling:
      'Helaas wordt momenteel alleen de wielrennen ondersteund. Andere sporten volgen snel.',
    sportSelectorInfo:
      "The Athlete's FoodCoach is samen met de wielerprofs van Team Visma | Lease a Bike ontwikkeld. Wielrennen en hardlopen zijn de eerste sporten die door The Athlete's FoodCoach worden ondersteund. Andere sporten volgen snel.",
  },
  trainingIntensity: 'Bovenstaand gemiddeld wattage kun je zelf scherper stellen.',
  weight: 'Ook je gewicht speelt een belangrijke rol.',
  workingHours: 'Hoeveel uur werk je per dag?',
  workType: {
    intro:
      'Daarnaast vergt de ene baan meer energie van je lichaam dan de andere baan. Daarom willen we graag weten wat voor werk je doet.',
    explanation:
      'Zit je veel achter je bureau? Dan heb je zittend werk. Een leerkracht verricht licht actief werk. Een postbode heeft gemiddeld actief werk en als stratenmaker heb je zwaar actief werk.',
  },
  changeParticipantsWarning:
    'Let op: door het aantal personen te wijzigen, veranderen de hoeveelheden voor ingrediënten, jouw portie als sporter is {portionPercentage} van deze maaltijd.',
  mealOnActivity: {
    intro:
      'Ga je langer dan een uur trainen? Dan is het belangrijk dat je je lichaam tijdens de training van brandstof voorziet.',
    explanation:
      'Selecteer de producten die jij wil meenemen tijdens je training. Vul de producten aan tot de meter vol is.',
  },
  mealPlanner: {
    intro:
      'Hieronder vind je maaltijden die passen bij jouw voedingsplan. Kies wat jij lekker en makkelijk vindt!',
    explanation:
      'Wanneer je je ideale recept hebt gevonden, kun je de ingrediënten makkelijk toevoegen aan je boodschappenlijstje.',
  },
  noTrainingScheduled: 'Vandaag heb je geen training gepland',
  noMatchingRecipes: 'Probeer een ander recept te zoeken',
  pullToRefresh: {
    pulling: 'Pull to refresh',
    loosing: 'Let go to refresh',
    refresh: 'Loading…',
    success: ' ',
  },
  thankYouForAddingThisIngredient:
    'Vanaf nu kun je dit ingrediënt gebruiken bij het afstemmen van je maaltijd. \n \n Ons team zal je ingrediënt beoordelen. Als jouw suggestie een geweldige toevoeging is aan onze database, zullen we het ingrediënt voor alle gebruikers publiceren.',
  productNotFound:
    'We kunnen geen product vinden dat overeenkomt met deze barcode ({gtin}). Probeer het opnieuw of voeg dit product zelf toe.',
  multipleProductsFound:
    'We hebben meerdere producten gevonden die overeenkomen met deze barcode ({gtin}). Selecteer er een.',
  cameraDeniedDescription:
    'De app heeft toegang tot de camera nodig om een barcode te scannen. Ga naar instellingen en schakel de camera in.',
};
