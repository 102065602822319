import { trainingTypesTranslations, TRAINING_TYPES } from './trainingTypes.en';

const FOOD_DURING_ACTIVITY = 'Food during activity';

const trainingMoments = () => ({
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening',
  beforeBreakfast: 'Breakfast',
  beforeMorningSnack: 'Morning snack',
  beforeLunch: 'Lunch',
  beforeAfternoonSnack: 'Afternoon snack',
  beforeDinner: 'Dinner',
  beforeEveningSnack: 'Evening snack',
  beforeRecoverySnack: 'Shake',
  beforeHalfTime: 'Half-time',
  beforeShake: 'Shake',
});

export default {
  activityLevel: 'Activity level',
  activityLevelOnboarding: 'How active are you besides sports and working hours?',
  activityLevels: {
    light: 'Average active',
    moderate: 'Very active',
    heavy: 'Extremely active',
  },
  chooseRecipeMoments: 'Where else would you like to show this recipe?',
  hideSyncedActivities:
    'Hide {n} synced activities | Hide {n} synced activity | Hide {n} synced activities',
  showSyncedActivities:
    'Show {n} synced activities | Show {n} synced activity | Show {n} synced activities',
  chooseFrom: 'Choose',
  chooseRecipeName: 'Give this meal a name',
  chooseYour: 'Choose',
  choseYourSport: 'Choose a teamsport',
  date: 'Date',
  dateOfBirth: 'Date of birth',
  doesNotMatchMealPlan: 'Does not meet nutritional plan',
  ftpValue: 'FTP',
  highlightMoments: {
    preWorkoutMeal: 'Pre-workout meal',
    preWorkoutSnack: 'Pre-workout snack',
    recoveryMeal: 'Recovery meal',
    recoverySnack: 'Shake',
  },
  intake: 'intake',
  hockey: {
    matchDayKeeper: 'Match (goalkeeper)',
    matchDayPlayer: 'Match (field player)',
    normalTraining: 'Normale training',
    intensiveTraining: 'Intensive training',
    keeperTraining: 'Goalkeeper training',
  },
  fitness: {
    strength: TRAINING_TYPES.STRENGTH_TRAINING,
    cardioGroup: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
    cardioStrength: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
    circuitCrossfit: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
    cardioStrengthEasy: 'easy',
    cardioStrengthModerate: 'average',
    cardioStrengthIntensive: 'Intensive',
    strengthLotsResistance: 'Low resistance',
    strengthLittleResistance: 'High resistance',
    littleRest: '0-120 sec',
    lotsRest: '> 120 sec',
    cardioGroupLessonEasy: 'Easy',
    cardioGroupLessonModerate: 'Average',
    cardioGroupLessonIntensive: 'Intensive',
    circuitCrossfitModerate: 'Average',
    circuitCrossfitIntensive: 'Intensive',
  },
  hockeyDuration: 'How long will your training take?',
  hockeyMoment: 'I will train before',
  hours: 'hours',
  intensity: 'Training intensity',
  carbsPerHour: 'Carbs per hour',
  intensityType: 'Intensity',
  restType: 'Rest between reps',
  length: 'Length',
  mealDifficulties: {
    simple: 'Easy',
    medium: 'advanced',
    hard: 'hard',
  },
  moments: {
    beforeBreakfast: FOOD_DURING_ACTIVITY,
    breakfast: 'Breakfast',
    beforeMorningSnack: FOOD_DURING_ACTIVITY,
    morningSnack: 'Morning snack',
    preRaceSnack: 'Pre-race snack',
    beforeLunch: FOOD_DURING_ACTIVITY,
    lunch: 'Lunch',
    beforeAfternoonSnack: FOOD_DURING_ACTIVITY,
    afternoonSnack: 'Afternoon snack',
    beforeRecoverySnack: FOOD_DURING_ACTIVITY,
    recoverySnack: 'Shake',
    shake: 'Shake',
    recoveryMeal1: 'Recovery meal 1',
    recoveryMeal2: 'Recovery meal 2',
    beforeDinner: FOOD_DURING_ACTIVITY,
    dinner: 'Dinner',
    beforeEveningSnack: FOOD_DURING_ACTIVITY,
    eveningSnack: 'Evening snack',
    beforeHalfTime: FOOD_DURING_ACTIVITY,
    beforeShake: FOOD_DURING_ACTIVITY,
    cherryJuice: 'Cherry juice',
    gel: 'Gel',
    halfTime: 'Half-time',
    postGameMeal: 'Post-game meal',
    preGame: 'Pre-game',
    preGameMeal: 'Pre-game meal',
    preWarmingUp: 'Pre-warming-up',
    recoveryMeal: 'Recovery meal',
    snack: 'Snack',
  },
  minutes: 'Minutes',
  minimumIntake: 'Minimum fuel requirement:',
  notAvailable: 'Not yet available',
  nutrients: {
    calories: 'Energy',
    carbs: 'Carbs',
    fat: 'Fats',
    fiber: 'Fibres',
    protein: 'Protein',
  },
  running: {
    distance: 'Distance',
    tempo: 'Pace (min/km)',
  },
  recipeTypes: {
    breakfast: 'Breakfast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    snack: 'Snack',
  },
  searchRecipes: 'What do you want to eat?',
  sleepingHours: 'Sleeping hours',
  soccer: {
    matchDayKeeper: 'Match (goalkeeper)',
    matchDayPlayer: 'Match (field player)',
    normalTraining: 'Normale training',
    intensiveTraining: 'Intensive training',
    keeperTraining: 'Goalkeeper training',
    gameDayMinusOneAm: 'Game day -1AM',
    gameDayMinusTwoAm: 'Game day -2AM',
    gameDayMinusThreeAm: 'Game day -3AM',
    gameDayMinusOnePm: 'Game day -1PM',
    gameDayMinusTwoPm: 'Game day -2PM',
    gameDayMinusThreePm: 'Game day -3PM',
    gameDayEvening: 'Game day evening',
  },
  soccerDuration: 'How long will your training take?',
  sports: {
    cycling: 'Cycling',
    fitness: 'Fitness',
    running: 'Running',
    teamsport: 'Teamsport',
    hockey: 'Hockey',
    soccer: 'Football',
  },
  today: 'Today',
  trainingDuration: 'Duration',
  trainingEnergy: 'Training energy',
  trainingImportance: 'Training importance',
  activityTrainingDuration: 'Planned duration',
  activityActualDuration: 'Actual duration',
  activityTrainingIntensity: 'Planned intensity',
  activityActualIntensity: 'Actual intensity',
  actualDuration: 'How long did you train?',
  trainingMoment: 'I will train before',
  trainingMoments: {
    ...trainingMoments(),
  },
  trainingMomentsPro: {
    ...trainingMoments(),
  },
  trainingTypes: {
    ...trainingTypesTranslations(),
  },
  trainingTypesPro: {
    ...trainingTypesTranslations(true),
  },
  trainingType: 'Type of training',
  trainingMainType: 'Training type',
  intensityTypes: {
    strengthLotsResistanceLittleRest: 'Low resistance',
    strengthLotsResistanceLotsRest: 'High resistance',
    strengthLittleResistanceLittleRest: 'low resistance',
    strengthLittleResistanceLotsRest: 'high resistance',
    cardioStrengthEasy: 'Easy',
    cardioStrengthModerate: 'Average',
    cardioStrengthIntensive: 'Intensive',
    cardioGroupLessonEasy: 'Easy',
    cardioGroupLessonModerate: 'Average',
    cardioGroupLessonIntensive: 'Intensive',
    circuitCrossfitModerate: 'Average',
    circuitCrossfitIntensive: 'Intensive',
  },
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Work type',
  whichTeamSport: 'Choose your teamsport',
  workTypes: {
    unemployed: "I don't work",
    sedentary: 'Physically passive (sitting down)',
    light: 'Mildly active',
    moderate: 'Quite active',
    heavy: 'Very active',
  },
  yourWeight: 'Weight',
};
