import { Preferences } from '@capacitor/preferences';

export const getItem = async key => {
  const { value } = (await Preferences.get({ key })) || {};

  try {
    return JSON.parse(value);
  } catch {
    return value || null;
  }
};

export const setItem = async (key, value) => {
  const val = typeof value === 'string' ? value : JSON.stringify(value);
  await Preferences.set({ key, value: val });
};

export const removeItem = async key => {
  await Preferences.remove({ key });
  return true;
};

export const clear = async () => Preferences.clear();
