import { setDefaultOptions } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';

export const setLocale = (i18n, locale = 'en') => {
  // eslint-disable-next-line no-param-reassign
  i18n.locale = locale || 'en';
  setDefaultOptions({
    locale: locale === 'nl' ? nl : enUS,
  });
};
