import { required } from 'vee-validate/dist/rules.umd';

const eventRules = [
  {
    name: 'event_name_required',
    message: 'core.inputErrors.required.eventName',
    rule: required,
  },
  {
    name: 'event_date_required',
    message: 'core.inputErrors.required.eventDate',
    rule: required,
  },
];

export default eventRules;
