var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fc-tag label",class:{
    recover: _vm.recover,
    perform: _vm.perform,
    protect: _vm.protect,
    health: _vm.health,
    coach: _vm.coach,
    video: _vm.video,
    specials: _vm.specials,
    challenge: _vm.challenge,
    learning: _vm.learning,
    doing: _vm.doing,
    mini: _vm.mini,
  }},[_vm._v(" "+_vm._s(_setup.label)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }