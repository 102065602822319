import { registerRoutes, TAB_ROUTE } from '@/modules/home/routes';
import messages from './messages/nl';
import messagesEn from './messages/en';

/* eslint-disable import/no-mutable-exports */
export let utils = {};
/* eslint-enable import/no-mutable-exports */

export const homeModule = (
  { router },
  sharedModule,
  { guards: { onboardingGuard } },
  i18nModule
) => ({
  tabRoute: undefined,
  install() {
    i18nModule.setTranslations({ home: { ...messages } }, 'nl');
    i18nModule.setTranslations({ home: { ...messagesEn } }, 'en');

    this.tabRoute = {
      ...TAB_ROUTE,
      beforeEnter: sharedModule.utils.guards([onboardingGuard]),
    };

    utils = { ...sharedModule.utils };

    registerRoutes(router);
  },
});
