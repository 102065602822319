import { configService } from '@/config.service';
import { actionMapping } from '@/util/gamification/actions';

const { strapiImageUrl } = configService;

const mapLink = link => {
  const tag = {
    type: link.type,
    text: link.type,
  };

  if (link.type === 'video') {
    return {
      title: link.video.data.attributes.title,
      url: link.video
        ? {
            name: 'video-no-playlist',
            params: {
              videoSlug: link.video.data.attributes.uuid,
            },
          }
        : null,
      img: `${strapiImageUrl}${link.video.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: false,
      tag,
      uuid: link.video.data.attributes.uuid,
      completed: false,
    };
  }

  if (link.type === 'learning') {
    return {
      title: link.blog.data.attributes.title,
      url: link.blog?.data?.attributes?.url,
      img: `${strapiImageUrl}${link.blog.data.attributes?.cardImage?.data?.attributes.url}`,
      isExternal: true,
      tag,
      uuid: link.blog.data.attributes.uuid,
      completed: false,
    };
  }

  return null;
};

export class Doing {
  constructor(title, what, why, coverImageUrl, cardImageUrl, action, tag, links) {
    this.title = title;
    this.what = what;
    this.why = why;
    this.coverImageUrl = coverImageUrl;
    this.cardImageUrl = cardImageUrl;
    this.action = action;
    this.tag = tag;
    this.links = links;
  }

  static createFromStrapi(resource) {
    const { attributes } = resource;

    return new Doing(
      attributes.title,
      attributes?.what.map(item => item.text) ?? [],
      attributes?.why,
      attributes.coverImage?.data?.attributes?.url
        ? `${strapiImageUrl}${attributes.coverImage.data.attributes.url}`
        : null,
      `${strapiImageUrl}${attributes.cardImage.data.attributes.url}`,
      actionMapping.get(attributes.type),
      attributes?.tag?.type,
      attributes.links.map(mapLink).filter(link => link !== null)
    );
  }
}
