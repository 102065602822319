<script setup>
  import { computed } from 'vue';
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';
  import { EVENT_DATE_FORMAT } from '@/modules/shared/constants/date.const';
  import WeightGoalBadge from '@/components/library/weight-goal-badge/WeightGoalBadge.vue';
  import { isDefined } from '@blancofoodcoach/typescript-toolkit';
  import { differenceInDays, format, isAfter, parseISO } from 'date-fns';

  const props = defineProps({
    givenName: {
      type: String,
      required: true,
    },
    middleName: {
      type: String,
      required: false,
      default: '',
    },
    familyName: {
      type: String,
      required: false,
      default: '',
    },
    event: {
      type: [Object, null],
      required: false,
      default: null,
    },
    calorieCorrection: {
      type: Number,
      default: 0,
    },
  });
  const startDate = computed(() => parseISO(props.event.startDate));
  const eventDate = computed(() => parseISO(props.event.eventDate));
  const isPassed = computed(() => isAfter(new Date(), eventDate.value));
  const totalDays = computed(() => differenceInDays(startDate.value, eventDate.value));
  const remainingDays = computed(() =>
    !isPassed.value ? differenceInDays(eventDate.value, new Date()) + 1 : 0
  );
  const pastDays = computed(() => totalDays.value - remainingDays.value);
  const percentagePast = computed(() =>
    !isPassed.value ? (totalDays.value / pastDays.value) * 100 : 100
  );
  const endDate = computed(() => format(eventDate.value, EVENT_DATE_FORMAT));
</script>

<template>
  <router-link v-slot="{ navigate }" :to="{ name: 'profile-performance-goal' }" custom>
    <div class="my-performance-goal" @click="navigate">
      <template v-if="event">
        <span class="primary-color light small">{{ $t('heading') }}</span>
        <jum-heading h1>
          <strong>{{ event.name }}</strong>
        </jum-heading>

        <div class="countdown">
          <div class="info">
            <div class="remainder">In {{ remainingDays }} days</div>

            <div class="end-date">
              {{ endDate }}
            </div>
          </div>

          <div class="progress-bar">
            <div class="filler" :style="{ width: `${percentagePast}%` }"></div>
          </div>
        </div>
      </template>

      <template v-else>
        <jum-heading h1>
          <strong>{{ $t('noGoal.heading', { name: givenName }) }}</strong>
        </jum-heading>
        <jum-heading h6 class="light">{{ $t('noGoal.subheading') }}</jum-heading>
        <router-link v-slot="{ navigate }" :to="{ name: 'profile-performance-goal' }" custom>
          <jum-button secondary class="inverse" @click="navigate">{{
            $t('noGoal.button')
          }}</jum-button>
        </router-link>
      </template>

      <template v-if="isDefined(calorieCorrection) && calorieCorrection !== 0">
        <weight-goal-badge :calorie-correction="calorieCorrection" class="margin-top-m" />
      </template>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  $color-primary: #f94d3f;

  .my-performance-goal {
    background-color: #000;
    color: #fff;
    cursor: pointer;
    margin-top: -$page-padding;
    padding: $page-padding $page-padding $spacing-l $page-padding;

    .jum-heading.h6 {
      margin-bottom: $spacing-m;
    }

    .jum-button.inverse {
      background-color: transparent;
      border-color: #fff;
      color: #fff;

      &:hover {
        background-color: #fff !important;
        border-color: #fff;
        color: #000 !important;
      }
    }
  }

  .light {
    font-weight: 300 !important;
  }

  .primary-color {
    color: $color-primary;
  }

  .small {
    font-size: 14px;
  }

  .countdown {
    .info {
      @extend .light;
      @extend .small;
      display: flex;
      justify-content: space-between;
      margin: 16px 0 4px;
    }

    .progress-bar {
      background: #e3e3e3;
      height: 12px;
      overflow: hidden;
      width: 100%;

      .filler {
        background: $color-primary;
        background: linear-gradient(270deg, $color-primary 0%, #ff796f 100%);
        height: 100%;
        left: 0;
        position: relative;
        top: 0;
        width: 0;
      }
    }
  }

  :deep(.weight-goal-badge) {
    font-weight: 700;
  }
</style>

<i18n>
{
  "en": {
    "heading": "My goal",
    "noGoal": {
      "heading": "Hey {name} 👋",
      "subheading": "What’s your next performance goal?",
      "button": "Add a goal"
    }
  },
  "nl": {
    "heading": "Mijn doel",
    "noGoal": {
      "heading": "Hallo {name} 👋",
      "subheading": "Wat is je volgende prestatiedoel?",
      "button": "Voeg een doel toe"
    }
  }
}
</i18n>
