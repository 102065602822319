// Pages
import ForgotPasswordPage from '@/modules/shared/auth/pages/forgot-password/ForgotPasswordPage.vue';
import RegisterPage from '@/modules/shared/auth/pages/register/RegisterPage.vue';
import ResetPasswordConfirmationPage from '@/modules/shared/auth/pages/reset-password-confirmation/ResetPasswordConfirmationPage.vue';

const LoginPage = () => import('@/modules/shared/auth/pages/login/LoginPage');

export const registerRoutes = (router, guards) => {
  const routes = [
    {
      path: '/login',
      name: 'auth-login',
      component: LoginPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/registreren',
      name: 'auth-register',
      component: RegisterPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/wachtwoord-vergeten',
      name: 'auth-forgot-password',
      component: ForgotPasswordPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/wachtwoord-gereset',
      name: 'auth-reset-password-confirmation',
      component: ResetPasswordConfirmationPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
