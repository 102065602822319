import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import VueApollo from 'vue-apollo';
import { httpLink } from '@/modules/shared/graphql/links/http.link';
import { logErrorLink } from '@/modules/shared/graphql/links/logError.link';
import { authClientResolvers } from '@/modules/shared/graphql/resolvers/resolvers';
import { unauthorizedErrorLink } from '@/modules/shared/graphql/links/unauthorizedErrorLink';
import { serviceUnavailableLink } from '@/modules/shared/graphql/links/serviceUnavailableLink';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { configService } from '@/config.service';
import introspectionQueryResultData from './fragment-types.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Links
const authClientLinks = ApolloLink.from([
  unauthorizedErrorLink(),
  serviceUnavailableLink(),
  logErrorLink(),
  httpLink(),
]);

// Cache - Shared across clients
const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: o => {
    switch (o.__typename) {
      case 'User':
        return `${o.__typename}:${o.customerId}`;
      case 'Feature':
        return `${o.__typename}:${o.name}`;
      case 'Recipe': {
        // eslint-disable-next-line no-console
        if (o.id === undefined) console.error(`${o.__typename} misses 'id' property`);
        // eslint-disable-next-line no-console
        if (o.quantity === undefined) console.error(`${o.__typename} misses 'quantity' property`);
        return `${o.__typename}:${o.id}:${o.quantity}`;
      }
      case 'Product':
        return `${o.__typename}:${o.id}:${o.requiredSkuQuantity}`;
      default:
        return defaultDataIdFromObject(o);
    }
  },
});

// Client
const authClient = new ApolloClient({
  link: authClientLinks,
  resolvers: authClientResolvers,
  cache,
  shouldBatch: true,
  query: {
    fetchPolicy: 'no-cache',
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${configService.strapiApiKey}`,
  },
  query: {
    fetchPolicy: 'cache-first',
  },
}));

const strapiClient = new ApolloClient({
  link: ApolloLink.from([
    authLink.concat(
      createHttpLink({
        uri: `${configService.strapiApiUrl}/graphql`,
      })
    ),
  ]),
  cache: new InMemoryCache(),
});

export default new VueApollo({
  defaultClient: authClient,
  clients: [strapiClient],
  $query: {
    notifyOnNetworkStatusChange: true,
  },
});
