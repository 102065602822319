import Router from 'vue-router';

export const routerModule = () => ({
  install(Vue) {
    Vue.use(Router);

    this.router = new Router({
      mode: 'history',
      base: process.env.VUE_APP_PUBLIC_PATH || '/',
      routes: [
        {
          path: '/down',
          name: 'down',
          component: () => import('@/modules/service-unavailable/pages/ServiceUnavailablePage.vue'),
          meta: {
            noPayWall: true,
          },
        },
        {
          path: '/foodcoach-ai',
          name: 'chat',
          meta: {
            noPayWall: true,
          },
          component: () => import('@/pages/ChatPage.vue'),
        },
        {
          path: '/medals/:sport',
          name: 'my-medals',
          component: () => import('@/pages/MyMedalsPage.vue'),
        },
        {
          path: '/medals/detail/:uuid',
          name: 'medal-detail',
          component: () => import('@/pages/MedalPage.vue'),
        },
        {
          path: '/focus/:type',
          name: 'focus',
          component: () => import('@/pages/FocusPage.vue'),
        },
      ],
    });

    this.router.onError(error => {
      if (/loading chunk \d* failed./i.test(error.message)) {
        return window.location.reload();
      }

      throw error;
    });
  },
});
