import { Purchases } from '@revenuecat/purchases-capacitor';

export const setRevenueCatCustomer = async user => {
  if (!user) {
    console.error('setRevenueCatCustomer: user is required to set the RevenueCat customer');
    return;
  }

  await Purchases.logIn({ appUserID: user.customerId });
  await Purchases.setEmail({
    email: user.emailAddress,
  });
};
