<script>
  import { defineComponent } from 'vue';
  import { events } from '../events';

  export default defineComponent({
    name: 'RenderlessItems',
    props: {
      type: {
        type: String,
        default: null,
        required: true,
      },
    },
    data: () => ({
      items: [],
    }),
    computed: {
      activeItem() {
        return this.items[0] || null;
      },
    },
    created() {
      events.$on('create', this.createItem);
      events.$on('remove', this.removeItem);
      events.$on('clear', this.clearItems);
    },
    beforeDestroy() {
      events.$off('create', this.createItem);
      events.$off('remove', this.removeItem);
      events.$off('clear', this.clearItems);
    },
    methods: {
      createItem(item) {
        this.items.unshift(item);
      },
      removeItem(id) {
        this.items = this.items.filter(({ id: itemId }) => itemId !== id);
      },
      clearItems() {
        this.items = [];
      },
    },
    render() {
      const types = {
        popovers: {
          popovers: this.items,
          activePopover: this.activeItem,
        },
        notifications: {
          notifications: this.items,
          activeNotification: this.activeItem,
        },
        modals: {
          modals: this.items,
          activeModal: this.activeItem,
        },
        tipMenus: {
          tipMenus: this.items,
          activeTipMenu: this.activeItem,
        },
      };
      const payload = types[this.type];
      return this.$scopedSlots.default(payload);
    },
  });
</script>
