export default {
  coach: 'Coach',
  moreInformation: 'Voor meer informatie zie het',
  savePreference: 'Voorkeur opslaan',
  coachesWantSeeDetails:
    '<strong>De coaches</strong> van {organization} willen jouw persoonlijke <strong>gegevens inzien</strong>:',
  choices: {
    true: 'Ja, ik ga ermee akkoord dat mijn (gezondheids)gegevens met de coaches worden gedeeld, om een nog persoonlijker voedingsplan samen te stellen.',
    false: 'Nee, ik ga hier niet mee akkoord.',
  },
  modals: {
    unsubscribe: {
      title: 'Afmelden voor de coach',
      message:
        'Weet je zeker dat je de koppeling met de coach wil stoppen? <br/><br/> Let op: Alleen een coach kan de koppeling weer aanvragen.',
      confirmText: 'Afmelden',
      cancelText: 'Nee',
    },
  },
  notifications: {
    unsubscribed: 'Je bent afgemeld voor de coach',
  },
};
