export const removeFromCacheByKey = (keyToBeDeleted, cache) => {
  const cacheKeys = Object.keys(cache.data.data);

  cacheKeys.forEach(key => {
    if (key.indexOf(keyToBeDeleted) > -1) {
      cache.data.delete(key);
    }
  });
};

export const removeFromCacheByKeys = (keysToBeDeleted, cache) => {
  keysToBeDeleted.forEach(keyToBeDeleted => removeFromCacheByKey(keyToBeDeleted, cache));
};
