export default {
  achieveYourGoals: 'Behaal je <strong>doelen</strong>',
  connectToRealCoach: 'Connectie maken met een coach',
  eatingSustainable: 'Duurzaam eten',
  faq: 'Meer <strong>over FoodCoach</strong>',
  feedback: { title: 'Help ons verbeteren.' },
  helloAnonymous: 'leuk dat je er bent',
  home: 'Home',
  loseWeight: 'Afvallen',
  myEvent: 'Mijn event',
  findMyFocus: 'Vind mijn focus',
  extras: "Extra's",
  foodCoachPremium: 'FoodCoach Premium',
  myFocus: 'Mijn focus',
};
