export default {
  confirmAccountReset: {
    title: 'Delete my account',
    message: 'We are sorry that you want to delete your account.',
    confirmText: 'Delete',
    cancelText: 'Cancel',
  },
  confirmPasswordChange: {
    title: 'Reset my password',
    message: 'We will receive you an email to reset your password',
    result: "If your email address is known to us, we've send you an email to reset your password.",
    closeText: 'Close',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
  },
  informEmailAddressChange: {
    title: 'Changing your email is not possible at the moment',
    message: 'If your email address is incorrect, try to create a new account',
    closeText: 'Close',
  },
  changeWeightGoal: {
    title: 'Are you sure you want to change your weight goal?',
    message:
      'Only a FoodCoach can restore your tailored weight target. Please note that reconnecting with a FoodCoach for this service will incur additional charges.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  loseWeight: {
    title: 'Important notice',
    message:
      'Smart weight loss responses are highly individual and may take time. Check our weight loss blogs to support your journey.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  nutritionViewWeightGoalConflict: {
    title: 'Are you sure?',
    message:
      'We advice to use the advanced performance view when working on smart weight loss or -gain',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  weightGoalNutritionViewConflict: {
    title: 'Please reconsider your nutrition view',
    message:
      'We advice to choose the advanced performance view when working on smart weight loss or -gain',
    confirmText: 'Change view',
    cancelText: 'Ignore',
  },
  changeNutritionViewCoach: {
    title: 'Are you sure you want to change your view?',
    message:
      'Only a FoodCoach can restore your tailored view. Please note that reconnecting with a FoodCoach for this service will incur additional charges.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
};
