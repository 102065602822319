export default {
  aboutThisApp: 'About this app',
  activityLevel: 'Activity level',
  searchProductsRecipes: 'Search products or recipes',
  searchProducts: 'Search products',
  addIngredients: 'Add ingredients',
  addRecipes: 'Add ingredients',
  addProduct: 'Add a product',
  addTraining: 'Add training',
  buffetOptions: 'Buffet options',
  createIngredient: 'Add own product',
  editIngredient: 'Edit own product',
  customRecipe: 'Own recipe',
  customRecipeDetails: 'Save tuned meal',
  customRecipeIntroduction: 'How does it work?',
  customRecipeOptions: 'Own recipe options',
  dailyEnergyNeed: 'Energy needs',
  dateOfBirth: 'Date of birth',
  developers: 'Developers',
  editTraining: 'Change training',
  FAQs: 'Frequently asked questions',
  ftpValue: 'FTP',
  ingredients: 'Ingredients',
  jumboFoodcoachRecipes: 'FoodCoach recipes',
  length: 'Height',
  macroNutrientsRatio: `<strong>Macronutrients ratio</strong> of this recipe`,
  mealPlannerOverview: 'My plan',
  myAccount: 'My account',
  myEnergyCalculation: 'Sport hours',
  myGoal: 'My goal',
  myInfo: 'My data',
  mySport: 'Sport',
  profile: 'Profile',
  product: 'Product',
  recipe: 'Recipe',
  sleepingHours: 'Sleeping hours',
  sportSettings: 'Sport settings',
  searchResults: 'Results',
  trainingSchedule: 'Training scheme',
  userRecipes: 'Own recipes',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Work type',
  yourSport: 'Sport',
  changeIngredientName: 'Change ingredient name',
};
