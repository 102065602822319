export default {
  connectDevice: {
    feedback: {
      title: 'Help ons te verbeteren',
      text: 'FoodCoach bevindt zich nog in de bètafase. Met je feedback kunnen wij onze dienstverlening verbeteren.',
      cta: 'Feedback delen',
    },
    planned: 'Gepland',
    adjusted: 'Uitgevoerd',
  },
  sportUSPs: [
    'Voedingsplan op maat.',
    'Je eigen weekmenu aangepast op je sportschema.',
    'Geef je lichaam op ieder moment van de dag de juiste voedingsstoffen.',
    'Inzicht in hoeveel kilocalorieën, koolhydraten, eiwitten en vetten je nodig hebt per dag.',
    'Maak je eigen recept dat past bij je voedingsplan.',
  ],
  privacyStatementExplanation:
    "Blijf op de hoogte van de laatste ontwikkelingen van de Athlete's FoodCoach <span class='light'>(optioneel)</span>.",
  approveJoinHeading: 'Automatische import van JOIN training data',
  approveJoinStatement:
    'Ik ga ermee akkoord dat FoodCoach automatisch mijn laatste trainingsplan gegevens uit de JOIN Cycling app importeert.',
  manageSubscription:
    'Alle abonnement gerelateerde acties (zoals betalingsmethode en annulering) zijn te vinden in de Apple App Store of Google Play Store.',
  lifetimeSubscription: 'Met een gratis abonnement heb je toegang tot alle premiumfuncties.',
  subscriptionWillRenew: 'Wordt automatisch verlengd op {date}.',
  whichDatabase: 'Welke productdatabase moet ik toevoegen?',
  whichDatabasePopover: [
    'Standaard is de FoodCoach-ingrediëntendatabase, vertrouwd door elite sportteams, geselecteerd.',
    "Je kunt extra productdatabases toevoegen om het loggen van voedsel in bepaalde regio's handiger te maken. Als je een lokale database toevoegt, kun je nu ook producten die op deze markt verkrijgbaar zijn loggen met behulp van de zoekbalk of de barcodescanner.",
    "Om je productdatabases later te beheren, ga je naar 'Profiel' > 'App-instellingen' > 'Productdatabase.'",
    '<strong>Open Food Facts</strong>\nOpen Food Facts is een wereldwijde database die door de community wordt onderhouden.Met meer dan 3 miljoen producten leggen ze de focus op de juistheid en volledigheid van de data. We hebben het getest met onze FoodCoaches en Premium-leden om de betrouwbaarheid van de data te valideren.',
    '<strong>Ik wil een andere database toevoegen, hoe doe ik dat?</strong>\nOp dit moment zijn we begonnen met productdatabases die producten dekken in Nederland, het Verenigd Koninkrijk en het Nederlandstalige gebied van België. Andere databases kunnen later toegevoegd worden.',
  ],
  whichWorkType: [
    'Het type en de duur van je werk hebben aanzienlijke invloed op je dagelijkse energiebehoefte.',
    'Professionele atleten beschouwen sport als hun primaire baan.',
    'Als je de hele dag achter een bureau zit, kies je voor een zittende levensstijl.',
    'Werken in een supermarkt of buurtwinkel, de hele dag staan, is licht actief',
    'Een postbode die fietst en loopt om post te bezorgen, wordt als behoorlijk actief beschouwd.',
    'Bouwvakkers en tuinmannen hebben zeer actieve banen.',
  ],
  whichGenderPopover: [
    "In de formule voor de energieberekening wordt geslacht als een factor beschouwd. Als je je niet met man of vrouw identificeert, raden we je aan bij de energieberekening de optie te kiezen die bij jouw voorkeur past. Houd er rekening mee dat het selecteren van 'mannelijk' resulteert in een hogere energiebehoefte vergeleken met het kiezen van 'vrouwelijk'. Twijfel je over de beste keuze voor jou, neem dan gerust contact op met onze Foodcoaches.",
  ],
  whichNutritionViewPopover: [
    '<strong>Core Performance</strong><br> Richt je op de essentiële macronutriënten voor training en herstel: koolhydraten en eiwitten. Voeding is een breed onderwerp. Het kan overweldigend zijn om op alle aspecten tegelijk te focussen. Deze core performance weergave is perfect voor wie zich wil concentreren op de belangrijkste elementen die je prestaties ondersteunen en je herstel bevorderen, om je sportieve doelen te behalen.',
    '<strong>Advanced Performance</strong><br> Houd je complete voedingsinname bij, inclusief calorieën, koolhydraten, eiwitten en vetten. De advanced performance weergave is ideaal voor atleten die gedetailleerde voedingsinformatie nodig hebben voor bredere analyse, het uitvoeren van complexe voedingsstrategieën (slim gewichtsverlies, lichaamssamenstelling, andere) en het omgaan met nieuwe situaties (blessures, trainingskampen, etc).',
    '<strong>Moet ik het hele jaar door dezelfde weergave gebruiken?</strong><br>Je hoeft niet het hele jaar door dezelfde weergave aan te houden. Afhankelijk van je trainingsfase en wedstrijdschema kun je een andere weergave kiezen.',
  ],
  whichWeightGoal: [
    'Stem je gewichtsdoel af op elke fase van je trainingsprogramma, waarbij je gebruik maakt van de systematische aanpak van periodisering. Coördineer zo je gewichtsbeheersing met de verschillende fasen van je trainingscyclus - basistraining, krachttraining, raceseizoen en taperfase - voor betere prestaties.',
    '<strong>Gewicht behouden</strong> \n Optimaal voor het behoud van de gezondheid en prestaties het hele jaar door.',
    '<strong>Slim afvallen</strong> \n Verminder geleidelijk je gewicht vóór of tijdens de basistraining. Pas niet toe tijdens het race seizoen en stop altijd minstens een week voor je evenement. Onze app ondersteunt een verlies van 0,25 kg of 0,5 kg per week om spieren en prestatie te behouden en duurzaam gewichtsbeheer te bevorderen.',
    '<strong>Slim aankomen</strong> \n Kies voor geleidelijke gewichtstoename of spieropbouw tijdens perioden waarin het vergroten van de spiermassa en kracht prioriteit krijgt, zoals buiten het seizoen of tijdens krachttraining. Onze app ondersteunt een gewichtstoename van ongeveer 0,25kg of 0,5kg per week, om spiergroei te ondersteunen, de vettoename te minimaliseren en de algehele prestaties te verbeteren.',
    '<strong>Professionele ondersteuning</strong> \n Twijfel je welk doel je moet kiezen, neem dan contact op te nemen met een gecertificeerde FoodCoach. Onze coaches geven persoonlijk advies over jouw gewichtsdoelen en kunnen je wekelijks richtpunt verder personaliseren.',
  ],
  smartWeightGain: [
    'Als het om aankomen gaat, bestaat er geen snelle oplossing. Bij FoodCoach geloven en staan we achter het concept van slim aankomen, dat duurzaam is en je gezondheid ondersteunt.',
    '<strong>Welk wekelijks richtpunt moet ik kiezen?</strong> \n Onze app ondersteunt een gewichtstoename van ongeveer 0.5kg per week, om spiergroei te ondersteunen, de vettoename te minimaliseren en de algehele prestaties te verbeteren. Als je langzamer wil aankomen, kun je 0.25 kg per week kiezen.',
    '<strong>Wat als ik sneller wil aankomen?</strong> \n Als standaard app instelling past dit niet bij het concept slim aankomen. In overleg en begeleiding van een gecertificeerde FoodCoach is het mogelijk om te kijken of in jouw individuele geval nog onbenut potentieel om aan te komen bestaat.',
    '<strong>Wanneer zie ik resultaat?</strong> \n De reacties op aankomen zijn zeer individueel en afhankelijk van fysieke kenmerken, gedrag en omgevingsfactoren. Het stellen van een gewichtsdoel en het daarmee samenhangende caloriesurplus leidt niet altijd direct tot resultaat. Het is verstandig om je proces te monitoren om je gewichtsdoel te bereiken.',
    '<strong>Professionele ondersteuning</strong> \n Als je twijfelt welk doel je moet kiezen, zie je na een tijdje nog geen resultaten of denk je dat je een andere aankom instelling nodig hebt, aarzel dan niet om hulp te zoeken bij een gecertificeerde FoodCoach. Onze coaches geven persoonlijk advies over jouw gewichtsdoelen en kunnen je wekelijks richtpunt verder personaliseren.',
  ],
};
