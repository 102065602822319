export default {
  activityLevel: 'Activity level',
  dateOfBirth: 'Date of birth',
  gender: 'Gender',
  length: 'Height',
  mySport: 'Sport type',
  sleepingHours: 'Sleeping hours',
  energyCalculationExplanation: 'What method applies best to me?',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Type of work',
};
