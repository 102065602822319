import { setInteractionMode } from 'vee-validate';
import VeeValidateExtend from '@/modules/core/plugins/veeValidateExtend';

export const validationModule = ({ i18n }) => ({
  install(Vue) {
    Vue.use(VeeValidateExtend, { i18n });

    setInteractionMode('lazy');
  },
});
