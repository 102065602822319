export default {
  buttons: {
    createAccount: 'Account aanmaken',
    login: 'Inloggen',
    resetPassword: 'Wachtwoord resetten',
    backToLogin: 'Terug naar inloggen',
  },
  headings: {
    login: '<strong>Goed je weer te zien</strong>',
    forgotPassword: 'Reset je wachtwoord',
    passwordReset:
      'Geregistreerde e-mailadressen ontvangen binnenkort een e-mail om het wachtwoord opnieuw in te stellen',
  },
  links: {
    createAccount: 'Maak een account aan',
    forgotPassword: 'Ik ben mijn wachtwoord vergeten',
    backToLogin: 'Heb je al een account? <strong>Inloggen</strong>',
  },
  placeholders: {
    givenName: 'Voornaam',
    middleName: 'Tussenvoegsel',
    familyName: 'Achternaam',
    email: 'naam@email.nl',
    password: 'Wachtwoord',
  },
  misc: {
    hello: 'Hallo 👋',
    newAtJumbo: 'Nieuw bij Blanco?',
    letsCreateYourAccount: 'Laten we jouw account maken',
    forgotPasswordInfo:
      '<strong>Belangrijk:</strong> alleen geregistreerde e-mailadressen ontvangen een e-mail voor het opnieuw instellen van het wachtwoord. Als je e-mailadres niet bekend is bij ons, wordt er geen reset-e-mail verzonden.',
  },
  titles: {
    signUp: 'Aanmelden',
    account: 'Account',
    login: 'Inloggen',
    resetPassword: 'Wachtwoord resetten',
    createAccount: 'Maak een <strong>Blanco Account</strong> aan om:',
    welcomeHappyMany: "Welkom bij <strong>The Athlete's FoodCoach</strong>",
    welcomeSports:
      'Ben jij een <strong>fanatieke sporter</strong> en wil je jouw sportprestaties verbeteren?',
  },
  notifications: {
    accountCreated: 'Gefelicteerd - je hebt nu een FoodCoach account',
  },
  body: {
    passwordConfirmation: [
      '<strong>Beveiligingsopmerking:</strong>\n Om veiligheidsredenen kunnen we niet bevestigen of het opgegeven e-mailadres bij ons is geregistreerd.',
      '<strong>Geen e-mail ontvangen?</strong>\n Probeer het opnieuw met een ander e-mailadres of neem contact met ons op via <a href="mailto:feedback@theathletesfoodcoach.com">feedback@theathletesfoodcoach.com</a>.',
      'Let op: Jumbo FoodCoach-accounts werken niet in deze nieuwe app',
    ],
  },
};
