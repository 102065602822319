export default {
  birthDateSaved: 'Your date of birth has been saved',
  dailyEnergyNeedSaved: 'Your daily energy requirement is saved',
  eatingOnActivityPlanned: 'Food during training is planned',
  ftpSaved: 'FTP-waarde is saved',
  heightSaved: 'Height is saved',
  mealPlanned: '{mealMoment} is planned',
  mealRemoved: '{mealMoment} is deleted',
  sleepingHoursSaved: 'Sleeping hours are saved',
  sportSaved: 'Sport is saved',
  userRecipeRemoved: 'Recipe is deleted',
  weightSaved: 'Weight is saved',
  workingHoursSaved: 'Working hours are saved',
  workTypeSaved: 'Work type is saved',
  pairActivitySuccess: '"{name}" is paired to your training',
  pairActivityFailed: 'Something went wrong while pairing to your training, Try again later',
  unpairActivitySuccess: '"{name}" is unpaired from your training',
  unpairActivityFailed: 'Something went wrong while unpairing to your training, Try again later',
  deleteActivitySuccess: '"{name}" has successfully been removed',
  deleteActivityFailed: 'Something went wrong while removing your activity. Try again later',
  trainingPeaksSyncSuccess: 'Training plan from TrainingPeaks synchronized for today.',
  trainingPeaksSyncFailed: 'Training plan synchronization failed. Try again later',
};
