import { ENVIRONMENTS } from '@/modules/shared/constants/env.const';

const { PRODUCTION, ACCEPTANCE, DEVELOPMENT, TEST } = ENVIRONMENTS;

export default {
  data: () => ({
    nodeEnv: process.env.NODE_ENV,
    appEnv: process.env.VUE_APP_ENV_NAME,
  }),
  computed: {
    acceptance: vm => vm.appEnv === ACCEPTANCE,
    development: vm => vm.appEnv === DEVELOPMENT,
    production: vm => vm.appEnv === PRODUCTION,
    test: vm => vm.appEnv === TEST,
  },
};
