export default {
  carbsPerHourExplanation: [
    {
      text: 'The amount of carbohydrates you need per hour depends on the duration and intensity of your training. The extent to which your gut is trained to process and absorb carbohydrates into the bloodstream also plays an important role.',
    },
    {
      title: 'Training up to 60 minutes',
      text: 'It is not necessary to take in extra carbohydrates. Your body can store enough energy from the pre-exercise meals to complete the workout.',
    },
    {
      title: 'Training 60 to 150 minutes',
      text: 'Depending on the intensity and duration of the exercise, the energy stores in the body will be depleted. It is recommended to take 30 to 60 grams of carbohydrates per hour, from the first hour.',
    },
    {
      title: 'Training more than 150 minutes',
      text: 'We recommend a minimum of 60 to 90 grams of carbohydrates per hour, from the first hour. Especially during intensive training, a high intake is recommended to keep up your energy level as good as possible. Use a combination of different types of carbohydrates to digest and absorb 90 grams per hour.',
    },
    {
      title: 'When do I take 120 grams of carbs per hour?',
      text: "The amount of carbohydrates you can absorb per hour is trainable. These guidelines are based on the average cyclist. Do you want a more specific and individually tailored plan? Connect with a FoodCoach and work together on 'training the gut'.",
    },
    {
      title: 'Extra tip!',
      text: "Your body needs fluids to absorb carbohydrates. So, don't forget to drink enough as well (+/- 500 ml per hour). Without fluids, it's pointless to consume a large amount of carbohydrates.",
    },
  ],
};
