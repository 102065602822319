<template>
  <button class="fc-clear-button" :class="{ 'fc-clear-button--block': block }" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcClearButton',
    props: {
      block: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<style lang="scss" scoped>
  .fc-clear-button {
    &,
    &:focus,
    &:active {
      background: none;
      border: 0;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      margin: 0;
      outline: none;
      padding: 0;
    }

    &--block {
      display: block;
    }

    .jum-icon {
      display: block;
    }
  }
</style>
