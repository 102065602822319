import { PROCESS } from '@/modules/shared/constants/env.const';
import { MODAL_CONFIG } from '@/modules/shared/constants/modals.const';
import { PERSISTENCE_KEYS } from '@/modules/shared/constants/storage.const';
import apollo from '@/modules/shared/graphql/apollo.config';
import APP_OPENS_QUERY from '@/modules/shared/graphql/queries/appOpens.query.graphql';
import { getItem } from '@/modules/shared/services/storage.service';
import { isSameDay } from '@/modules/shared/utils/date.util';

const packageVersion = PROCESS.PACKAGE_VERSION;

export default async (_, { modalName }) => {
  const { min = 0, max = 0, showForEachVersion = false } = MODAL_CONFIG[modalName];

  const defaultModal = {
    __typename: 'Modal',
    _id: modalName,
    lastShownDate: '',
    lastShownVersion: '',
    min,
    max,
    showForEachVersion,
    timesShown: 0,
  };

  const {
    data: { appOpens },
  } = await apollo.defaultClient.query({
    query: APP_OPENS_QUERY,
  });
  const storedModals = await getItem(PERSISTENCE_KEYS.MODALS);
  const modal =
    storedModals && storedModals[modalName]
      ? { ...defaultModal, ...storedModals[modalName] }
      : defaultModal;

  if (modal.showForEachVersion) {
    modal.shouldBeShown = modal.lastShownVersion !== packageVersion;
  } else if (modal.min && !modal.max) {
    modal.shouldBeShown = appOpens.daysWithAppOpens >= modal.min;
  } else if (modal.max && !modal.min) {
    modal.shouldBeShown = modal.timesShown < modal.max && !isSameDay(modal.lastShownDate);
  } else {
    modal.shouldBeShown =
      appOpens.daysWithAppOpens >= modal.min &&
      modal.timesShown < modal.max &&
      !isSameDay(modal.lastShownDate);
  }

  return modal;
};
