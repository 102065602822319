import { fieldsAreValid } from '@/modules/onboarding-sports/guards/onboardingSportsStep/onboardingSportsStep.guard';
import { getUserProfile } from '@/modules/shared/services/profile/profile.service';

const onboardingSportsGuard = async (to, from, next) => {
  const redirectToLogin = () => next({ name: 'auth-login' });
  const redirectToOnboarding = () => {
    next({
      name: 'onboarding-sports',
      query: { redirect: to.name },
    });
  };

  const fields = [
    'gender',
    'birthDate',
    { name: 'acceptsSportsTermsAndConditions', expectedValue: true },
    'height',
    'weight',
    'activityLevelWorkDay',
    'avgWorkDayHours',
    'activityLevelDayOff',
    'avgSleepingHours',
    'bmrFormula',
  ];

  try {
    const user = await getUserProfile();

    if (fieldsAreValid(fields, user)) {
      next();
    } else if (!user?.acceptsSportsTermsAndConditions) {
      next({
        name: 'onboarding-personal-data',
        query: { redirect: to.name },
      });
    } else {
      redirectToOnboarding();
    }
  } catch {
    redirectToLogin();
  }
};

export default onboardingSportsGuard;
