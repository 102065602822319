export default {
  showAllRecipes: 'Show all recipes',
  activate: 'Activate',
  connectToYourCoach: 'Connect to your FoodCoach',
  specificWishes: 'I have specific wishes',
  backToHome: 'Back to My goal',
  searchForProduct: 'Find a product',
  shareFeedback: 'Share Feedback',
  comingSoon: 'Coming soon',
  discoverPremium: 'Discover FoodCoach Premium',
  getWinterTrial: 'Get free 14-day trial',
};
