<template>
  <renderless-items type="notifications">
    <template #default="{ activeNotification }">
      <transition
        v-if="activeNotification && activeNotification.type === 'notification'"
        name="bounce"
        enter-active-class="fadein"
      >
        <div v-if="activeNotification" ref="niceNotifications" class="nice-notifications">
          <component
            :is="activeNotification.component || 'NiceNotification'"
            class="nice-notifications__notification"
            :notification="activeNotification"
          />
        </div>
      </transition>
    </template>
  </renderless-items>
</template>

<script>
  import { defineComponent } from 'vue';
  import NiceNotification from './NiceNotification';

  export default defineComponent({
    name: 'NiceNotifications',
    components: { NiceNotification },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  $speed: 1.6s;

  .nice-notifications {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;

    &__notification {
      flex-grow: 1;
      will-change: bottom, transform;
    }
  }

  .fadein {
    animation-duration: $speed;
    animation-name: fadein;

    .nice-notifications__notification {
      animation-duration: $speed;
      animation-name: fadeout;
      opacity: 1;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    18.75% {
      opacity: 1;
      transform: scale(1);
    }

    81.25% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  @keyframes fadeout {
    0% {
      visibility: visible;
    }

    100% {
      visibility: hidden;
    }
  }
</style>
