export default {
  aboutFoodcoach: {
    intro:
      '<p><a href="https://www.theathletesfoodcoach.com" target="_blank">The Athlete\'s FoodCoach</a> app give you a personal performance nutrition plan. Take the next step in your performance. We will coach you on rest and training days.</p><p>Connect to a certified FoodCoach to get hyper personal suggestions and feedback. Ask questions whenever you want. Say goodbye to the insecure feelings like: "Did I eat the right things?" and "Did I eat enough?"</p>',
    copyright: '© 2022-2024 Blanco FoodCoach B.V. – all rights reserved.',
    privacy:
      'See our <a href="https://www.theathletesfoodcoach.com/general-terms-and-conditions.html" target="_blank">general terms and conditions</a>, <a href="https://www.theathletesfoodcoach.com/privacy-statement.html" target="_blank">privacy statement</a> and <a href="https://www.theathletesfoodcoach.com/cookie-statement.html" target="_blank">cookie statement</a>.',
  },
  new: 'New',
  free: 'Free',
  yearly: 'Yearly',
  monthly: 'Monthly',
};
