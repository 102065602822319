<script>
  import { defineComponent, ref, onMounted, onActivated, watch } from 'vue';

  export default defineComponent({
    name: 'ProgressBar',
    props: {
      currentStep: {
        type: Number,
        default: 0,
      },
      totalSteps: {
        type: Number,
        default: 0,
      },
      progress: {
        type: Number,
        default: 0,
      },
    },
    setup(props) {
      const progressBarFill = ref(null);
      const tooltipOffset = ref(0);
      const padding = 8;
      const rightSideCorrection = 5;

      const setTooltipOffset = async () => {
        if (!progressBarFill.value) {
          return;
        }

        if (props.progress === 100) {
          tooltipOffset.value = progressBarFill.value.offsetWidth + padding - rightSideCorrection;
          return;
        }

        tooltipOffset.value = progressBarFill.value.offsetWidth + padding;
      };

      const triggerTooltipUpdate = async () => {
        setTimeout(() => {
          setTooltipOffset();
        });
      };

      watch(props, triggerTooltipUpdate);

      onActivated(triggerTooltipUpdate);

      onMounted(triggerTooltipUpdate);

      return {
        progressBarFill,
        tooltipOffset,
      };
    },
  });
</script>

<template>
  <div class="progress-bar-container">
    <div class="progress-bar">
      <div
        ref="progressBarFill"
        class="progress-bar__fill"
        :style="{ width: `${progress}%` }"
      ></div>
    </div>

    <div class="tooltip" :style="{ left: `${tooltipOffset}px` }">
      {{ currentStep }} / {{ totalSteps }}
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .progress-bar-container {
    padding: 0 8px;
    position: relative;
  }

  .progress-bar {
    background-color: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    height: 16px;
    overflow: hidden;
    width: 100%;

    &__fill {
      background-color: $taf-light-green;
      height: 100%;
      min-width: 1.5%;
      position: relative;
    }
  }

  .tooltip {
    background-color: $taf-green;
    border-radius: 4px;
    bottom: 5.5px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    left: 1%;
    padding: 4px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 6ch;

    &::after {
      border-color: $taf-green transparent transparent transparent;
      border-style: solid;
      border-width: 5px;
      content: '';
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 100%;
      transform: translateX(-50%);
    }
  }
</style>
