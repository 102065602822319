export default {
  aboutFoodcoach: {
    intro:
      '<p><a href="https://www.theathletesfoodcoach.com" target="_blank">The Athlete\'s FoodCoach</a> app helpt jou met een persoonlijk performance nutrition plan op basis van jouw gegevens.  Zo kun jij de volgende stap zetten in je sportprestaties. We helpen je op rust- en trainingsdagen.</p><p>Ga aan de slag met een van onze certified FoodCoaches en krijg persoonlijke suggesties. Stel al je voedingsgereleateerde vragen aan een expert en zeg vaarwel tegen het onzekere gevoel dat voeding met zich mee kan brengen.</p>',
    copyright: '© 2022-2024 Blanco FoodCoach B.V. – alle rechten voorbehouden.',
    privacy:
      'Bekijk hier <a href="https://www.theathletesfoodcoach.com/algemene-voorwaarden.html" target="_blank">de algemene voorwaarden</a>, <a href="https://www.theathletesfoodcoach.com/privacy-verklaring.html" target="_blank">privacy verklaring</a> en <a href="https://www.theathletesfoodcoach.com/cookie-verklaring.html" target="_blank"> de cookie verklaring</a>.',
  },
  new: 'Nieuw',
  free: 'Gratis',
  yearly: 'Jaarlijks',
  monthly: 'Maandelijks',
};
