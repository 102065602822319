export default {
  workInProgress: 'We werken momenteel hard om jou binnenkort te kunnen helpen met dit doel.',
  youreEarlyExplanations: [
    'Doordat je op de knop hebt gedrukt, weten wij dat je dit belangrijk vindt.',
    'Wil je ons verder helpen? Geef via de knop aan hoe wij je het beste kunnen helpen met dit doel.',
  ],
  connectToCoachList: [
    'Get top-class nutrition advice',
    'Certified FoodCoach',
    'Ask any questions you may have',
  ],
  premiumBenefits: [
    'Personal adaptive meal plan',
    'Sync Strava, Garmin, TrainingPeaks and more',
    'Exclusive FoodCoach AI support',
    'Unlock medals, challenges & focus plans',
    'Explore  1500+ Pro Recipes',
  ],
  feedback: {
    text: 'FoodCoach is still in beta phase. With your feedback we can improve our services.',
  },
};
