<template>
  <div class="accent-wrapper" :class="{ checked }">
    <jum-panel class="radio-panel">
      <div class="radio-panel__content">
        <slot></slot>
      </div>
    </jum-panel>
  </div>
</template>

<script>
  import { JumPanel } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcInputRadioPanel',
    components: {
      JumPanel,
    },
    props: {
      checked: {
        default: false,
        type: Boolean,
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .accent-wrapper {
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    &::before {
      background-color: $color-primary;
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.2s ease-in-out;
      width: 4px;
    }

    &.checked {
      &::before {
        opacity: 1;
      }
    }
  }

  .radio-panel {
    min-height: 54px;

    &__content {
      padding: $spacing-m;
    }

    :deep(span.radio.checked) {
      border-color: #9e9e9e;

      &::after {
        background-color: #000;
      }
    }
  }
</style>
