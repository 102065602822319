export default {
  confirmAccountReset: {
    title: 'Blanco FoodCoach account verwijderen',
    message: 'Wat jammer dat je je Blanco FoodCoach account wil verwijderen.',
    confirmText: 'Verwijderen',
    cancelText: 'Annuleren',
  },
  confirmPasswordChange: {
    title: 'Wachtwoord opnieuw instellen',
    message:
      'Met deze actie wordt een e-mail voor het opnieuw instellen van het wachtwoord verzonden.',
    result:
      'Als jouw email bekend is bij ons dan is er nu een mail voor wachtwoordherstel verzonden.',
    closeText: 'Sluiten',
    confirmText: 'Bevestigen',
    cancelText: 'Annuleren',
  },
  changeWeightGoal: {
    title: 'Weet je zeker dat je je streefgewicht wil aanpassen?',
    message:
      'Alleen een FoodCoach kan je op maat gemaakte streefgewicht herstellen. Houd er rekening mee dat het opnieuw verbinden met een FoodCoach voor deze service extra kosten met zich meebrengt.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  loseWeight: {
    title: 'Belangrijk om te weten',
    message:
      'Iedereen reageert anders op afvallen en dit kan tijd kosten. Bekijk onze blogs over afvallen voor extra hulp bij dit onderwerp.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  nutritionViewWeightGoalConflict: {
    title: 'Weet je het zeker?',
    message:
      'We adviseren om de advanced performance weergave te gebruiken als je bezig bent met gewichtsverlies of -toename',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
  weightGoalNutritionViewConflict: {
    title: 'Overweeg je ingestelde voedingsweergave te herzien',
    message:
      'We raden aan om de advanced performance weergave te kiezen voor slim gewichtsverlies of -toename',
    confirmText: 'Verander weergave',
    cancelText: 'Negeren',
  },
  changeNutritionViewCoach: {
    title: 'Weet u zeker dat u uw weergave wilt wijzigen?',
    message:
      'Alleen een FoodCoach kan uw maatwerk weergave herstellen. Houd er rekening mee dat het opnieuw inschakelen van een FoodCoach voor deze service extra kosten met zich meebrengt.',
    confirmText: 'Doorgaan',
    cancelText: 'Annuleren',
  },
};
