import BaseType from './baseType';

export class Notification extends BaseType {
  constructor({
    icon = '',
    iconColor = '',
    duration = null,
    message = '',
    component = null,
    onClose = null,
    ...args
  }) {
    super(component, onClose);
    this.icon = icon;
    this.iconColor = iconColor;
    this.duration = duration;
    this.message = message;
    this.component = component;
    this.type = 'notification';
    Object.keys(args).forEach(key => {
      this[key] = args[key];
    });
  }
}
