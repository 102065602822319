import WEEKS_QUERY from '@/modules/shared/graphql/queries/weeks.query.graphql';
import { generateMonthArray } from '@/modules/shared/utils/date.util';

export default (_, data, { cache }) => {
  try {
    return cache.readQuery({
      query: WEEKS_QUERY,
    });
  } catch (err) {
    return generateMonthArray();
  }
};
