import { render, staticRenderFns } from "./MyPerformanceGoal.vue?vue&type=template&id=56c9515e&scoped=true"
import script from "./MyPerformanceGoal.vue?vue&type=script&setup=true&lang=js"
export * from "./MyPerformanceGoal.vue?vue&type=script&setup=true&lang=js"
import style0 from "./MyPerformanceGoal.vue?vue&type=style&index=0&id=56c9515e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c9515e",
  null
  
)

/* custom blocks */
import block0 from "./MyPerformanceGoal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports