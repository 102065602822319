export default {
  personalData:
    'We make use of <strong>your body metrics</strong> and <strong>baseline activity level</strong> to calculate your personal nutrition plan.',
  chooseYourSport: `What is <strong>your main sport?</strong>`,
  dailyEnergyNeed: `<strong>Energy needs</strong>`,
  ftpValueExplanation: 'Learn more about FTP',
  fitnessExplanation: 'What’s my training intensity?',
  energyCalculation:
    'What <strong>calculation method for resting energy expenditure</strong> should we use for you?',
  gender: "What's your <strong>gender?</strong>",
  birthdate: "What's your <strong>date of birth?</strong>",
  height: "What's your <strong>height?</strong>",
  weight: "What's your <strong>weight?</strong>",
  workType: 'What <strong>type of work</strong> do you do?',
  workingHours: 'How many <strong>hours</strong> do you <strong>work per day?</strong>',
  activityLevel:
    'What is your baseline activity level when you do <strong><u>not</u> work or sport?</strong>',
  sleepingHours: 'How many <strong>hours</strong> do you <strong>sleep each night?</strong>',
  creatingPlan: 'Thanks. We’ll now create your nutrition plan',
  planCreated: 'Your nutrition plan is ready',
  weightGoal: 'What is <strong>your weight goal?</strong>',
  nutritionView: '<strong>Which view</strong> would you like for your nutrition tracking?',
};
