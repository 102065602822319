const evaluateGuards = async (guards, to, from, next) => {
  const guardsLeft = [...guards];
  const nextGuard = guardsLeft.shift();

  if (nextGuard === undefined) {
    next();
    return;
  }

  nextGuard(to, from, nextArg => {
    if (nextArg === undefined) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
};

export default guards => {
  if (!Array.isArray(guards)) {
    throw new Error('Please supply an array of guards');
  }

  const invalidGuardIndex = guards.findIndex(guard => typeof guard !== 'function');
  if (invalidGuardIndex >= 0) {
    throw new Error(
      `Guard at index "${invalidGuardIndex}" in ${JSON.stringify(guards)} is not a function`
    );
  }

  return (to, from, next) => evaluateGuards(guards, to, from, next);
};
