<script>
  import { computed, defineComponent } from 'vue';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import FocusPlaylistCard from '@/components/focus-playlist-card/FocusPlaylistCard.vue';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';
  import { usePlaylist } from '@/composables/usePlaylist';

  export default defineComponent({
    name: 'FocusPlaylist',
    components: {
      LinkCardList,
      JumHeading,
      DefaultSpacer,
      FocusPlaylistCard,
    },
    props: {
      playlistId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { playlist, addPlaylistProgress, refetchPlaylistProgress } = usePlaylist(
        props.playlistId
      );

      const items = computed(() =>
        playlist.value.phases.filter(phase => phase.links.length).flatMap(phase => phase.links)
      );

      const highlightedItems = computed(() => {
        const firstIncompleteIndex = items.value.findIndex(item => !item.completed);

        if (firstIncompleteIndex === -1) {
          return items.value.slice(0, 3);
        }

        return items.value.slice(firstIncompleteIndex, firstIncompleteIndex + 3);
      });

      const handleItemSelected = async link => {
        if (link.tag.type === 'challenge') {
          return;
        }

        await addPlaylistProgress(playlist.value.uuid, link.uuid);
        await refetchPlaylistProgress(playlist.value.uuid);
      };

      return {
        playlist,
        items,
        handleItemSelected,
        highlightedItems,
      };
    },
  });
</script>

<template>
  <section v-if="playlist" class="focused-playlist">
    <jum-heading h3 bold>
      {{ $t('headingFocus') }}
    </jum-heading>

    <default-spacer s />

    <router-link
      v-slot="{ navigate }"
      :to="{ name: 'playlist-detail', params: { slug: playlist.slug } }"
      custom
    >
      <focus-playlist-card :playlist="playlist" @click.native="navigate" />
    </router-link>

    <default-spacer />

    <jum-heading h3 bold>
      {{ $t('headingChallenges') }}
    </jum-heading>

    <default-spacer s />

    <link-card-list :links="highlightedItems" @item-selected="handleItemSelected" />
  </section>
</template>

<i18n>
{
  "en": {
    "headingFocus": "My Focus",
    "headingChallenges": "Your next challenges "
  },
  "nl": {
    "headingFocus": "Mijn focus",
    "headingChallenges": "Jouw volgende uitdagingen"
  }
}
</i18n>
