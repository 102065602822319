export default {
  aboutThisApp: 'Over deze app',
  activityLevel: 'Activiteitenniveau',
  energyCalculationExplanation: 'Welke methode past het beste bij mij?',
  calculateYourFtp: 'Zelf berekenen',
  dateOfBirth: 'Geboortedatum',
  developer: 'Features',
  editRecipe: 'Foodcoach je eigen recept',
  fitnessOverview: 'Welke intensiteit moet ik kiezen?',
  ftpValueExplanation: 'Meer weten over je FTP waarde?',
  gender: 'Geslacht',
  length: 'Lengte',
  myAccount: 'Mijn account',
  myFtpValue: 'Mijn FTP-waarde',
  myGoal: 'Mijn doel',
  myInfo: 'Mijn gegevens',
  mySport: 'Mijn sport',
  myEnergyCalculation: 'Mijn energieberekening',
  onActivityExplanation: 'Nooit meer een hongerklop?',
  progressBarExplanation: 'Hoe werkt deze grafiek?',
  save: 'Opslaan',
  sleepingHours: 'Slaapuren',
  sportSettings: 'Mijn sportinstellingen',
  toHappyMany: 'Pas mijn doel aan',
  trainingIntensity: 'Meer weten over je trainingsintensiteit?',
  weight: 'Gewicht',
  workingHours: 'Werkuren',
  workType: 'Type werk',
  recipeListTabs: {
    favorites: 'Mijn favorieten',
    foodcoach: 'Ontdekken',
    myRecipes: 'Mijn recepten',
    planner: 'Mijn plan',
    tuneYourMeal: 'Tune je maaltijd',
    suggestedMeals: 'Voorgestelde maaltijden',
  },
  tuneYourMealExplanation: 'Hoe werkt tuning?',
  tuningSatisfied: 'Ja, tune deze maaltijd',
};
