export default {
  addOwnIngredient: {
    title: 'Add your own ingredient',
    message:
      'Unfortunately, adding your own ingredients does not yet work. We are working on getting this in the app as soon as possible.',
    confirmText: 'Okay',
  },
  changeInFtp: {
    title: 'Change FTP',
    message: `Due to your change, the wattage of already planned training sessions may be inaccurate, adjust this if necessary. to "training"`,
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  changeInProfile: {
    title: 'Change profile',
    message: 'When one of the profile data changes, the daily energy requirement changes.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  trainingImportance: {
    title: 'Are you sure this is your primary training?',
    message:
      'You already planned a training, which is specified as primary training. If you make this your primary training, the other training will become secondary.',
    confirmText: 'Yes, continue',
    cancelText: 'No, cancel',
  },
  confirmAccountReset: {
    title: 'Delete my account',
    message: 'What a pity that you want to delete your account. Are you sure?',
    confirmText: 'Yes, delete',
    cancelText: 'No, cancel',
  },
  createTraining: {
    title: 'Add a trainnig',
    message: 'By adding this training your energy requirement for the day changes, ',
  },
  pairActivity: {
    title: 'New activity synced!',
    message: 'Do you want to pair this to your planned training?',
    confirmText: 'Yes, pair',
    cancelText: 'No',
  },
  pairActivityMultipleTraining: {
    title: 'New activity synced!',
    pairToTraining: 'Do you want to pair this to one of your planned trainings?',
    selectTraining: 'To which training session do you want to pair this?',
    confirmText: 'Ja, koppelen',
    cancelText: 'Nee',
  },
  deleteUserRecipe: {
    title: 'Delete this recipe',
    message: 'Deleting this recipe will remove it from all future scheduled moments.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  energyIntroduction: {
    title: 'Your energy needs',
    message:
      'When you schedule a workout, your energy needs change. This affects the amount of carbs, proteins and fats.',
    confirmText: 'Okay',
  },
  mealDetailIntroduction: {
    title: 'Customized meals',
    message: {
      default:
        'We customize all your meals. This way you always get the right amount of carbohydrates, proteins and fats per meal.',
      userRecipe:
        'We will scale the ingredients based on the macronutrient ratio of your recipe, so that you get the correct number of kilocalories.',
    },
    confirmText: "That's clear",
  },
  moveTraining: {
    title: 'Change training',
    message:
      'Your change will change your energy needs and any planned meals for these day(s) will be removed.',
  },
  removeTraining: {
    title: 'Delete training',
    message: 'By removing this training, your energy requirement for the day changes, ',
  },
  trainingMoment: {
    mealsRemoved: ' | this deletes {n} planned meal. | this deletes {n} planned meals.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
  },
  updateTraining: {
    title: 'Change training',
    message:
      'By changing this training, your energy needs for the day will be recalculated. It is possible that the meals you have already planned no longer fit within your plan.',
  },
  deleteActivity: {
    title: 'Are you sure you want to remove this activity?',
    message: 'This synced activity will removed from your plan, this cannot be undone.',
    cancelText: 'No, cancel',
    confirmText: 'Yes, delete activity',
  },
  activityAlreadyPaired: {
    title: 'Are you sure you want to pair this activity?',
    message:
      'A different activity is already paired to this training. Pairing this activity will unpair the currently paired activity.',
    cancelText: 'No, cancel',
    confirmText: 'Yes, pair activity',
  },
};
