import messages from './messages/nl';
import messagesEn from './messages/en';
import { registerRoutes } from './routes/training.routes';

/* eslint-disable-next-line import/no-mutable-exports */
export let mixins = {};

// Register module
export const trainingModule = (
  sharedModule,
  { router },
  { guards: { onboardingGuard } },
  { guards: { onboardingSportsGuard } },
  i18n,
  proModule
) => ({
  guards: {},
  install() {
    const defaultGuards = [onboardingGuard, onboardingSportsGuard];

    i18n.setTranslations({ training: { ...messages } }, 'nl');
    i18n.setTranslations({ training: { ...messagesEn } }, 'en');

    mixins = { ...sharedModule.mixins, ...proModule.mixins };

    registerRoutes(router, sharedModule.utils.guards(defaultGuards));
  },
});
