import { onError } from 'apollo-link-error';
import { logErrorMessages } from '@vue/apollo-util';

export const logErrorLink = () =>
  onError(error => {
    if (process.env.NODE_ENV !== 'production') {
      try {
        logErrorMessages(error);
      } catch (err) {
        /* eslint-disable no-console */
        console.warn(`Failed to log via 'logErrorMessages' - apollo-util`);
        console.log(
          `%cOops`,
          'color: #fff; background-color: #e90000; border-radius: 4px; padding: 1px 4px; font-weight: bold;',
          error
        );
        /* eslint-enable */
      }
    }
  });
